/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

const PaymentsModal = (props) => {
  const { ccPayment } = props;
  return (
    <div className="modal-overlay open">
      <div className="modal-overlay-title">Select payment method</div>
      <div className="modal-overlay-items">
        <div
          className="modal-overlay-item"
          role="presentation"
          onClick={() => ccPayment()}
        >
          <div className="modal-overlay-item-main">
            <i className="material-icons">add</i>
            Add Credit Card
          </div>
          <div className="modal-overlay-item-action">
            <i className="material-icons">credit_card</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsModal;
