import {
  isPossiblePhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input";

/* Remove leading zero from mobile number */
const getFinalizedMobileNumber = mobileNumber => {
  if (mobileNumber) {
    const number = parsePhoneNumber(mobileNumber);
    if (number) {
      if (isPossiblePhoneNumber(mobileNumber)) {
        return parsePhoneNumber(mobileNumber).number;
      }
    }
  }
};

export default getFinalizedMobileNumber;