const validationRules = {
	required: (value) => {
		return value !== '';
	},
	email: (value) => {
		if (!value) {
			return true;
		}
		//eslint-disable-next-line
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(value).toLowerCase());
	},
	number: (value) => {
		return !isNaN(Number(value));
	},
	credit_card: (value) => {
		//eslint-disable-next-line
		var ccRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/;
		var card_number = value.replace(/ /g, '');
		if (card_number.length > 0 && ccRegEx.test(card_number)) {
			return true;
		}
		return false;
	},
	cc_expire_date: (value) => {
		return value.length === 5;
	},
	cc_cvv: (value) => {
		return value.length > 2;
	}
};

const ruleMessages = {
	required: 'Required',
	number: 'must be a number',
	email: 'invalid e-mail',
	credit_card: 'invalid credit card',
	cc_expire_date: 'invalid expire date',
	cc_cvv: 'invalid CVV'
};

const showHideError = (/** @type {FormRule} */ rule, /** @type {HTMLElement} */ input, valid, invalidRuleName) => {
	const errorElement = input.parentElement.querySelector('.validation-error');
	errorElement.textContent = rule.message || ruleMessages[invalidRuleName];
	errorElement.style.display = valid ? 'none' : '';
};

export const formStateManager = (
	/** @type {HTMLFormElement} */ form,
	/** @type {FormController} */ controller,
	/** @type {FormRule[]} */ rules
) => {
	const inputStates = {
		touched: {},
		value: {},
		valid: {},
		invalidRule: {}
	};

	const elementRule = (element) => {
		let name = element.name;
		let rule = rules.find((rule) => {
			if (rule.displayQs) {
				if (element.matches(rule.displayQs)) {
					name = rule.name;
					return true;
				}
			}
			return rule.name === name;
		});
		return rule;
	};

	const checkAllRules = () => {
		let allValid = true;
		rules.forEach((rule) => {
			const val = inputStates.value[rule.name];
			let inputValid = true;
			if (val !== undefined) {
				const validations = rule.validation;
				for (var i = 0; i < validations.length; i++) {
					if (window.$test) {
						debugger;
					}
					const valid = validationRules[validations[i].rule.type](val);
					if (!valid) {
						inputStates.invalidRule[rule.name] = validations[i].rule.type;
						inputValid = false;
						break;
					}
				}
			} else {
				inputValid = false;
			}
			inputStates.valid[rule.name] = inputValid;
			if (!inputValid) {
				allValid = false;
			}
		});
		controller.validated(inputStates, allValid);
	};

	const inputs = Array.from(form.querySelectorAll('input, textarea')).filter(
		(item) => item.type !== 'hidden' && !item.disabled
	);
	inputs.forEach((input) => {
		const errorElement = document.createElement('span');
		errorElement.className = 'validation-error';
		errorElement.textContent = input.name || input.id;
		errorElement.style.display = 'none';
		input.parentElement.appendChild(errorElement);

		input.addEventListener('input', (e) => {
			window.requestAnimationFrame(() => {
				const rule = elementRule(e.target);
				if (!rule) {
					return;
				}
				let name = rule.name;
				const value = e.target.value;
				inputStates.touched[name] = true;
				inputStates.value[name] = value;

				checkAllRules();
			});
		});

		input.addEventListener('blur', (e) => {
			const rule = elementRule(e.target);
			if (!rule) {
				return;
			}
			showHideError(rule, input, inputStates.valid[rule.name], inputStates.invalidRule[rule.name]);
		});
		input.addEventListener('keypress', (e) => {
			if (e.charCode === 13) {
				const index = inputs.indexOf(input) + 1;
				const nextInput = inputs[index];
				if (nextInput) {
					nextInput.focus();
				}
			}
		});
	});

	window.requestAnimationFrame(() => {
		inputs.forEach((input) => {
			const rule = elementRule(input);
			if (rule) {
				inputStates.value[rule.name] = input.value;
			}
		});
		checkAllRules();
	});
	if (window.innerWidth > 375) {
		setTimeout(() => {
			inputs[0].focus();
		}, 1000);
	}
};
