export const initMainContentScroll = () => {
	var layoutContent = null;
	var layoutBasket = null;
	var pathName = '';
	document.addEventListener('wheel', (e) => {
		if (pathName !== window.location.pathname) {
			layoutContent = null;
			pathName = window.location.pathname;
		}
		if (!layoutContent) {
			if (window.location.pathname.startsWith('/menu/')) {
				layoutContent = document.querySelector('.ls-modifier-list-wrapper');
			} else {
				layoutContent = document.querySelector('.ls-layout-content');
			}
		}
		if (!layoutBasket) {
			layoutBasket = document.querySelector('.ls-layout-basket');
		}
		if (layoutBasket && layoutContent) {
			const basketBody = layoutBasket.querySelector('.body');
			if (!basketBody || basketBody.scrollHeight <= basketBody.clientHeight) {
				layoutContent.scrollTop += e.deltaY;
			}
		}
	});
};
