import React from "react";
import { Svg } from "../Svg";
import BannerHeader from "../Banner/header";

import { confirmAlert } from "react-confirm-alert";
import { withRouter } from "react-router-dom";
import { ROOT, SEARCH } from "../../utils/routeConstants";
import "react-confirm-alert/src/react-confirm-alert.css";

const AppHeader = ({
  history,
  restaurantName,
  heading,
  backButtonClick,
  isShowHours = false,
  isShowBackButton = true,
  openModal,
  isHeightChange,
  selectedLocationInfo
}) => {

  const handleModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-body">
            <div className="alert-content">
              <div className="exclamation"></div>
              <div className="alert-description">
                {" "}
                Changing Location Will Clear
              </div>
              <div className="alert-description"> The Existing Cart</div>
            </div>
            <div className="alert-footer">
              <button
                className="dark-theme"
                onClick={() => {
                  onClose();
                  openModal();
                }}
              >
                Change Location
              </button>
              <button className="cancel" onClick={onClose}>Cancel</button>
            </div>
          </div>
        );
      },
    });
  };

  const gotoAreaSelect = () => {
    handleModal();
  };

  const gotoHome = () => {
    history.push(ROOT + SEARCH);
  };

  backButtonClick = () => {
    history.goBack();
  };

  return (
    <div className="ls-app-header" style={{
      height: isHeightChange === true ? "60px" : true,
      minHeight: isHeightChange === true ? "60px" : true
    }}>
      {isShowBackButton && (
        <div className="header-text-left" onClick={backButtonClick}>
          Back
        </div>
      )}
      {restaurantName && (
        <div className="header-text-center--restaurant" onClick={gotoHome}>
          {restaurantName}
        </div>
      )}
      {selectedLocationInfo && (
        <div className="header-text-center--selectedcity">
          <div className="header-text-city-label" onClick={gotoAreaSelect}>
            Change Delivery Location
            <Svg
              type="down-arrow"
              stroke="#000000"
              height="14px"
              width="14px"
            />
          </div>
        </div>
      )}
      {heading && (
        <div className="header-text-center--heading">
          <div className="header-text-center-label">{heading}</div>
        </div>
      )}

      {isShowHours && (
        <div className="header-text-right">
          <BannerHeader />
        </div>
      )}
    </div>
  );
};

export default withRouter(AppHeader);
