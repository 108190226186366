import React from 'react';
import { Svg } from '../../components/Svg';

const BasketHeader = ({ itemCount }) => {
	// return (
	//   <div className="header">
	//     <div className="title-bar">
	//       <button
	//         type="button"
	//         className="header-button header-button-back"
	//         onClick={() => backButtonClick()}
	//       >
	//         <i className="material-icons">keyboard_arrow_left</i>
	//       </button>
	//       <div className="title">
	//         My Basket
	//         <div className="subtitle">{restaurantName}</div>
	//       </div>
	//       <div className="header-button-wrapper">
	//         <button type="button" className="header-button">
	//           <i className="material-icons">shopping_cart</i>
	//         </button>
	//         {/* <button type="button" className="header-button">
	//           <i className="material-icons">search</i>
	//         </button> */}
	//       </div>
	//     </div>
	//   </div>
	// );

	return (
		<div className="ls-basket-header">
			Your Basket <Svg type="basket" width="25" height="24" className="ls-icon-basket" />
			<div className="ls-basket-oval">{itemCount}</div>
		</div>
	);
};

export default BasketHeader;
