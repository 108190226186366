/* eslint-disable camelcase */
import {
  CREDIT_CARD_TOKENIZATION_FAILED,
  CREDIT_CARD_TOKENIZATION_SUCCESS,
  SET_PAYMENT_METHOD,
  SET_LOADER_OFF,
  SET_LOADER_ON,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_STATUS,
  SET_PAYMENT_GATEWAY_CONFIGURATION
} from "../actionTypes";
import { PAYFORT_STATUS } from "../../utils/enums";
import { CHECKOUT, PAYMENT as PAYMENT_ROUTE, SEARCH } from "../../utils/routeConstants";
import { FETCH_PAYMENT_GATEWAY } from "../../utils/apiConstants";
import axios from "axios";
import uuid from "uuid";

const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;

export const setCreditCardResponse = (cardResponse, history) => {
  return (dispatch) => {
    // eslint-disable-next-line camelcase
    dispatch({ type: SET_LOADER_ON });
    const {
      status,
      card_number,
      card_holder_name,
      token_name,
      merchant_reference,
    } = cardResponse;
    if (status === PAYFORT_STATUS.SUCCESS) {
      const creditObject = {
        cardNumber: card_number,
        cardHolder: card_holder_name,
        tokenname: token_name,
        merchantReference: merchant_reference,
      };

      dispatch({
        type: CREDIT_CARD_TOKENIZATION_SUCCESS,
        payload: creditObject,
      });

      dispatch({ type: SET_LOADER_OFF });
      history.push(CHECKOUT + SEARCH);
    } else {
      dispatch({
        type: CREDIT_CARD_TOKENIZATION_FAILED,
      });
      dispatch({ type: SET_LOADER_OFF });
    }
  };
};

export const setPaymentMethod = (paymenthMethod) => {
  return (dispatch) => {
    dispatch({ type: SET_PAYMENT_METHOD, payload: paymenthMethod });
  };
};

export const setPaymentMethods = (paymenthMethods) => {
  return (dispatch) => {
    dispatch({ type: SET_PAYMENT_METHODS, payload: paymenthMethods });
  };
};

export const setPaymentStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_PAYMENT_STATUS, payload: status });
  };
};

export const getPaymentGateWayConfiguration = (history) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADER_ON });
    const { appParams } = getState().app;
    axios.get(appApiEndPoint + FETCH_PAYMENT_GATEWAY + "/PAYFORT/configurations/byBrand/" + appParams.restaurantId)
      .then(res => {
        if (res.status === 200) {
          let merchantReference = uuid.v4();
          let payloadOb = { ...res.data.configurations, merchantReference };
          dispatch({ type: SET_PAYMENT_GATEWAY_CONFIGURATION, payload: payloadOb });
          history.push(PAYMENT_ROUTE + SEARCH);
        }
        dispatch({ type: SET_LOADER_OFF });
      }).catch((error) => {
        dispatch({ type: SET_LOADER_OFF });
      });
  };
};
