/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "../../components/Toast";

import {
  fetchMenuItem,
  fetchFromCartItemId
} from "../../store/actions/menuActions";
import {
  addItemToCart,
  updateExistingCartItem,
  removeItemFromCart,
} from "../../store/actions/cartActions";
import QuantitySelector from "./QuantitySelector";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/isEmpty";
import { ROOT, SEARCH } from "../../utils/routeConstants";
import TagManager from "react-gtm-module";
import AppHeader from "../../components/AppHeader";
import Basket from "../Basket";
import Breadcrumb from "../../components/Breadcrumb";
import { MiniQuantitySelector } from "./MiniQuantitySelector";
import OrderFlowExceptionTypes from "../../utils/OrderFlowExceptionTypes";
import whiteBackground from "../../assets/img/white-background.jpg";
let isGTMSent = true;

class ItemDetail extends Component {
  state = {
    itemCount: 1,
    selectedExtraItems: [],
    extraItemPrice: 0,
    missedMandatoryItems: [],
    // Indicates whether selected item is already in the card
  };

  componentDidMount() {
    window.scroll(0, 0);

    const { match, fetchSelectedItem } = this.props;
    const { categoryId, menuId, cartItemId } = match.params;

    fetchSelectedItem(categoryId, menuId, cartItemId);

    isGTMSent = false;

    if (this.props.ui.screenSize === "sm") {
      window.requestAnimationFrame(() => {
        const layoutContent = document.querySelector(".ls-layout-content");
        const headerDescription = document.querySelector(
          ".ls-item-detail-header-detail-descr"
        );
        const backButton = document.querySelector("#back-button");

        let scrollChecking = false;
        let timer = null;

        const headerDescriptionCheck = (scrollTop) => {
          if (scrollChecking) {
            return;
          }
          if (scrollTop > 315) {
            scrollChecking = true;
            headerDescription.style.display = "none";
            backButton.style.display = "none";
          } else {
            if (headerDescription.style.display === "none") {
              scrollChecking = true;
              headerDescription.style.display = "";
              backButton.style.display = "";
            }
          }
        };
        layoutContent &&
          layoutContent.addEventListener("scroll", () => {
            headerDescriptionCheck(layoutContent.scrollTop);
            if (scrollChecking) {
              if (timer) {
                clearTimeout(timer);
              }
              timer = setTimeout(() => {
                scrollChecking = false;
                headerDescriptionCheck(layoutContent.scrollTop);
              }, 100);
            }
          });
      });
    }
  }

  // remove already selected modifiers when the modifiers are identified as inactive from server side
  filterSelectedModifiers = (extraItems, orderResponse) => {
    if (
      orderResponse &&
      orderResponse.errorType === OrderFlowExceptionTypes.MODIFIER_INACTIVE &&
      orderResponse.deSelectingModifierInfo &&
      Object.keys(orderResponse.deSelectingModifierInfo).length > 0
    ) {
      const deSelectingModifierInfo = orderResponse.deSelectingModifierInfo;

      var i = extraItems.length;
      while (i--) {
        if (deSelectingModifierInfo[extraItems[i].modifierId]) {
          extraItems.splice(i, 1);
        }
      }
    }

    return extraItems;
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      existing,
      extraItemPrice,
      extraItems,
      itemCount,
    } = nextProps.selectedItem;
    if (this.props.match.url !== nextProps.match.url) {
      window.requestAnimationFrame(() => {
        this.componentDidMount();
      });
    } else if (existing) {
      const filteredExtraItems = this.filterSelectedModifiers(
        extraItems.slice(),
        this.props.orderResponse
      );
      this.setState({
        itemCount,
        selectedExtraItems: filteredExtraItems,
        extraItemPrice,
        existing: true,
      });
    }
  }

  addItem = () => {
    const { itemCount } = this.state;
    this.setState({ itemCount: itemCount + 1 }, () => {
      this.calculateExtraItemPrice();
    });
  };

  removeItem = () => {
    const { itemCount } = this.state;
    this.setState({ itemCount: itemCount - 1 }, () => {
      this.calculateExtraItemPrice();
    });
  };

  getCheckboxValue = (event, id) => {
    const { selectedItem } = this.props;

    const { checked } = event.target;
    const { selectedExtraItems, extraItemPrice } = this.state;
    const extraItem = selectedItem.menuExtraItems.find((item) => {
      return item.id === id;
    });

    if (checked) {
      this.setState({
        selectedExtraItems: [...selectedExtraItems, extraItem],
        extraItemPrice: extraItemPrice + extraItem.price,
      });
    } else {
      const extraItems = selectedExtraItems.filter((item) => item.id !== id);
      this.setState({
        selectedExtraItems: extraItems,
        extraItemPrice: extraItemPrice - extraItem.price,
      });
    }
    this.calculateExtraItemPrice();
  };

  calculateExtraItemPrice = () => {
    const { selectedExtraItems, itemCount } = this.state;

    let price = 0;
    selectedExtraItems.forEach((item) => {
      price += (item.price * item.quantity) * itemCount;
    });
    this.setState({ extraItemPrice: price });
  };

  handleItemRemove = () => {
    const {
      selectedItem,
      removeFromCart,
      history,
      restaurant,
      appParams,
    } = this.props;
    removeFromCart(selectedItem);
    this.sendGTMDataForRemove(selectedItem, 1, restaurant, appParams);
    history.push(ROOT + SEARCH);
  };

  sendGTMDataForRemove = (item, itemCount, restaurant, appParams) => {
    const tagManagerArgs = {
      gtmId: appParams.gtmKey,
      dataLayer: {
        event: "removeFromCart",
        ecommerce: {
          currencyCode: appParams.currecyDescription,
          remove: {
            products: [
              {
                id: item.id,
                name: item.name,
                price: item.recipeIds[0].sellingPrice,
                brand: restaurant.name,
                dimension1: null,
                position: 0,
                quantity: item.itemCount,
              },
            ],
          },
        },
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  // hook should go here
  addToBasket = () => {
    const {
      selectedItem,
      addToCart,
      history,
      updateCartItem,
      restaurant,
      appParams,
    } = this.props;

    const { mandatoryExtraItems, recipeIds } = selectedItem;

    const {
      itemCount,
      extraItemPrice,
      selectedExtraItems,
      existing,
    } = this.state;

    const { modifiers } = selectedItem;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < modifiers.length; i++) {
      const extraItems = selectedExtraItems.filter(
        (item) => item.modifierGroupId === modifiers[i].modifierGroupId
      );

      let modifiersCount = 0;
      extraItems.forEach((mod) => {
        modifiersCount += mod.quantity;
      });

      if (
        // skipping radio field validations because they are seperatly valiadated.
        !(modifiers[i].min === 1 && modifiers[i].max === 1) &&
        modifiers[i].min &&
        modifiersCount < modifiers[i].min
      ) {
        toast.error(
          `Minimum ${modifiers[i].min} modifiers should be selected from ${modifiers[i].groupName}`
        );
        return false;
      }
    }

    const missingItems = [];
    mandatoryExtraItems.forEach((mei) => {
      if (!selectedExtraItems.some((es) => es.modifierGroupId === mei)) {
        if (recipeIds[0].recipe.modifiersGroups.length > 0) {
          recipeIds[0].recipe.modifiersGroups.forEach((md) => {
            if (md.modifierGroupId === mei) {
              missingItems.push(md.modifierGroupId);
            }
          });
        }
      }
    });

    if (missingItems.length > 0) {
      this.setState({ missedMandatoryItems: missingItems });

      const missingItemsNames = [];
      missingItems.forEach((mei) => {
        if (recipeIds[0].recipe.modifiersGroups.length > 0) {
          recipeIds[0].recipe.modifiersGroups.forEach((md) => {
            if (md.modifierGroupId === mei) {
              missingItemsNames.push(md.groupName);
            }
          });
        }
      });

      toast.error(`${missingItemsNames.join()} are missing`);

      window.requestAnimationFrame(() => {
        const errorElement = document.querySelector(
          ".ls-modifier-mandatory-error"
        );
        if (errorElement) {
          document.querySelector(".ls-layout-content").scrollTo({
            top:
              errorElement.getBoundingClientRect().top -
              (document.querySelector(".ls-item-detail-header-detail")
                .clientHeight +
                50),
            behavior: "smooth",
          });
          setTimeout(() => {
            errorElement.parentElement.parentElement.classList.add("ls-shake");
            setTimeout(() => {
              errorElement.parentElement.parentElement.classList.remove(
                "ls-shake"
              );
            }, 1500);
          }, 500);
        }
      });

      return false;
    }
    const { match } = this.props;
    const { cartItemId } = match.params;
    const basketObject = {
      id: cartItemId,
      item: selectedItem,
      extraItems: selectedExtraItems,
      extraItemPrice,
      itemCount,
    };

    if (existing) {
      updateCartItem(basketObject);
    } else {
      addToCart(basketObject);
    }

    this.sendGTMData(selectedItem, itemCount, restaurant, appParams);
    history.push(ROOT + SEARCH);
  };

  sendGTMData = (item, itemCount, restaurant, appParams) => {
    const tagManagerArgs = {


      gtmId: appParams.gtmKey,
      dataLayer: {
        event: "addToCart",
        ecommerce: {
          currencyCode: appParams.currecyDescription,
          add: {
            products: [
              {
                id: item.id,
                name: item.name,
                price: item.recipeIds[0].sellingPrice,
                brand: restaurant.name,
                dimension1: null,
                position: 0,
                quantity: itemCount,
              },
            ],
          },
        },
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  sendGTMProductView = (item, restaurant, appParams) => {
    if (!isGTMSent) {
      const tagManagerArgs = {
        gtmId: appParams.gtmKey,
        dataLayer: {
          event: "productDetails",
          ecommerce: {
            detail: {
              products: [
                {
                  id: item.id,
                  name: item.name,
                  price: item.recipeIds[0].sellingPrice,
                  brand: restaurant.name,
                  dimension1: null,
                  position: 0,
                },
              ],
            },
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
      isGTMSent = true;
    }
  };

  onCloseClick = () => {
    const { history } = this.props;
    history.push(ROOT + SEARCH);
  };

  handleSingleExtraItem = (item, modifierGroupId, recipeId) => {
    const newExtraItem = { ...item, modifierGroupId, quantity: 1, recipeId };

    const { selectedExtraItems } = this.state;

    const newSelectedItems = selectedExtraItems.filter(
      (sei) => sei.modifierGroupId !== newExtraItem.modifierGroupId
    );

    newSelectedItems.push(newExtraItem);

    this.setState(
      {
        selectedExtraItems: newSelectedItems,
      },
      () => this.calculateExtraItemPrice()
    );
  };

  removeItemFromMultipleExtraItems = (item) => {
    const { selectedExtraItems } = this.state;
    const existingItemRemoved = selectedExtraItems.filter(
      (sei) => sei.modifierId !== item.modifierId
    );

    this.setState(
      {
        selectedExtraItems: existingItemRemoved,
      },
      () => this.calculateExtraItemPrice()
    );
  };

  handleMultipleExtraItemsCheckBox = (
    item,
    maxItems,
    modifierGroupId,
    recipeId,
    quantity) => {
    const { selectedExtraItems } = this.state;

    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    // if the item is there remove it.
    if (modifierItem) {
      this.setState(
        {
          selectedExtraItems: selectedExtraItems.filter(
            (sei) => sei.modifierId !== modifierItem.modifierId
          ),
        },
        () => this.calculateExtraItemPrice()
      );
    } else {

      let totalQuantity = 0;
      this.state.selectedExtraItems.forEach((item) => {
        if (item.modifierGroupId === modifierGroupId) {
          totalQuantity += item.quantity;
        }
      });

      if (maxItems && totalQuantity >= maxItems) {
        toast.error(`Maximum ${maxItems} modifiers are allowed`);
        return false;
      }
      // if the item is not there add it.
      this.setState(
        {
          selectedExtraItems: selectedExtraItems.concat([
            { ...item, quantity: quantity || 1, modifierGroupId, recipeId },
          ]),
        },
        () => this.calculateExtraItemPrice()
      );
    }
  }

  addModifierItem = (
    item,
    maxItems,
    modifierGroupId,
    recipeId,
    quantity) => {
    const { selectedExtraItems } = this.state;

    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    const selectedModifierGroupOb = selectedExtraItems.filter(
      (selectedItem) => {
        return selectedItem.modifierGroupId === modifierGroupId;
      }
    );

    let modifierCount = 0;
    selectedModifierGroupOb.forEach((modifierOb) => {
      modifierCount += modifierOb.quantity;
    });

    // if the item is there remove it.
    if (modifierItem) {
      if (maxItems && modifierCount + quantity > maxItems) {
        toast.error(`Maximum ${maxItems} modifiers are allowed`);
        return false;
      } else {
        if (modifierItem.modifierQty < modifierItem.quantity + quantity) {
          toast.error(`Maximum ${modifierItem.modifierQty}  items are allowed for this modifier`);
          return false;
        } else {

          modifierItem.quantity = modifierItem.quantity + quantity;
          this.setState(
            {
              selectedExtraItems: selectedExtraItems.filter(
                (sei) => sei.modifierId !== modifierItem.modifierId
              )
            },
            () => {
              this.state.selectedExtraItems.push(modifierItem);
              this.setState({
                selectedExtraItems: this.state.selectedExtraItems
              });
              this.calculateExtraItemPrice()
            }
          );
        }
      }
    } else {
      if (maxItems && modifierCount + quantity > maxItems) {
        toast.error(`Maximum ${maxItems} modifiers are allowed`);
        return false;
      } else {
        this.setState(
          {
            selectedExtraItems: selectedExtraItems.concat([
              { ...item, quantity: quantity || 1, modifierGroupId, recipeId },
            ]),
          },
          () => this.calculateExtraItemPrice()
        );
      }
    }
  }

  removeModifierItem = (
    item,
    maxItems,
    modifierGroupId,
    recipeId,
    quantity) => {
    const { selectedExtraItems } = this.state;

    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    if (modifierItem) {
      if (modifierItem.quantity - 1 >= 1) {
        modifierItem.quantity = modifierItem.quantity + quantity;
        this.setState(
          {
            selectedExtraItems: selectedExtraItems.filter(
              (sei) => sei.modifierId !== modifierItem.modifierId
            )
          },
          () => {
            this.state.selectedExtraItems.push(modifierItem);
            this.setState({
              selectedExtraItems: this.state.selectedExtraItems
            });
            this.calculateExtraItemPrice()
          }
        );
      } else {
        this.setState(
          {
            selectedExtraItems: selectedExtraItems.filter(
              (sei) => sei.modifierId !== item.modifierId
            )
          },
          () => {
            this.calculateExtraItemPrice()
          }
        );
      }
    }
  }

  getMultipleExtraItemCount = (item) => {
    const { selectedExtraItems } = this.state;
    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    return modifierItem ? modifierItem.quantity : 0;
  };

  checkIfIncludedInExtraItems = (item) => {
    const { selectedExtraItems } = this.state;
    const extraItem = selectedExtraItems.find(
      (it) => it.modifierId === item.modifierId
    );
    return !!extraItem;
  };

  handleBasketRoute = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  renderBreadCrumb = (selectedItemName) => {
    const items = [
      { url: ROOT + SEARCH, name: "Home Page" },
      selectedItemName
    ];
    return <Breadcrumb items={items} leftSide={true} />;
  };

  renderExtraItems = () => {
    const { selectedItem, appParams } = this.props;
    const { currecyDescription, currecyISOPrecision } = appParams;
    const { missedMandatoryItems } = this.state;
    const elements = [];
    selectedItem.modifiers.forEach((md, index) => {
      if (md.min && md.min === 1 && md.max && md.max === 1) {
        elements.push(
          <ul key={index} className="ls-modifier-list">
            <li className="ls-modifier-list-heading">
              <span className="selection-title">{md.groupName}</span>
              <span className="selection-text">(Select one item)</span>
              {missedMandatoryItems.includes(md.modifierGroupId) && (
                // this is used as the scroll in anchor
                <span className="ls-modifier-mandatory-error"></span>
              )}
            </li>
            <div>
              {md.modifier.map((mod, index) => {
                const checked = this.checkIfIncludedInExtraItems(mod);
                return (
                  <>
                    {/* specific fix to tabkha [GRB-1198] Loading all data to client side and do the filtering at the UI level to
                 avoid heavy nested filtering of modifiers within recipes in the server-side which affects to every menu reading*/}
                    {mod.active ? (
                      <li key={index} className="ls-modifier-list-item">
                        <div
                          className="ls-modifier-list-item-content"
                          onClick={() =>
                            this.handleSingleExtraItem(
                              mod,
                              md.modifierGroupId,
                              md.recipeId
                            )
                          }
                        >
                          <div
                            className={
                              (mod.price ? "title" : "title-no-margin")
                            }
                          >
                            {mod.displayName}
                          </div>
                          {mod.originalPrice ? (
                            <div
                              className={mod.price ? "price" : "display-none"}
                            >
                              {` ${currecyDescription}`}{" "}
                              <del>{`${mod.originalPrice.toFixed(currecyISOPrecision)}`}</del>{" "}
                            </div>
                          ) : null}
                          <div
                            className={mod.price ? "price" : "display-none"}
                          >
                            <span className="plus">+</span>
                            {` ${currecyDescription} ${mod.price.toFixed(currecyISOPrecision)}`}</div>
                        </div>
                        <label
                          className="ls-dj-radio"
                          htmlFor={`${mod.modifierId}:${mod.displayName}`}
                        >
                          <input
                            id={`${mod.modifierId}:${mod.displayName}`}
                            type="radio"
                            checked={checked}
                            onChange={() =>
                              this.handleSingleExtraItem(
                                mod,
                                md.modifierGroupId,
                                md.recipeId
                              )
                            }
                          />
                          <span className="ls-checkmark" />
                        </label>
                      </li>
                    ) : null}
                  </>
                );
              })}
            </div>
          </ul>
        );
      } else {
        elements.push(
          <ul key={elements.length + "1"} className="ls-modifier-list">
            <li className="ls-modifier-list-heading">
              <span className="selection-title">{md.groupName}</span>
              <span className="selection-text">(Select multiple items)</span>
            </li>
            {md.modifier.map((mod, index) => {
              const checked = this.checkIfIncludedInExtraItems(mod);
              const quantity = this.getMultipleExtraItemCount(mod);
              return (
                <>
                  {/* specific fix to tabkha [GRB-1198] Loading all data to client side and do the filtering at the UI level to
               avoid heavy nested filtering of modifiers within recipes in the server-side which affects to every menu reading*/}
                  {mod.active ? (
                    <li key={index} className="ls-modifier-list-item">
                      <div
                        className="ls-modifier-list-item-content"
                        onClick={() => {
                          return this.handleMultipleExtraItemsCheckBox(
                            mod,
                            md.max,
                            md.modifierGroupId,
                            // md.groupName,
                            md.recipeId
                          );
                        }}
                      >
                        <div
                          className={mod.price ? "title" : "title-no-margin"}
                        >
                          {mod.displayName}
                        </div>
                        {mod.originalPrice ? (
                          <div className={mod.price ? "price" : "display-none"}>
                            {` ${currecyDescription}`}{" "}
                            <del>{`${mod.originalPrice.toFixed(currecyISOPrecision)}`}</del>{" "}
                          </div>
                        ) : null}

                        <div className={mod.price ? "price" : "display-none"}>
                          <span className="plus">+</span>
                          {` ${currecyDescription} ${mod.price.toFixed(currecyISOPrecision)}`}
                        </div>
                      </div>
                      {mod.modifierQty > 1 ? (
                        <MiniQuantitySelector
                          modifier={mod}
                          itemCount={mod.quantity === undefined ? quantity : mod.quantity}
                          onAdd={() => {
                            this.addModifierItem(
                              mod,
                              md.max,
                              md.modifierGroupId,
                              md.recipeId,
                              1)
                          }}
                          onRemove={() => {
                            this.removeModifierItem(
                              mod,
                              md.max,
                              md.modifierGroupId,
                              md.recipeId,
                              -1)
                          }}
                        />
                      ) : (
                        <label
                          className="ls-dj-checkbox"
                          htmlFor={`${mod.modifierId}:${mod.displayName}`}
                        >
                          <input
                            id={`${mod.modifierId}:${mod.displayName}`}
                            type="checkbox"
                            checked={checked}
                            onChange={() => {
                              return this.handleMultipleExtraItemsCheckBox(
                                mod,
                                md.max,
                                md.modifierGroupId,
                                md.recipeId
                              );
                            }}
                          />
                          <span className="ls-checkmark" />
                        </label>
                      )}
                    </li>
                  ) : null}
                </>
              );
            })}
          </ul>
        );
      }
    });

    return elements;
  };

  render() {
    const {
      selectedItem,
      isLoading,
      appParams,
      restaurant,
      cart,
      restaurantName
    } = this.props;
    const { currecyDescription, currecyISOPrecision } = appParams;
    const { itemCount, extraItemPrice, existing } = this.state;

    const { name, details, price, images, originalPrice } = selectedItem;
    let bannerImage = "";
    if (images && images.length > 0) {
      const [bImage] = images;
      bannerImage = bImage;
    } else {
      bannerImage = whiteBackground;
    }

    if (!isEmpty(selectedItem)) {
      this.sendGTMProductView(selectedItem, restaurant, appParams);
    }

    // const isEmpty = Object.keys(selectedItem).length === 0;
    return (
      <React.Fragment>
        <div className="App ls-layout-home ls-item-detail">
          {isLoading && <Loader />}
          {!isEmpty(selectedItem) && (
            <React.Fragment>
              <div className="ls-layout-content">
                <div>
                  {this.props.ui.screenSize === "lg" && (
                    <AppHeader
                      cart={cart}
                      restaurantName={restaurantName}
                      isShowHours={true}
                      backButtonClick={this.onCloseClick.bind(this)}
                    />
                  )}
                  {this.renderBreadCrumb(selectedItem.name)}
                  <button
                    type="button"
                    id="back-button"
                    className="ls-banner-button"
                    onClick={() => this.onCloseClick()}
                  >
                    <img
                      src={require("../../assets/img/back.svg")}
                      alt="back"
                    />
                  </button>
                  <img
                    className="ls-item-detail-header-image"
                    src={bannerImage}
                    alt="menu-header-logo"
                  />
                  <div className="ls-item-detail-header-detail">
                    <div className="ls-item-detail-header-detail-name">
                      {name}
                    </div>
                    <div className="ls-item-detail-header-detail-descr">
                      {details}
                    </div>
                    <QuantitySelector
                      originalPrice={originalPrice}
                      details={details}
                      price={price}
                      currecyDescription={currecyDescription}
                      currecyISOPrecision={currecyISOPrecision}
                      itemCount={itemCount}
                      onAdd={this.addItem}
                      addToBasket={this.addToBasket}
                      onRemove={this.removeItem}
                      extraItemPrice={extraItemPrice}
                      isOpenCategory={selectedItem.isOpenCategory}
                    />
                  </div>

                  <div className="ls-modifier-list-wrapper">
                    {this.renderExtraItems()}
                    {this.props.ui.screenSize === "sm" && (
                      <div className="ls-modifier-list-wrapper-bottom" />
                    )}
                  </div>
                  <QuantitySelector
                    originalPrice={originalPrice}
                    mobile={true}
                    details={details}
                    price={price}
                    currecyDescription={currecyDescription}
                    currecyISOPrecision={currecyISOPrecision}
                    itemCount={itemCount}
                    onAdd={this.addItem}
                    addToBasket={this.addToBasket}
                    onRemove={this.removeItem}
                    extraItemPrice={extraItemPrice}
                    existingItem={existing}
                    isOpenCategory={selectedItem.isOpenCategory}
                  />
                  <div className="ls-footer-fixed show">
                    {!existing ? (
                      <button
                        type="button"
                        className={(selectedItem.isOpenCategory === true ? "ls-cart-button" : "ls-cart-button-disabled") + " center-text"}
                        onClick={() => selectedItem.isOpenCategory(this.addToBasket())}
                      >
                        <span>Add to basket</span>
                      </button>
                    ) : (
                      <React.Fragment>
                        <div className="ls-remove-from-cart-wrapper">
                          <button
                            type="button"
                            onClick={() => this.handleItemRemove()}
                          >
                            Remove Item from Cart
                          </button>
                        </div>
                        <button
                          type="button"
                          className={(selectedItem.isOpenCategory === true ? "ls-cart-button center-text" : "ls-cart-button-disabled") + " center-text"}
                          onClick={() => this.addToBasket()}
                        >
                          <span>Update basket</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              {this.props.ui.screenSize === "lg" && (
                <div className="ls-layout-basket">
                  <Basket showBasketHeader={true} />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {/* {this.props.ui.screenSize === 'lg' && <AppFooter />} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedItem: state.menu.currentSelectedItem,
    restaurant: state.menu.restaurant,
    isLoading: state.app.loading,
    appParams: state.app.appParams,
    ui: state.ui,
    orderResponse: state.cart.orderResponse,
    cart: state.cart,
    restaurantName: state.app.appParams.restaurantName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelectedItem: (categoryId, menuId, cartItemId) => {
      const dispatchFn =
        cartItemId === "0"
          ? fetchMenuItem(categoryId, menuId)
          : fetchFromCartItemId(cartItemId);
      return dispatch(dispatchFn);
    },
    addToCart: (menuItem) => dispatch(addItemToCart(menuItem)),
    updateCartItem: (menuItem) => dispatch(updateExistingCartItem(menuItem)),
    removeFromCart: (menuItem) => dispatch(removeItemFromCart(menuItem))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetail);
