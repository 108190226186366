import {
  FETCH_RESTAURANT_SUCCESS,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ITEM,
  CLEAR_MENU_ON_INITIAL_SCREEN,
  FETCH_FROM_CART_ITEM_ID
} from "../actionTypes";

const initState = {
  restaurant: {},
  menuItems: [],
  cart: [],
  currentSelectedItem: {}
};

const menuReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_MENU_ON_INITIAL_SCREEN:
      return {
        ...state,
        menuItems: []
      };

    case FETCH_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant: action.payload
      };
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        menuItems: action.payload
      };
    case FETCH_FROM_CART_ITEM_ID: {
      return {
        ...state,
        currentSelectedItem: action.payload
      };
    }
    case FETCH_MENU_ITEM:
      return {
        ...state,
        currentSelectedItem: action.payload
      };

    default:
      return state;
  }
};

export default menuReducer;
