/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "../../components/Toast";

import { fetchFromCartItemId } from "../../store/actions/menuActions";
import {
  updateExistingCartItem,
  removeItemFromCart,
} from "../../store/actions/cartActions";
import QuantitySelector from "../ItemDetail/QuantitySelector";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/isEmpty";
import { ROOT, BASKET, SEARCH } from "../../utils/routeConstants";
import TagManager from "react-gtm-module";

class ItemDetail extends Component {
  state = {
    itemCount: 1,
    selectedExtraItems: [],
    extraItemPrice: 0,
    missedMandatoryItems: [],
    // Indicates whether selected item is already in the card
  };

  componentDidMount() {
    window.scroll(0, 0);

    const { match, fetchSelectedItem } = this.props;
    const { itemId } = match.params;
    fetchSelectedItem(itemId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      existing,
      extraItemPrice,
      extraItems,
      itemCount,
    } = nextProps.selectedItem;
    if (existing) {
      this.setState({
        itemCount,
        selectedExtraItems: extraItems,
        extraItemPrice,
        existing: true,
      });
    }
  }

  addItem = () => {
    const { itemCount } = this.state;
    this.setState({ itemCount: itemCount + 1 }, () => {
      this.calculateExtraItemPrice();
    });
  };

  removeItem = () => {
    const { itemCount } = this.state;
    this.setState({ itemCount: itemCount - 1 }, () => {
      this.calculateExtraItemPrice();
    });
  };

  getCheckboxValue = (event, id) => {
    const { selectedItem } = this.props;

    const { checked } = event.target;
    const { selectedExtraItems, extraItemPrice } = this.state;
    const extraItem = selectedItem.menuExtraItems.find((item) => {
      return item.id === id;
    });

    if (checked) {
      this.setState({
        selectedExtraItems: [...selectedExtraItems, extraItem],
        extraItemPrice: extraItemPrice + extraItem.price,
      });
    } else {
      const extraItems = selectedExtraItems.filter((item) => item.id !== id);
      this.setState({
        selectedExtraItems: extraItems,
        extraItemPrice: extraItemPrice - extraItem.price,
      });
    }
    // this.calculateExtraItemPrice();
  };

  calculateExtraItemPrice = () => {
    const { selectedExtraItems, itemCount } = this.state;

    let price = 0;
    selectedExtraItems.forEach((item) => {
      price += item.price * itemCount;
    });
    this.setState({ extraItemPrice: price });
  };

  handleItemRemove = () => {
    const {
      removeFromCart,
      history,
      selectedItem,
      restaurant,
      appParams,
    } = this.props;
    const { match } = this.props;
    const { itemId } = match.params;
    removeFromCart(itemId);

    this.sendGTMData(selectedItem, 1, restaurant, appParams);

    history.push(BASKET + SEARCH);
  };

  sendGTMData = (item, itemCount, restaurant, appParams) => {
    const tagManagerArgs = {
      gtmId: appParams.gtmKey,
      dataLayer: {
        event: "removeFromCart",
        ecommerce: {
          currencyCode: appParams.currecyDescription,
          remove: {
            products: [
              {
                id: item.id,
                name: item.name,
                price: item.recipeIds[0].sellingPrice,
                brand: restaurant.name,
                dimension1: null,
                position: 0,
                quantity: item.itemCount,
              },
            ],
          },
        },
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  removeItemFromMultipleExtraItems = (item) => {
    const { selectedExtraItems } = this.state;
    const existingItemRemoved = selectedExtraItems.filter(
      (sei) => sei.modifierId !== item.modifierId
    );

    this.setState(
      {
        selectedExtraItems: existingItemRemoved,
      },
      () => this.calculateExtraItemPrice()
    );
  };

  // hook should go here
  addToBasket = () => {
    const { selectedItem, history, updateCartItem } = this.props;

    const { match } = this.props;
    const { itemId } = match.params;

    const { mandatoryExtraItems, recipeIds } = selectedItem;
    const {
      modifiers: [modifiers],
    } = selectedItem;

    const { itemCount, extraItemPrice, selectedExtraItems } = this.state;

    // loop each modifier group

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < modifiers.length; i++) {
      const extraItems = selectedExtraItems.filter(
        (item) => item.modifierGroupId === modifiers[i].modifierGroupId
      );

      if (
        // skipping radio field validations because they are seperatly valiadated.
        !(modifiers[i].min === 1 && modifiers[i].max === 1) &&
        modifiers[i].min &&
        extraItems.length < modifiers[i].min
      ) {
        toast.error(
          `Minimum ${modifiers[i].min} modifiers should be selected from ${modifiers[i].groupName}`
        );
        return false;
      }
    }

    const missingItems = [];

    mandatoryExtraItems.forEach((mei) => {
      if (!selectedExtraItems.some((es) => es.modifierGroupId === mei)) {
        if (recipeIds[0].recipe.modifiersGroups.length > 0) {
          recipeIds[0].recipe.modifiersGroups.forEach((md) => {
            if (md.modifierGroupId === mei) {
              missingItems.push(md.modifierGroupId);
            }
          });
        }
      }
    });

    if (missingItems.length > 0) {
      this.setState({ missedMandatoryItems: missingItems });

      const missingItemsNames = [];
      missingItems.forEach((mei) => {
        if (recipeIds[0].recipe.modifiersGroups.length > 0) {
          recipeIds[0].recipe.modifiersGroups.forEach((md) => {
            if (md.modifierGroupId === mei) {
              missingItemsNames.push(md.groupName);
            }
          });
        }
      });

      toast.error(`${missingItemsNames.join()} are missing`);
      return false;
    }

    const basketObject = {
      id: itemId,
      item: selectedItem,
      extraItems: selectedExtraItems,
      extraItemPrice,
      itemCount,
    };

    updateCartItem(basketObject);
    history.push(ROOT + SEARCH);
  };

  onCloseClick = () => {
    const { history } = this.props;
    history.push(BASKET + SEARCH);
  };

  handleSingleExtraItem = (item, modifierGroupId, recipeId) => {
    const newExtraItem = { ...item, modifierGroupId, quantity: 1, recipeId };

    const { selectedExtraItems } = this.state;

    const newSelectedItems = selectedExtraItems.filter(
      (sei) => sei.modifierGroupId !== newExtraItem.modifierGroupId
    );

    newSelectedItems.push(newExtraItem);

    this.setState(
      {
        selectedExtraItems: newSelectedItems,
      },
      () => this.calculateExtraItemPrice()
    );
  };

  handleMultipleExtraItems = (item, maxItems, modifierGroupId, recipeId) => {
    const { selectedExtraItems } = this.state;

    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    const selectedItemCountInTheGroup = selectedExtraItems.filter(
      (selectedItem) => {
        return selectedItem.modifierGroupId === modifierGroupId;
      }
    );

    // if the item is there remove it.
    if (modifierItem) {
      this.setState(
        {
          selectedExtraItems: selectedExtraItems.filter(
            (sei) => sei.modifierId !== modifierItem.modifierId
          ),
        },
        () => this.calculateExtraItemPrice()
      );
    } else {
      // if the item is not there add it.
      if (maxItems && selectedItemCountInTheGroup.length >= maxItems) {
        toast.error(`Maximum ${maxItems} modifiers are allowed`);
        return false;
      }
      this.setState(
        {
          selectedExtraItems: selectedExtraItems.concat([
            { ...item, quantity: 1, modifierGroupId, recipeId },
          ]),
        },
        () => this.calculateExtraItemPrice()
      );
    }
  };

  getMultipleExtraItemCount = (item) => {
    const { selectedExtraItems } = this.state;
    const modifierItem = selectedExtraItems.find(
      (sei) => sei.modifierId === item.modifierId
    );

    return modifierItem ? modifierItem.quantity : 0;
  };

  checkIfIncludedInExtraItems = (item) => {
    const { selectedExtraItems } = this.state;
    const extraItem = selectedExtraItems.find(
      (it) => it.modifierId === item.modifierId
    );
    return !!extraItem;
  };

  renderExtraItems = () => {
    const { selectedItem, appParams } = this.props;
    const { currecyDescription } = appParams;
    const { missedMandatoryItems } = this.state;
    const { modifiers } = selectedItem;

    const elements = [];
    modifiers.forEach((md, index) => {
      if (md.min === 1 && md.max === 1) {
        elements.push(
          <div key={index}>
            <li className="modifier-list-heading">
              {md.groupName}
              <span>(Select one item)</span>
              {missedMandatoryItems.includes(md.modifierGroupId) && (
                <span style={{ color: "red" }}>(*required)</span>
              )}
            </li>
            <div>
              {md.modifier.map((mod, index) => {
                return (
                  <li key={index} className="modifier-list-item">
                    <div
                      className="modifier-list-item-content"
                      onClick={() =>
                        this.handleSingleExtraItem(
                          mod,
                          md.modifierGroupId,
                          md.recipeId
                        )
                      }
                    >
                      <div className={mod.price ? "title" : "title-no-margin"}>
                        {mod.displayName}
                      </div>
                      <div
                        className={mod.price ? "price" : "display-none"}
                      >{` ${currecyDescription} ${mod.price}`}</div>
                    </div>
                    <label
                      className="dj-radio"
                      htmlFor={`${mod.modifierId}:${mod.displayName}`}
                    >
                      <input
                        id={`${mod.modifierId}:${mod.displayName}`}
                        type="radio"
                        className=""
                        checked={this.checkIfIncludedInExtraItems(mod)}
                        onChange={() =>
                          this.handleSingleExtraItem(
                            mod,
                            md.modifierGroupId,
                            md.recipeId
                          )
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </li>
                );
              })}
            </div>
          </div>
        );
      } else {
        elements.push(
          <div key={index}>
            <li className="modifier-list-heading">
              {md.groupName}
              <span>(Select multiple items)</span>
            </li>
            {md.modifier.map((mod, index) => {
              return (
                <li key={index} className="modifier-list-item">
                  <div
                    className="modifier-list-item-content"
                    onClick={() =>
                      this.handleMultipleExtraItems(
                        mod,
                        md.max,
                        md.modifierGroupId,
                        // md.groupName,
                        md.recipeId
                      )
                    }
                  >
                    <div className={mod.price ? "title" : "title-no-margin"}>
                      {mod.displayName}
                    </div>
                    <div
                      className={mod.price ? "price" : "display-none"}
                    >{` ${currecyDescription} ${mod.price}`}</div>
                  </div>
                  <label
                    className="dj-checkbox"
                    htmlFor={`${mod.modifierId}:${mod.displayName}`}
                  >
                    <input
                      id={`${mod.modifierId}:${mod.displayName}`}
                      type="checkbox"
                      className=""
                      checked={this.checkIfIncludedInExtraItems(mod)}
                      onChange={() =>
                        this.handleMultipleExtraItems(
                          mod,
                          md.max,
                          md.modifierGroupId,
                          // md.groupName,
                          md.recipeId
                        )
                      }
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              );
            })}
          </div>
        );
      }
    });

    return elements;
  };

  render() {
    const { selectedItem, isLoading, appParams, history } = this.props;
    if (!selectedItem) {
      return history.push(BASKET + SEARCH);
    }

    const { name, price, images } = selectedItem;
    const { currecyDescription } = appParams;

    const { itemCount, extraItemPrice } = this.state;

    let bannerImage = "";
    if (images && images.length > 0) {
      const [bImage] = images;
      bannerImage = bImage;
    }
    let bannerImageWithOutCdn = bannerImage;
    bannerImage = `${bannerImage}`;
    // const isEmpty = Object.keys(selectedItem).length === 0;
    return (
      <div className="App">
        {isLoading && <Loader />}
        {!isEmpty(selectedItem) && (
          <React.Fragment>
            {bannerImageWithOutCdn !==
              "IMAGE_RECIPES-e54b88bd-ad1f-4363-9aff-55ec424d51d9.png" ? (
              <div className="header menu-header">
                <img src={bannerImage} alt="menu-header-logo" />
                <button
                  type="button"
                  className="banner-button"
                  onClick={() => this.onCloseClick()}
                >
                  <i className="material-icons">keyboard_backspace</i>
                </button>
              </div>
            ) : (
              <div className="header menu-header-without-image">
                <button
                  type="button"
                  className="banner-button"
                  onClick={() => this.onCloseClick()}
                >
                  <i className="material-icons">keyboard_backspace</i>
                </button>
              </div>
            )}
            <QuantitySelector
              name={name}
              price={price}
              currecyDescription={currecyDescription}
              itemCount={itemCount}
              onAdd={this.addItem}
              addToBasket={this.addToBasket}
              onRemove={this.removeItem}
              extraItemPrice={extraItemPrice}
            />
            <div className="modifier-list-wrapper">
              <ul className="modifier-list">{this.renderExtraItems()}</ul>
            </div>
            <div className="footer-fixed show">
              <React.Fragment>
                <div className="remove-from-cart-wrapper">
                  <button type="button" onClick={() => this.handleItemRemove()}>
                    Remove Item from Cart
                  </button>
                </div>
                <button
                  type="button"
                  className="ls-cart-button center-text"
                  onClick={() => this.addToBasket()}
                >
                  <span>Update basket</span>
                </button>
              </React.Fragment>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedItem: state.menu.currentSelectedItem,
    restaurant: state.menu.restaurant,
    isLoading: state.app.loading,
    appParams: state.app.appParams,
    applicationType: state.app.applicationType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelectedItem: (itemId) => dispatch(fetchFromCartItemId(itemId)),
    updateCartItem: (menuItem) => dispatch(updateExistingCartItem(menuItem)),
    removeFromCart: (menuItem) => dispatch(removeItemFromCart(menuItem)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetail);
