/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

const CartItemSummary = ({
  itemCount,
  name,
  extraItems,
  originalPrice,
  currecyDescription,
  currecyISOPrecision,
  price
}) => {
  // const itemPrice = itemCount * price;
  const extraItemsStr = extraItems.map((i) => i.displayName);
  const sumOfExtraItemOriginalPrices = extraItems.reduce((totalOriginalPrice, item) => totalOriginalPrice + getExtraItemPrice(item) * item.quantity, 0);
  let finalOriginalPrice = 0;
  if (originalPrice !== undefined && originalPrice !== null && !Number.isNaN(originalPrice)) {
    finalOriginalPrice = itemCount * (originalPrice + sumOfExtraItemOriginalPrices);
  } else {
    finalOriginalPrice = itemCount * (price + sumOfExtraItemOriginalPrices);
  }

  function getExtraItemPrice(item) {
    if (item.originalPrice !== undefined && item.originalPrice !== null && !Number.isNaN(item.originalPrice)) {
      return item.originalPrice;
    } else if (item.price !== undefined && item.price !== null && !Number.isNaN(item.price)) {
      return item.price;
    }
    return 0;
  }

  return (
    <li
      className="ls-order-item-list-item">
      <div className="ls-order-row">
        <div className="main-row">
          <div className="ls-order-item-qty">
            <span>{`${itemCount}x`}</span>
          </div>
          <div className="ls-order-item-details">
            <div className="ls-order-item-name">{name}</div>
            {extraItems.length > 0 && (
              <div className="ls-order-item-desc">{extraItemsStr.join()}</div>
            )}
          </div>
          <div className="ls-order-item-price">
            <div className="prices-wrapper">
              <div className="final-price">
                {currecyDescription} {(finalOriginalPrice).toFixed(currecyISOPrecision)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItemSummary;
