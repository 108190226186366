/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import sjcl from "sjcl";
import queryString from "query-string";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import payfortConfig from "../../config/payfort";
import { setCreditCardResponse } from "../../store/actions/paymentActions";
import AppHeader from "../../components/AppHeader";
import {
  applyPromoDiscount,
  removePromoDiscount,
} from "../../store/actions/cartActions";

import { PAYFORT_STATUS } from "../../utils/enums";
import { toast } from "../../components/Toast";
import Breadcrumb from "../../components/Breadcrumb";
import {
  ROOT,
  VERIFICATION,
  SELECT_ADDRESS,
  SEARCH,
  SELECT_PAYMENT,
  NEW_GUEST_INFO
} from "../../utils/routeConstants";
import { formStateManager } from "../../utils/formState";

const tokenRequest = {
  service_command: payfortConfig.serviceCommand,
  language: payfortConfig.language,
  return_url: `${window.origin}/payments`,
};

const payfortUrl = process.env.REACT_APP_PAYFORT_URL;

class Payment extends Component {
  state = {
    requestObj: {},
    card_number: "",
    card_security_code: "",
    expiry_date: "",
    card_holder_name: "",
    responseCode: false,
    focus: "",
    voucher: "",
    allValid: false,
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };
  handleVoucher = async () => {
    const { applyDiscount, removeDiscount } = this.props;
    const { discount } = this.props.cart;
    if (discount === 0) {
      applyDiscount(this.state.voucher);
    } else {
      removeDiscount();
      this.setState({ voucher: "" });
    }
  };
  componentDidMount() {
    const {
      location,
      setPayfortResponse,
      history,
    } = this.props;
    const queryParams = location.search;
    if (queryParams) {
      const params = queryString.parse(queryParams);
      if (params.status === PAYFORT_STATUS.SUCCESS) {
        setPayfortResponse(params, history);
      } else if (params.response_code === "00012") {
        toast.error("Card expired");
      } else if (params.response_code === "00003") {
        toast.error("Payment option is not available");
      } else if (params.response_code === "00016") {
        toast.error("Invalid card number");
      } else {
        if (params.response_code !== undefined) {
          toast.error("Invalid Credit Card Details");
        }
      }
    }

    formStateManager(
      this.refs.tokenForm,
      {
        validated: (state, allValid) => {
          if (this.state.allValid !== allValid) {
            this.setState({
              allValid: allValid,
            });
          }
        },
      },
      [
        {
          name: "card_number",
          displayQs: "#card_number_display",
          validation: [
            {
              rule: { type: "required" },
            },
            {
              rule: { type: "credit_card" },
            },
          ],
        },
        {
          name: "expiry_date",
          displayQs: "#expiry_date_display",
          validation: [
            {
              rule: {
                type: "required",
              },
            },
            {
              rule: {
                type: "cc_expire_date",
              },
            },
          ],
        },
        {
          name: "card_security_code",
          validation: [
            {
              rule: {
                type: "required",
              },
            },
            {
              rule: {
                type: "number",
              },
            },
            {
              rule: {
                type: "cc_cvv",
              },
            },
          ],
        },
        {
          name: "card_holder_name",
          validation: [
            {
              rule: {
                type: "required",
              },
            },
          ],
        },
      ]
    );
  }

  createTokenRequestObj = () => {
    const { merchantReference, accessCode, merchantId } = this.props;
    const newObj = {
      ...tokenRequest,
      merchant_reference: merchantReference,
      access_code: accessCode,
      merchant_identifier: merchantId,
    };
    const signature = this.createSignature(newObj);

    this.setState(
      {
        requestObj: { ...newObj, signature },
      },
      () => {
        this.refs.tokenForm.submit();
      }
    );
  };

  getToken = () => {
    this.refs.tokenForm.submit();
  };

  createSignature = (tknObj) => {
    const orderedObj = {};
    Object.keys(tknObj)
      .sort()
      .forEach((key) => {
        orderedObj[key] = tknObj[key];
      });

    let phraseStr = "";
    Object.keys(orderedObj).forEach((key) => {
      phraseStr += `${key}=${orderedObj[key]}`;
    });

    const testStr = `$2y$10$RTVsPK.ZB${phraseStr}$2y$10$RTVsPK.ZB`;

    const bitArray = sjcl.hash.sha256.hash(testStr);
    const hash = sjcl.codec.hex.fromBits(bitArray);

    return hash;
  };

  handleSubmit = () => {
    this.createTokenRequestObj();
  };

  handleExpireDate = (e) => {
    let val = e.target.value;
    const numVal = Number(val.length > 2 ? val.substr(3) : val.substr(0, 2));
    if (isNaN(numVal)) {
      e.target.value = val.substr(0, val.length - 1);
      return;
    }
    if (val.length === 1 && numVal > 1) {
      e.target.value = "";
    } else if (val.length === 2) {
      if (numVal > 12) {
        e.target.value = e.target.value.substr(0, 1);
      } else if (
        val.indexOf("/") < 0 &&
        this.state.expiry_date.substr(2) !== "/"
      ) {
        e.target.value += "/";
      }
    } else if (val.length === 4 && numVal < 2) {
      e.target.value = e.target.value.substr(0, 3);
    }
    this.setState({
      expiry_date: e.target.value,
    });
  };

  handleCreditCard = (e) => {
    var v = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];
    for (var i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      v = parts.join(" ");
    }
    e.target.value = v;
    this.setState({
      card_number: v,
    });
  };

  renderBreadCrumb = () => {
    const { applicationType } = this.props;
    let items = [];
    if (applicationType === "VIRTUAL_WAITER") {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: NEW_GUEST_INFO + SEARCH, name: "Gust Info" },
        { url: SELECT_PAYMENT + SEARCH, name: "Payment Method" },
        "Payments"
      ];
    } else {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: VERIFICATION + SEARCH, name: "Verification" },
        { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
        { url: SELECT_PAYMENT + SEARCH, name: "Payment Method" },
        "Payments"
      ];
    }
    return <Breadcrumb items={items} />;
  };

  render() {
    const { requestObj } = this.state;
    const { restaurant, history, isLoading, restaurantName } = this.props;
    const { cart, appParams } = this.props;
    const { currecyDescription, currecyISOPrecision } = appParams;

    const {
      totalPrice,
      subTotal,
      // tax,
      discount,
    } = cart;

    const { deliveryFee } = restaurant;

    return (
      <React.Fragment>
        {isLoading && <Loader />}

        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              backButtonClick={() => history.goBack()}
              heading={"Add Credit Card"}
              restaurantName={restaurantName}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>

        {this.renderBreadCrumb()}
        <div className="body ls-checkout-body ">
          <div className="ls-checkout-section">
            <div className="ls-flows-wrapper">
              <div className="ls-flows-container ls-flows-container-scrolled">
                <div className="clm--100">
                  <div className="ls-headerArea">
                    <p className="ls-title"> Enter card details </p>
                  </div>
                </div>
                <div className="clm--50">
                  <form
                    action={payfortUrl}
                    method="POST"
                    ref="tokenForm"
                  >
                    <div className="ls-field">
                      <input
                        required
                        type="text"
                        id="card_number_display"
                        inputMode="numeric"
                        placeholder={"Card Number"}
                        pattern="[0-9]*"
                        value={this.state.card_number}
                        onFocus={this.handleInputFocus}
                        onChange={this.handleCreditCard}
                        maxLength="20"
                      />
                      <label htmlFor="card_number">Card Number</label>
                      <input
                        type="hidden"
                        name="card_number"
                        value={this.state.card_number.replace(/ /g, "")}
                      />
                    </div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <div className="ls-expiry-date">
                        <div className="ls-field">
                          <input
                            required
                            id="expiry_date_display"
                            placeholder={"MM/YY"}
                            inputMode="numeric"
                            maxLength="5"
                            value={this.state.expiry_date}
                            onFocus={this.handleInputFocus}
                            onChange={this.handleExpireDate}
                          />

                          <label>Expiry date</label>
                        </div>
                      </div>

                      <div className="ls-cvv">
                        <div className="ls-field">
                          <input
                            required={true}
                            type="number"
                            pattern="[0-9]*"
                            placeholder={"CVV"}
                            name="card_security_code"
                            value={this.state.card_security_code}
                            onFocus={this.handleInputFocus}
                            onChange={(data) => {
                              if (data.target.value.length <= 4) {
                                this.setState({
                                  card_security_code: data.target.value,
                                });
                              }
                            }}
                          />
                          <label htmlFor="card_security_code">CVV</label>
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="expiry_date"
                        value={this.state.expiry_date
                          .split("/")
                          .reverse()
                          .join("")}
                      />
                    </div>

                    <div className="ls-field">
                      <input
                        required={true}
                        type="text"
                        name="card_holder_name"
                        placeholder={"Name on card"}
                        autoCapitalize="characters"
                        value={this.state.card_holder_name}
                        onFocus={this.handleInputFocus}
                        onChange={(data) => {
                          this.setState({
                            card_holder_name: data.target.value,
                          });
                        }}
                      />
                      <label htmlFor="card_holder_name">Name on card</label>
                    </div>

                    <input
                      type="hidden"
                      name="service_command"
                      value={requestObj.service_command}
                    />
                    <input
                      type="hidden"
                      name="access_code"
                      value={requestObj.access_code}
                    />
                    <input
                      type="hidden"
                      name="merchant_identifier"
                      value={requestObj.merchant_identifier}
                    />
                    <input
                      type="hidden"
                      name="merchant_reference"
                      value={requestObj.merchant_reference}
                    />
                    <input
                      type="hidden"
                      name="language"
                      value={requestObj.language}
                    />
                    <input
                      type="hidden"
                      name="signature"
                      value={requestObj.signature}
                    />
                    <input
                      type="hidden"
                      name="return_url"
                      value={`${window.origin}/payments`}
                    />
                    <div className="ls-payments-totals-wrapper">
                      <hr />
                      <ul className="ls-basket-item-list ls-basket-item-small with-padding">
                        <li className="ls-basket-item-list-item-price basket-item-list-item-total">
                          <div className="ls-basket-item-details">
                            <div className="ls-basket-item-name">Subtotal</div>
                          </div>
                          <div className="ls-basket-item-price">
                            {currecyDescription} {subTotal.toFixed(currecyISOPrecision)}
                          </div>
                        </li>
                        {discount > 0 && (
                          <li className="ls-basket-item-list-item-price basket-item-list-item-total ls-basket-item-highlight">
                            <div className="ls-basket-item-details">
                              <div className="ls-basket-item-name">
                                Discount
                              </div>
                            </div>
                            <div className="ls-basket-item-price">
                              - {currecyDescription} {discount.toFixed(currecyISOPrecision)}
                            </div>
                          </li>
                        )}
                        {deliveryFee > 0 && (
                          <li className="ls-basket-item-list-item-price basket-item-list-item-total">
                            <div className="ls-basket-item-details">
                              <div className="ls-basket-item-name">
                                Delivery fee{" "}
                              </div>
                            </div>
                            <div className="ls-basket-item-price">
                              {currecyDescription} {deliveryFee.toFixed(currecyISOPrecision)}
                            </div>
                          </li>
                        )}
                      </ul>

                      <ul className="ls-basket-item-list with-padding border-top-1px">
                        <li className="ls-basket-item-list-item-price basket-item-list-item-total">
                          <div className="ls-basket-item-details">
                            <div className="ls-basket-item-name">Total</div>
                          </div>
                          <div className="ls-basket-item-price price-xl">
                            {currecyDescription} {totalPrice.toFixed(currecyISOPrecision)}
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="ls-mobile-bottom-button-container">
                      <button
                        type="button"
                        className={
                          (this.state.allValid
                            ? "ls-cart-button"
                            : "ls-cart-button-disabled") + " center-text"
                        }
                        onClick={() =>
                          this.state.allValid && this.handleSubmit()
                        }
                      >
                        Pay Now!
                      </button>
                    </div>
                    <div className="ls-text-center-mobile">
                      <img
                        src={require("../../assets/img/payfort.jpg")}
                        alt="Payfort"
                      />
                    </div>

                  </form>
                </div>
                <div className="clm--50">
                  <img
                    src={require("../../assets/img/letter.svg")}
                    className="img-right-side"
                    alt="Letter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-fixed">
          <button
            type="button"
            className="ls-cart-button center-text"
            onClick={() => this.handleSubmit()}
          >
            Proceed
          </button>
        </div>
        {/* <AppFooter /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    isLoggedIn: state.auth.loggedIn,
    appParams: state.app.appParams,
    restaurant: state.menu.restaurant,
    isLoading: state.app.loading,
    merchantReference: state.paymentDetails.paymentGateWayConfigurations.merchantReference,
    accessCode: state.paymentDetails.paymentGateWayConfigurations.accessKey,
    merchantId: state.paymentDetails.paymentGateWayConfigurations.merchantId,
    applicationType: state.app.applicationType,
    restaurantName: state.app.appParams.restaurantName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPayfortResponse: (response, history) =>
      dispatch(setCreditCardResponse(response, history)),
    applyDiscount: (discount) => dispatch(applyPromoDiscount(discount)),
    removeDiscount: () => dispatch(removePromoDiscount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
