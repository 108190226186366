import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import CurrentLocation from "../../components/CurrentLocation";
import { ROOT, SEARCH } from "../../utils/routeConstants";
import marker from "../../assets/img/locationPin.png";
import Loader from "../../components/Loader";
import { toast } from "../../components/Toast";
import {
  getNearestLocation
} from "../../store/actions/appActions";
import { FETCH_CURRENT_LOCATION_COORDINATES } from "../../utils/apiConstants";

const locationApiEndPoint = process.env.REACT_APP_LOCATION_API_URL;

class DeliveryLocationPicker extends Component {
  state = {
    displayTime: "hidden",
    initialCenter: {},
    options: {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }
  };

  getCurrentLocationCordinatesForAPI() {
    axios.get(locationApiEndPoint + FETCH_CURRENT_LOCATION_COORDINATES, {
      headers: {
        //"X-FORWARDED-FOR": "86.99.166.162" // only use developer testing
      }
    })
      .then(res => {
        if (res.status === 200) {
          let coordinates = res.data.coordinates;
          this.setState({
            initialCenter: {
              lat: coordinates.latitude,
              lng: coordinates.longitude
            }
          })
        }
      }).catch((error) => {
        toast.error("Current location not found", { autoClose: false });
      });
  }

  componentDidMount() {

    if (this.props.screenSize === "lg") {
      var that = this;
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              console.log("geolocation picker granted");
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(function success(pos) {
                var crd = pos.coords;
                that.setState({
                  initialCenter: {
                    lat: crd.latitude,
                    lng: crd.longitude
                  }
                })
              });
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(function success(pos) {
                var crd = pos.coords;
                that.setState({
                  initialCenter: {
                    lat: crd.latitude,
                    lng: crd.longitude
                  }
                })
              },
                function errors(err) {
                  // console.warn(`ERROR(${err.code}): ${err.message}`);
                  that.getCurrentLocationCordinatesForAPI();
                },
                that.state.options);
            } else if (result.state === "denied") {
              that.getCurrentLocationCordinatesForAPI();
            }
            result.onchange = function () {
              console.log(result.state);
            };
          });
      } else {
        alert("Sorry Not available!");
      }
    } else {
      this.getCurrentLocationCordinatesForAPI();
    }

    window.scroll(0, 0);
  }

  handleBackButton = () => {
    const { history } = this.props;
    history.push(ROOT + SEARCH);
  };

  setDeliveryPosition = (cod) => {
    this.setState({
      selectLocation: cod
    })
  }

  handleSubmit = () => {
    const { getNearestLocation, history } = this.props;
    if (this.state.selectLocation !== undefined) {
      getNearestLocation(this.state.selectLocation, history);
    } else {
      toast.error("Please drag and select delivery location!", { autoClose: false });
      // getNearestLocation(this.state.initialCenter, history);
    }
  }

  render() {
    const {
      isLoading,
      menuCardImage,
      restaurantName
    } = this.props;

    return (
      <div className="App ls-location-select">
        {isLoading && <Loader />}
        <div className="ls-banner-delivery-picker">
          {menuCardImage !== undefined ? (
            <>
              <div id="ls-banner-home" className="ls-banner-image stretch-vw">
                <img src={menuCardImage} alt="" />
              </div>
              {restaurantName && (<div className="restaurant-name-vw">{restaurantName}</div>)}
            </>
          ) : <Loader />}
        </div>
        <div className="ls-flows-wrapper">
          <div className="ls-flows-container">
            <div>
              <div className="ls-headerArea">
                <p className="ls-title"> Delivery Address </p>
              </div>
            </div>
            <div className="ls-map-container">
              <img src={marker} alt="map marker" className="full-map-marker" />
              {this.state.initialCenter.lat !== undefined ? (
                <CurrentLocation
                  setLocation={this.setDeliveryPosition}
                  initialCenter={this.state.initialCenter}
                />
              ) : null}
            </div>
            <div className="ls-submit-container" style={{ padding: 0 }}>
              <button
                type="button"
                className="ls-cart-button"
                onClick={() => this.handleSubmit()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div className="footer-fixed show">
          <button
            type="button"
            className="ls-cart-button center-text"
            onClick={() => this.handleSubmit()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.loading,
    menuCardImage: state.app.appParams.logo,
    restaurantName: state.app.appParams.restaurantName,
    operatingHours: state.cart.operatingHours,
    restaurant: state.menu.restaurant,
    screenSize: state.ui.screenSize
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNearestLocation: (locationCoordinates, history, applicationType) => dispatch(getNearestLocation(locationCoordinates, history, applicationType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryLocationPicker);
