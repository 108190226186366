// Menu actions
export const FETCH_RESTAURANT_SUCCESS = "FETCH_RESTAURANT_DATA";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const FETCH_MENU_ITEM = "FETCH_MENU_ITEM";
export const FETCH_FROM_CART_ITEM_ID = "FETCH_FROM_CART_ITEM_ID";
export const SET_MENU_CARD_ID = "SET_MENU_CARD_ID";
export const SET_KITCHEN_ID = "SET_KITCHEN_ID";
export const SET_MENU_CARD_IMAGE = "SET_MENU_CARD_IMAGE";
export const CLEAR_MENU_ON_INITIAL_SCREEN = "CLEAR_MENU_ON_INITIAL_SCREEN";
export const SET_OPERATING_HOURS = "SET_OPERATING_HOURS";
export const FETCH_MERCHANT_REFERENCE = "FETCH_MERCHANT_REFERENCE";
export const FETCH_ACCESS_CODE = "FETCH_ACCESS_CODE";
export const FETCH_MERCHANT_ID = "FETCH_MERCHANT_ID";
export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_SELECTED_DISTRICTS = "FETCH_SELECTED_DISTRICTS";
export const FETCH_CURRENT_DISTRICT_CODE = "FETCH_CURRENT_DISTRICT_CODE";
export const FETCH_CURRENT_IP_ADDRESS = "FETCH_CURRENT_IP_ADDRESS";

// Cart actions
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const FETCH_CART_RESPONSE_SUCCESS = "FETCH_CART_RESPONSE_SUCCESS";
export const FETCH_IF_EXISTING_ITEM = "FETCH_IF_EXISTING_ITEM";
export const UPDATE_EXISTING_CART_ITEM = "UPDATE_EXISTING_CART_ITEM";
export const FETCH_ORDER_RESPONSE_SUCCESS = "FETCH_ORDER_RESPONSE_SUCCESS";
export const SET_DELIVERY_LOCATION = "SET_DELIVERY_LOCATION";
export const FETCH_ORDER_RESPONSE_FAILED = "FETCH_ORDER_RESPONSE_FAILED";
export const SET_CART_EMPTY = "SET_CART_EMPTY";
export const APPLY_PROMO_DISCOUNT = "APPLY_PROMO_DISCOUNT";
export const REMOVE_PROMO_DISCOUNT = "REMOVE_PROMO_DISCOUNT";
export const ADD_CUSTOM_NOTE_TO_CART = "ADD_CUSTOM_NOTE_TO_CART";

// Auth actions
export const LOGIN_USER_SUCCESSFUL = "LOGIN_USER_SUCCESSFUL";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const SIGNUP_USER_SUCCESSFUL = "SIGNUP_USER_SUCCESSFUL";
export const SIGNUP_USER_FAILED = "SIGNUP_USER_FAILED";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_ADDRESS";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_PREVIOUS_LOCATIONS = "SET_PREVIOUS_LOCATIONS";
export const SET_MOBILE_NUMBER = "SET_MOBILE_NUMBER";
export const FETCH_INITIAL_PARAMS_SUCCESSFUL =
  "FETCH_INITIAL_PARAMS_SUCCESSFUL";
export const FETCH_INITIAL_PARAMS_FAILED = "FETCH_INITIAL_PARAMS_FAILED";
export const SET_SELECTED_CITY = "SET_SELECTED_CITY";
export const SET_MOBILE_NUMBER_REMEMBER = "SET_MOBILE_NUMBER_REMEMBER";

// Payment actions
export const CREDIT_CARD_TOKENIZATION_SUCCESS =
  "CREDIT_CARD_TOKENIZATION_SUCCESS";
export const CREDIT_CARD_TOKENIZATION_FAILED =
  "CREDIT_CARD_TOKENIZATION_FAILED";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_PAYMENT_STATUS = "SET_PAYMENT_STATUS";
export const SET_PAYMENT_GATEWAY_CONFIGURATION = "SET_PAYMENT_GATEWAY_CONFIGURATION";

// Responsive UI
export const SCREEN_SIZE = "SCREEN_SIZE"; // lg | sm

//vouchery end point
export const SET_VOUCHERY_END_POINT = "SET_VOUCHERY_END_POINT";
export const SET_SITEWIDE_PROMO_DETAILS = "SET_SITEWIDE_PROMO_DETAILS";
export const SET_SITEWIDE_PROMO_RESPONSE = "SET_SITEWIDE_PROMO_RESPONSE";
export const REMOVE_SITEWIDE_PROMO_RESPONSE = "REMOVE_SITEWIDE_PROMO_RESPONSE"

// App action
export const SET_LOADER_ON = "SET_LOADER_ON";
export const SET_LOADER_OFF = "SET_LOADER_OFF";
export const SET_MODAL_ON = "SET_MODAL_ON";
export const SET_MODAL_OFF = "SET_MODAL_OFF";
export const SELECTED_LOCATION_INFO = "SELECTED_LOCATION_INFO";
export const SELECTED_LOCATION_COORDINATES = "SELECTED_LOCATION_COORDINATES";

//virtual waiter configuration
export const SET_APPLICATION_TYPE = "SET_APPLICATION_TYPE";
export const VIRTUAL_WAITER_CONFIGURATIONS = "VIRTUAL_WAITER_CONFIGURATIONS";
