import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import CurrentLocation from "../../components/CurrentLocation";
import { setDeliveryLocation } from "../../store/actions/cartActions";
import { saveCustomerDetails } from "../../store/actions/authActions";
import { updateSelectedLocation } from "../../store/actions/menuActions";
import { CHECKOUT, ROOT, SEARCH, SELECT_PAYMENT, VERIFICATION, SELECT_ADDRESS, NEW_ADDRESS } from "../../utils/routeConstants";
import { FETCH_NEAREST_LOCATION, FETCH_CURRENT_LOCATION_COORDINATES } from "../../utils/apiConstants";
import marker from "../../assets/img/locationPin.png";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb";
import AppHeader from "../../components/AppHeader";
import { toast } from "../../components/Toast";
import {
  setPaymentStatus,
  setPaymentMethod,
  getPaymentGateWayConfiguration
} from "../../store/actions/paymentActions";
import { PAYMENT, PAYMENT_STATUS } from "../../utils/enums";

const locationApiEndPoint = process.env.REACT_APP_LOCATION_API_URL;

class LocationSelector extends Component {

  state = {
    initialCenter: {}
  };

  componentDidMount() {
    const { setDeliveryPosition, selectedLocationInfo, selectedLocationCoordinates } = this.props;
    window.scroll(0, 0);

    if (selectedLocationInfo) {

      if (selectedLocationCoordinates.lat && selectedLocationCoordinates.lng) {
        this.setState({
          initialCenter: {
            lat: selectedLocationCoordinates.lat,
            lng: selectedLocationCoordinates.lng
          }
        }, () => {
          setDeliveryPosition({});
        })
      }
    } else {
      axios.get(locationApiEndPoint + FETCH_CURRENT_LOCATION_COORDINATES, {
        headers: {
          //"X-FORWARDED-FOR": "86.99.166.162" // only use developer testing
        }
      })
        .then(res => {
          if (res.status === 200) {
            let coordinates = res.data.coordinates;
            this.setState({
              initialCenter: {
                lat: coordinates.latitude,
                lng: coordinates.longitude
              }
            }, () => {
              setDeliveryPosition({});
            })
          }
        }).catch((error) => {
          toast.error("Current location not found", { autoClose: false });
        });
    }
  }

  handleBackButton = () => {
    const { history } = this.props;
    history.push(CHECKOUT + SEARCH);
  };

  renderBreadCrumb = () => {
    const items = [
      { url: ROOT + SEARCH, name: "Home Page" },
      { url: VERIFICATION + SEARCH, name: "Verification" },
      { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
      { url: NEW_ADDRESS + SEARCH, name: "New Address" },
      "Location Select"
    ];
    return <Breadcrumb items={items} />;
  };

  handleSubmit = () => {
    const {
      cart,
      saveCustomerDetails,
      history,
      setStatus,
      setPayment,
      paymentDetails,
      restaurantId,
      restaurant,
      getPaymentGateWayConfiguration,
      updateSelectedLocation
    } = this.props;

    let locationCoordinates = {};
    if (cart.deliveryLocation.lat && cart.deliveryLocation.lng) {
      locationCoordinates = { lat: cart.deliveryLocation.lat, lng: cart.deliveryLocation.lng };

      axios.get(locationApiEndPoint + FETCH_NEAREST_LOCATION, {
        params: {
          "brandId": restaurantId,
          "latitude": locationCoordinates.lat,
          "longitude": locationCoordinates.lng
        }
      })
        .then(res => {
          if (res.status === 200) {
            let locationData = res.data;
            if (locationData.locationId !== undefined) {
              if (restaurant.kitchenList.length > 1) {
                const uniqueLocationsByMenuId = [...new Set(restaurant.kitchenList.map(item => item.menuId))];
                if (uniqueLocationsByMenuId.length > 1) {
                  const isExistLocation = restaurant.locationId === locationData.locationId;

                  if (isExistLocation) {
                    saveCustomerDetails();
                    if (cart.totalPrice === 0) {
                      setStatus(PAYMENT_STATUS.FOC);
                      setPayment(PAYMENT.FOC);
                      history.push(CHECKOUT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length > 1) {
                        history.push(SELECT_PAYMENT + SEARCH);
                      } else {
                        if (paymentDetails.paymentMethods.length === 0) {
                          toast.error("Payment methods not cofigured!", { autoClose: true })
                        } else if (paymentDetails.paymentMethods.length === 1) {
                          if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                            setStatus(PAYMENT_STATUS.PREPAID);
                            setPayment(PAYMENT.PREPAID);
                            getPaymentGateWayConfiguration(history);
                          } else {
                            history.push(SELECT_PAYMENT + SEARCH);
                          }
                        }
                      }
                    }
                  } else {
                    toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                  }
                } else {
                  const isExistLocation = restaurant.kitchenList.find((data) => data.locationId === locationData.locationId);
                  if (isExistLocation !== undefined) {
                    updateSelectedLocation(locationData.locationId);
                    saveCustomerDetails();
                    if (cart.totalPrice === 0) {
                      setStatus(PAYMENT_STATUS.FOC);
                      setPayment(PAYMENT.FOC);
                      history.push(CHECKOUT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length > 1) {
                        history.push(SELECT_PAYMENT + SEARCH);
                      } else {
                        if (paymentDetails.paymentMethods.length === 0) {
                          toast.error("Payment methods not cofigured!", { autoClose: true })
                        } else if (paymentDetails.paymentMethods.length === 1) {
                          if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                            setStatus(PAYMENT_STATUS.PREPAID);
                            setPayment(PAYMENT.PREPAID);
                            getPaymentGateWayConfiguration(history);
                          } else {
                            history.push(SELECT_PAYMENT + SEARCH);
                          }
                        }
                      }
                    }
                  } else {
                    toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                  }
                }
              } else {
                const isExistLocation = restaurant.locationId === locationData.locationId;

                if (isExistLocation) {
                  saveCustomerDetails();
                  if (cart.totalPrice === 0) {
                    setStatus(PAYMENT_STATUS.FOC);
                    setPayment(PAYMENT.FOC);
                    history.push(CHECKOUT + SEARCH);
                  } else {
                    if (paymentDetails.paymentMethods.length > 1) {
                      history.push(SELECT_PAYMENT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length === 0) {
                        toast.error("Payment methods not cofigured!", { autoClose: true })
                      } else if (paymentDetails.paymentMethods.length === 1) {
                        if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                          setStatus(PAYMENT_STATUS.PREPAID);
                          setPayment(PAYMENT.PREPAID);
                          getPaymentGateWayConfiguration(history);
                        } else {
                          history.push(SELECT_PAYMENT + SEARCH);
                        }
                      }
                    }
                  }
                } else {
                  toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                }
              }
            } else {
              toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
            }
          }
        }).catch((error) => {
          toast.error("Something went wrong!!!", { autoClose: true });
        });
    } else {
      toast.error("Please drag and select delivery location!", { autoClose: false });
      //locationCoordinates = { lat: this.state.initialCenter.lat, lng: this.state.initialCenter.lng }
    }
  };

  render() {
    const {
      setDeliveryPosition,
      isLoading,
      history,
      cart
    } = this.props;
    return (
      <div className="App ls-location-select">
        {isLoading && <Loader />}
        {/* <div className="loading-overlay"></div> */}
        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              cart={cart}
              backButtonClick={() => history.goBack()}
              heading={"Delivery Address"}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>
        {this.renderBreadCrumb()}
        <div className="ls-flows-wrapper">
          <div className="ls-flows-container">
            <div>
              <div className="ls-headerArea">
                <p className="ls-title"> Delivery Address </p>
              </div>
            </div>
            <div className="ls-map-container">
              <img src={marker} alt="map marker" className="full-map-marker" />
              {this.state.initialCenter.lat !== undefined ? (
                <CurrentLocation
                  setLocation={setDeliveryPosition}
                  initialCenter={this.state.initialCenter}
                />
              ) : null}
            </div>
            <div className="ls-submit-container" style={{ padding: 0 }}>
              <button
                type="button"
                className="ls-cart-button"
                onClick={() => this.handleSubmit()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div className="footer-fixed show">
          <button
            type="button"
            className="ls-cart-button center-text"
            onClick={() => this.handleSubmit()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    isLoading: state.app.loading,
    paymentDetails: state.paymentDetails,
    cart: state.cart,
    restaurantId: state.app.appParams.restaurantId,
    selectedLocationInfo: state.app.selectedLocationInfo,
    selectedLocationCoordinates: state.app.selectedLocationCoordinates
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryPosition: (coords) => dispatch(setDeliveryLocation(coords)),
    saveCustomerDetails: (history, location) =>
      dispatch(saveCustomerDetails(history, location)),
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    setStatus: (status) => dispatch(setPaymentStatus(status)),
    getPaymentGateWayConfiguration: (history) =>
      dispatch(getPaymentGateWayConfiguration(history)),
    updateSelectedLocation: (locationId) => dispatch(updateSelectedLocation(locationId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelector);
