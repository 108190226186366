/* eslint-disable */
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import Autocomplete from 'react-google-autocomplete';
//import CurrentLocation from "./currentLocation";

const mapStyles = {
	height: 'calc(100% - 160px)'
};

export class MapContainer extends Component {
	state = {
		showingInfoWindow: false, //Hides or the shows the infoWindow
		activeMarker: {}, //Shows the active marker upon click
		selectedPlace: {},
		centerLat: null,
		centerLong: null
	};
	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});

	onClose = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			});
		}
	};

	centerMoved = (mapProps, map) => {
		const { setLocation } = this.props;

		this.setState({
			centerLat: map.center.lat(),
			centerLong: map.center.lng()
		});

		const lat = map.center.lat();
		const lng = map.center.lng();
		setLocation({ lat, lng });
	};

	onPlaceSelected = (place) => {
		const { setLocation } = this.props;

		const lat = place.geometry.location.lat();
		const lng = place.geometry.location.lng();

		this.setState({
			centerLat: lat,
			centerLong: lng
		});

		setLocation({ lat, lng });
	};

	componentDidMount() {
		const { initialCenter } = this.props;
		this.setState({
			centerLat: initialCenter.lat,
			centerLong: initialCenter.lng
		});
	}

	onMarkerDragEnd = (coord, index) => {
		const { setLocation } = this.props;
		const { latLng } = coord;
		const lat = latLng.lat();
		const lng = latLng.lng();
		setLocation({ lat, lng });
	};

	onMapLoaded = (mapProps, map) => {
		map.setOptions({
			styles: NightModeStyles
		 })
	}

	render() {
		const { setLocation, initialCenter } = this.props;
		return (
			<React.Fragment>
				<div className="flex-container">
					<div className="ls-search-location">
						<img src={require('../../assets/img/search.svg')} className="search" alt="search" />

						<Autocomplete
							onPlaceSelected={(place) => {
								this.onPlaceSelected(place);
							}}
							types={[ 'geocode', 'establishment' ]}
							componentRestrictions={{ country: 'ae' }}
							placeholder="Search your location"
						/>
					</div>
				</div>
				<div className="ls-map">
					<Map
						// centerAroundCurrentLocation
						className={'clm--maps'}
						mapTypeControl={false}
						google={this.props.google}
						setLocation={setLocation}
						zoom={16}
						initialCenter={{
							lat: initialCenter.lat,
							lng: initialCenter.lng
						}}
						center={{ lat: this.state.centerLat, lng: this.state.centerLong }}
						onDragend={this.centerMoved}
						onZoom_changed={this.centerMoved}
						gestureHandling="greedy"
						streetViewControl={false}
						zoomControl={false}
						fullscreenControl={false}
						onReady={(mapProps, map) => this.onMapLoaded(mapProps, map)}
					>
						{/*<Marker
          onClick={this.onMarkerClick}
          name={"current location"}
          position={{ lat: this.state.centerLat, lng: this.state.centerLong }}
        />*/}
						<InfoWindow
							marker={this.state.activeMarker}
							visible={this.state.showingInfoWindow}
							onClose={this.onClose}
						>
							<div>
								<h4>{this.state.selectedPlace.name}</h4>
							</div>
						</InfoWindow>
					</Map>
				</div>
			</React.Fragment>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyAp7IlgmKEcSIk9wDEnVDjTDnz-3dg1IR8'
})(MapContainer);

const NightModeStyles = [
	{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
	{ elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
	{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
	{
	  featureType: "administrative.locality",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }]
	},
	{
	  featureType: "poi",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }]
	},
	{
	  featureType: "poi.park",
	  elementType: "geometry",
	  stylers: [{ color: "#263c3f" }]
	},
	{
	  featureType: "poi.park",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#6b9a76" }]
	},
	{
	  featureType: "road",
	  elementType: "geometry",
	  stylers: [{ color: "#38414e" }]
	},
	{
	  featureType: "road",
	  elementType: "geometry.stroke",
	  stylers: [{ color: "#212a37" }]
	},
	{
	  featureType: "road",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#9ca5b3" }]
	},
	{
	  featureType: "road.highway",
	  elementType: "geometry",
	  stylers: [{ color: "#746855" }]
	},
	{
	  featureType: "road.highway",
	  elementType: "geometry.stroke",
	  stylers: [{ color: "#1f2835" }]
	},
	{
	  featureType: "road.highway",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#f3d19c" }]
	},
	{
	  featureType: "transit",
	  elementType: "geometry",
	  stylers: [{ color: "#2f3948" }]
	},
	{
	  featureType: "transit.station",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }]
	},
	{
	  featureType: "water",
	  elementType: "geometry",
	  stylers: [{ color: "#17263c" }]
	},
	{
	  featureType: "water",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#515c6d" }]
	},
	{
	  featureType: "water",
	  elementType: "labels.text.stroke",
	  stylers: [{ color: "#17263c" }]
	}
  ]