import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "../../components/Toast";

import DeliveryInfo from "./DeliveryInfo";
import PaymentInfo from "./PaymentInfo";
import PaymentsModal from "./PaymentsModal";
import AppHeader from "../../components/AppHeader";

import {
  PAYMENT,
  SELECT_ADDRESS,
  BASKET,
  ROOT,
  VERIFICATION,
  SEARCH,
  SELECT_PAYMENT,
} from "../../utils/routeConstants";
import { postOrder } from "../../store/actions/cartActions";
import { setPaymentMethod } from "../../store/actions/paymentActions";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb";
class Checkout extends Component {
  state = { isPaymentModalOpen: false, isLoadingState: false };

  componentDidMount() {
    const { applicationType, history, publishOrder } = this.props;
    if (applicationType === "VIRTUAL_WAITER") {
      this.setState({
        isLoadingState: true
      }, () => {
        publishOrder(history);
      });
    }
  }

  handleChangeLocation = () => {
    const { history } = this.props;
    history.push(SELECT_ADDRESS + SEARCH, { refFrom: "checkout" });
  };

  generateMapLocation = () => {
    const { restaurant, deliveryLocation } = this.props;
    // console.log("🚀 ~ file: index.js ~ line 44 ~ Checkout ~ deliveryLocation", deliveryLocation)
    // console.log("🚀 ~ file: index.js ~ line 46 ~ Checkout ~ location", location)
    const { location } = restaurant;

    const height = this.props.ui.screenSize === "lg" ? 300 : 400;

    // Ref: https://developers.google.com/maps/documentation/maps-static/styling
    // Ref: https://developers.google.com/maps/documentation/javascript/examples/style-array
    const mapUrl =
      `https://maps.googleapis.com/maps/api/staticmap?size=600x${height}&maptype=roadmap` +
      `&markers=color:red%7Clabel:C%7C${deliveryLocation.lat},${deliveryLocation.lng}` +
      `&markers=color:blue%7Clabel:S%7C${location.lat},${location.lng}` +
      `&key=AIzaSyAp7IlgmKEcSIk9wDEnVDjTDnz-3dg1IR8` +
      `&style=element:geometry%7Cvisibility:simplified%7Ccolor:0x242f3e` +
      `&style=element:labels.text.stroke%7Cvisibility:simplified%7Ccolor:0x242f3e` +
      `&style=element:labels.text.fill%7Cvisibility:simplified%7Ccolor:0x746855` +
      `&style=feature:road%7Celement:geometry%7Cvisibility:simplified%7Ccolor:0x38414e` +
      `&style=feature:road%7Celement:geometry.stroke%7Cvisibility:simplified%7Ccolor:0x212a37` +
      `&style=feature:road%7Celement:labels.text.fill%7Cvisibility:simplified%7Ccolor:0x9ca5b3` +
      `&style=feature:road.highway%7Celement:geometry%7Cvisibility:simplified%7Ccolor:0x746855` +
      `&style=feature:road.highway%7Celement:geometry.stroke%7Cvisibility:simplified%7Ccolor:0x1f2835` +
      `&style=feature:road.highway%7Celement:labels.text.fill%7Cvisibility:simplified%7Ccolor:0xf3d19c` +
      `&style=feature:water%7Celement:geometry%7Cvisibility:simplified%7Ccolor:0x17263c`;
    return mapUrl;
  };

  onPaymentInfoClick = () => {
    const { history, selectedPayment } = this.props;
    if (selectedPayment !== "FOC") {
      history.push(SELECT_PAYMENT + SEARCH);
    }
    // this.setState({ isPaymentModalOpen: true });
  };

  onPaymentOptionChnage = (event) => {
    const { setPayment } = this.props;

    setPayment(event.target.value);
    this.setState({
      isPaymentModalOpen: false,
    });
  };

  backToBasket = () => {
    const { history } = this.props;
    history.push(BASKET + SEARCH);
  };

  changeAddress = () => {
    const { history } = this.props;
    history.push(SELECT_ADDRESS + SEARCH);
  };

  proceedToCCPayment = () => {
    const { history } = this.props;
    history.push(PAYMENT + localStorage.getItem("searchUrl"));
  };

  closePaymentModal = () => {
    this.setState({ isPaymentModalOpen: false });
  };

  onPlaceOrder = () => {
    const { selectedPayment, history } = this.props;
    if (!selectedPayment) {
      toast.warn("You must select a payment method !");
    } else {
      const { publishOrder } = this.props;
      publishOrder(history);
      this.refs.mobile_btn.setAttribute("disabled", "disabled");
      this.refs.cart_btn.setAttribute("disabled", "disabled");
    }
  };

  renderBreadCrumb = () => {
    const { selectedPayment } = this.props;
    let items = [];
    if (selectedPayment === "FOC") {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: VERIFICATION + SEARCH, name: "Verification" },
        { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
        "Checkout",
      ];
    } else {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: VERIFICATION + SEARCH, name: "Verification" },
        { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
        { url: SELECT_PAYMENT + SEARCH, name: "Payment Method" },
        "Checkout",
      ];
    }

    return <Breadcrumb items={items} />;
  };

  render() {
    const { cart } = this.props;
    const { totalPrice } = cart;

    const {
      appParams,
      selectedPayment,
      cardDetails,
      selectedAddress,
      applicationType,
      restaurant
    } = this.props;

    const { currecyDescription, currecyISOPrecision } = appParams;
    const { isPaymentModalOpen, isLoadingState } = this.state;

    return (
      <>
        {applicationType !== "VIRTUAL_WAITER" ? (
          <React.Fragment>
            {!isLoadingState ? (
              <div className="App">

                <div className="ls-app-header-wrapper">
                  <div className="container">
                    <AppHeader
                      heading={"Place Order"}
                      backButtonClick={() => {
                        this.props.history.push(SELECT_PAYMENT + SEARCH);
                      }}
                      cartEnabled={false}
                      isShowHours={true}
                    />
                  </div>
                </div>
                {this.renderBreadCrumb()}
                <div className="ls-flows-wrapper map-and-payment">
                  <div className="ls-flows-container">
                    <div >
                      <div className="basket-main-details">
                        <div className='estimated-delivery'>Estimated delivery</div>
                        <div
                          className="basket-main-details-item del"
                          style={{ flexBasis: "70%" }}
                        >
                          {restaurant.deliveryTimeDisplayText}
                        </div>
                      </div>
                      <div className="map-area">
                        {" "}
                        <img
                          style={{ width: "100%" }}
                          src={this.generateMapLocation()}
                          alt="Location"
                        />
                      </div>
                      <div className="ls-mobile-bottom-button-container">
                        <button
                          ref="mobile_btn"
                          type="button"
                          className="ls-cart-button center-text"
                          onClick={() => this.onPlaceOrder()}
                        >
                          Place Order
                        </button>
                      </div>

                      <DeliveryInfo
                        changeAddress={this.handleChangeLocation}
                        deliveryLocation={selectedAddress}
                      />
                      <PaymentInfo
                        onPaymentClick={this.onPaymentInfoClick}
                        selectedPayment={selectedPayment}
                        cardDetails={cardDetails}
                      />
                    </div>
                    <div className="footer-fixed">
                      <div className="basket-final-total">
                        <span>Total</span>
                        <span>
                          {currecyDescription} {totalPrice.toFixed(currecyISOPrecision)}
                        </span>
                      </div>
                      <button
                        ref="cart_btn"
                        type="button"
                        className="ls-cart-button center-text"
                        onClick={() => this.onPlaceOrder()}
                      >
                        Place Order
                      </button>
                    </div>
                    {isPaymentModalOpen && (
                      <PaymentsModal
                        selectedPayment={selectedPayment}
                        onChange={this.onPaymentOptionChnage}
                        ccPayment={this.proceedToCCPayment}
                        onClose={this.closePaymentModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : <Loader />}
          </React.Fragment>
        ) : <Loader />}
      </>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    applicationType: state.app.applicationType,
    cart: state.cart,
    restaurant: state.menu.restaurant,
    deliveryLocation: state.cart.deliveryLocation,
    selectedPayment: state.paymentDetails.paymentType,
    cardDetails: state.payment.cardDetails,
    selectedAddress: state.app.applicationType === "VIRTUAL_WAITER" ? "" : state.auth.user.label,
    appParams: state.app.appParams,
    isLoading: state.app.loading,
    isShowModal: state.app.isModalShow,
    ui: state.ui
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    publishOrder: (history) => dispatch(postOrder(history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
