import { SCREEN_SIZE } from '../actionTypes';

const responsiveReducer = (state = { screenSize: window.innerWidth <= 768 ? 'sm' : 'lg' }, action) => {
	switch (action.type) {
		case SCREEN_SIZE:
			return Object.assign({}, state, {
				screenSize: action.size
			});
		default:
			return state;
	}
};

export default responsiveReducer;
