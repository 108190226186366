/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Svg } from "../../components/Svg";
import { cartItemPrice } from "../../store/actions/cartActions"


const CartItem = ({
  itemCount,
  name,
  extraItems,
  price,
  originalPrice,
  extraItemPrice,
  onItemClick,
  item,
  currecyDescription,
  currecyISOPrecision,
  onRemoveClick,
  cartItem
}) => {
  // const itemPrice = itemCount * price;
  const extraItemsStr = extraItems.map((i) => i.displayName);
  const sumOfExtraItemOriginalPrices = extraItems.reduce((totalOriginalPrice, item) => totalOriginalPrice + getExtraItemPrice(item) * item.quantity, 0);
  const finalOriginalPrice = itemCount * (originalPrice + sumOfExtraItemOriginalPrices);


  let finalPrice = 0;

  if (cartItem.discounted !== undefined) {
    finalPrice = cartItemPrice(cartItem, cartItem.discounted)
  } else {
    const sumOfExtraItemPrices = extraItems.reduce((totalPrice, item) => totalPrice + item.price * item.quantity, 0);
    finalPrice = itemCount * (price + sumOfExtraItemPrices);
  }

  function getExtraItemPrice(item) {
    if (item.originalPrice !== undefined && item.originalPrice !== null && !Number.isNaN(item.originalPrice)) {
      return item.originalPrice;
    } else if (item.price !== undefined && item.price !== null && !Number.isNaN(item.price)) {
      return item.price;
    }
    return 0;
  }


  return (
    <li
      className="ls-basket-item-list-item"
      onClick={(e) => {
        if (
          e.target.classList.contains("icon-basket-trash") === false &&
          e.target.tagName !== "path"
        ) {
          onItemClick(item);
        }
      }}
    >
      <div className="ls-basket-row">
        <div className="main-row">
          <div className="ls-basket-item-qty">
            <span>{`${itemCount}x`}</span>
          </div>
          <div className="ls-basket-item-details">
            <div className="ls-basket-item-name">{name}</div>
            {extraItems.length > 0 && (
              <div className="ls-basket-item-desc">{extraItemsStr.join()}</div>
            )}
          </div>
          <div className="ls-basket-item-price">
            <div className="prices-wrapper">
              <div className="final-price">
                {currecyDescription} {(finalPrice).toFixed(currecyISOPrecision)}
              </div>
              {originalPrice !== undefined && originalPrice !== null && !Number.isNaN(originalPrice) && (cartItem.discounted === undefined || (cartItem.discounted !== undefined && !cartItem.discounted)) && (
                <div className="discount">
                  {currecyDescription} {(finalOriginalPrice).toFixed(currecyISOPrecision)}
                </div>
              )}
            </div>
            <Svg
              className="icon-basket-trash"
              type="trash"
              fill="#333333"
              width="20"
              height="20"
              onClick={() => onRemoveClick(currecyDescription)}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
