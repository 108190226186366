import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ROOT,
  GRUB_WAITER_WITHOUT_SOURCE_NUMBER,
  GRUB_WAITER_WITH_SOURCE_NUMBER,
  BASKET,
  ITEM_DETAIL,
  CHECKOUT,
  LOCATION_SELECT,
  PAYMENT,
  VERIFICATION,
  SELECT_ADDRESS,
  NEW_ADDRESS,
  SELECT_PAYMENT,
  UPDATE_BASKET_ITEM,
  SEARCH,
  NEW_GUEST_INFO,
  ORDER_SUMMARY,
  ORDER,
  DELIVERY_LOCATION_PICKER
} from "./utils/routeConstants";
import HomeC from "./scenes/Home";
import ItemDetail from "./scenes/ItemDetail";
import Basket from "./scenes/Basket";
import OrderComplete from "./scenes/OrderComplete";
import OrderSummary from "./scenes/OrderComplete/OrderSummary"
import Checkout from "./scenes/Checkout";
import LocationSelector from "./scenes/LocationSelector";
import Payment from "./scenes/Payment";
import MobileVerification from "./scenes/Checkout/MobileVerification";
import SelectAddress from "./scenes/Checkout/SelectAddress";
import NewAddress from "./scenes/Checkout/NewAddress";
import SelectPayment from "./scenes/Checkout/SelectPayment";
import UpdateBasketItem from "./scenes/Basket/UpdateBasketItem";
import NewGuestInfo from "./scenes/Checkout/NewGuestInfo";
import DeliveryLocationPicker from "./scenes/Home/DeliveryLocationPicker";

const Router = () => {
  if (localStorage.getItem("searchUrl") === null || SEARCH.length !== 0) {
    localStorage.setItem("searchUrl", SEARCH);
  } else {
    if (SEARCH.length === 0) {
      localStorage.setItem("searchUrl", "");
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={ROOT} component={HomeC} />
        <Route exact path={GRUB_WAITER_WITHOUT_SOURCE_NUMBER} component={HomeC} />
        <Route exact path={GRUB_WAITER_WITH_SOURCE_NUMBER} component={HomeC} />
        <Route exact path={DELIVERY_LOCATION_PICKER} component={DeliveryLocationPicker} />
        <Route exact path={ITEM_DETAIL} component={ItemDetail} />
        <Route exact path={BASKET} component={Basket} />
        <Route exact path={UPDATE_BASKET_ITEM} component={UpdateBasketItem} />
        <Route exact path={ORDER_SUMMARY} component={OrderSummary} />
        <Route exact path={ORDER} component={OrderComplete} />
        <Route exact path={CHECKOUT} component={Checkout} />
        <Route exact path={LOCATION_SELECT} component={LocationSelector} />
        <Route exact path={PAYMENT} component={Payment} />
        <Route exact path={VERIFICATION} component={MobileVerification} />
        <Route exact path={SELECT_ADDRESS} component={SelectAddress} />
        <Route exact path={NEW_ADDRESS} component={NewAddress} />
        <Route exact path={SELECT_PAYMENT} component={SelectPayment} />
        <Route exact path={NEW_GUEST_INFO} component={NewGuestInfo} />
      </Switch>
    </React.Fragment>
  );
};

export default Router;
