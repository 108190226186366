/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable dot-notation */
// import Hashmap from "hashmap";

import {
  FETCH_FROM_CART_ITEM_ID,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ITEM,
  CLEAR_MENU_ON_INITIAL_SCREEN,
  FETCH_RESTAURANT_SUCCESS
} from "../actionTypes";

import { SEARCH } from "../../utils/routeConstants";

export const clearMenuOnInitialScreen = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MENU_ON_INITIAL_SCREEN });
  };
};

export const fetchRecipeData = (categoryId, menuData, history) => {
  return () => {
    history.push(`/menu/${categoryId}/${menuData.id}/0${SEARCH}`);
  };
};

export const fetchFromCartItemId = (itemId) => {
  return (dispatch, getState) => {

    let selectedCategory;
    let selectedMenuItem;
    let price = 0;
    let originalPrice = 0;
    let payload = {};

    const fetchedMenuItems = getState().menu.menuItems;
    const currecyISOPrecision = getState().app.appParams.currecyISOPrecision;
    // this is calling from update Function
    const { cartItems } = getState().cart;

    const selectedItem = cartItems.find((i) => i.id === itemId);
    dispatch({ type: FETCH_FROM_CART_ITEM_ID, payload });
    if (selectedItem) {
      selectedCategory = fetchedMenuItems.find(
        (categories) =>
          categories.categoryData &&
          categories.categoryData.id === selectedItem.item.categoryId
      );

      selectedMenuItem = selectedCategory.categoryData.menuList.find((mi) => {
        return mi.id === selectedItem.item.id;
      });

      selectedMenuItem.recipeIds.forEach((sm) => {
        price += sm.sellingPrice;
        originalPrice += sm.originalPrice;
      });

      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: fetchedMenuItems,
      });

      const { recipeIds } = selectedMenuItem;
      const rewardType = recipeIds[0].rewardType
      const rewardValue = recipeIds[0].rewardValue

      const modifiers = [];
      const mandatoryExtraItems = [];
      if (recipeIds.length > 0) {
        recipeIds.forEach((rp) => {
          if (rp.recipe && rp.recipe.modifiersGroups) {
            rp.recipe.modifiersGroups.forEach((mg) => {
              mg.modifier.forEach(mod => {
                let originalPrice;
                let modifiedPrice;
                if (rewardType !== undefined && rewardType === "PERCENTAGE") {
                  originalPrice = mod.price;
                  modifiedPrice = (mod.price * (100 - rewardValue)) / 100;
                  if (mod.originalPrice === undefined) {
                    mod.price = parseFloat(modifiedPrice.toFixed(currecyISOPrecision))
                    mod.originalPrice = originalPrice
                  }
                }
              });

              modifiers.push({ ...mg, recipeId: rp.recipe.id });
            });
          }
        });
      }

      modifiers.forEach((md) => {
        if (md.min === 1 && md.max === 1) {
          mandatoryExtraItems.push(md.modifierGroupId);
        }
      });

      payload = {
        ...selectedMenuItem,
        // id: itemId, // overring id to the current objectID
        categoryId: selectedItem.item.categoryId,
        price,
        mandatoryExtraItems,
        modifiers,
        existing: true,
        extraItemPrice: selectedItem.extraItemPrice,
        extraItems: selectedItem.extraItems,
        itemCount: selectedItem.itemCount,
        rewardType,
        rewardValue,
        originalPrice,
        isOpenCategory: selectedCategory.categoryData.isOpenCategory
      };

      dispatch({
        type: FETCH_FROM_CART_ITEM_ID,
        payload,
      });
    }
  };
};

export const fetchMenuItem = (categoryId, menuId) => {
  return (dispatch, getState) => {
    const fetchedMenuItems = getState().menu.menuItems;
    const currecyISOPrecision = getState().app.appParams.currecyISOPrecision;
    let selectedCategory;
    let selectedMenuItem;
    let price = 0;
    let originalPrice = 0
    let payload;

    if (categoryId && menuId) {
      selectedCategory = fetchedMenuItems.find(
        (categories) =>
          categories.categoryData && categories.categoryData.id === categoryId
      );

      selectedMenuItem = selectedCategory.categoryData.menuList.find(
        (mi) => mi.id === menuId
      );

      selectedMenuItem.recipeIds.forEach((sm) => {
        price += sm.sellingPrice;
        originalPrice += sm.originalPrice
      });

      const { recipeIds } = selectedMenuItem;

      const rewardType = recipeIds[0].rewardType
      const rewardValue = recipeIds[0].rewardValue

      const modifiers = [];
      const mandatoryExtraItems = [];


      if (recipeIds.length > 0) {
        recipeIds.forEach((rp) => {
          if (rp.recipe && rp.recipe.modifiersGroups) {
            rp.recipe.modifiersGroups.forEach((mg) => {
              mg.modifier.forEach(mod => {
                let originalPrice;
                let modifiedPrice;
                if (rewardType !== undefined && rewardType === "PERCENTAGE") {
                  originalPrice = mod.price;
                  modifiedPrice = (mod.price * (100 - rewardValue)) / 100;
                  if (mod.originalPrice === undefined) {
                    mod.price = parseFloat(modifiedPrice.toFixed(currecyISOPrecision))
                    mod.originalPrice = originalPrice
                  }
                }
              });
              modifiers.push({ ...mg, recipeId: rp.recipe.id });
            });
          }
        });
      }

      modifiers.forEach((md) => {
        if (md.min === 1 && md.max === 1) {
          mandatoryExtraItems.push(md.modifierGroupId);
        }
      });

      payload = {
        ...selectedMenuItem,
        categoryId,
        price,
        mandatoryExtraItems,
        modifiers,
        originalPrice,
        isOpenCategory: selectedCategory.categoryData.isOpenCategory
      };
    } else {
      // this is calling from update Function
      const { cartItems } = getState().cart;
      const selectedItem = cartItems.find((i) => i.id === menuId);

      if (selectedItem) {
        selectedCategory = fetchedMenuItems.find(
          (categories) =>
            categories.categoryData &&
            categories.categoryData.id === selectedItem.item.categoryId
        );

        selectedMenuItem = selectedCategory.categoryData.menuList.find(
          (mi) => mi.id === selectedItem.item.menuId
        );

        selectedMenuItem.recipeIds.forEach((sm) => {
          price += sm.sellingPrice;
          originalPrice += sm.originalPrice
        });

        const { recipeIds } = selectedMenuItem;
        const rewardType = recipeIds[0].rewardType
        const rewardValue = recipeIds[0].rewardValue

        const modifiers = [];
        const mandatoryExtraItems = [];
        if (recipeIds.length > 0) {
          recipeIds.forEach((rp) => {
            if (rp.recipe && rp.recipe.modifiersGroups) {
              rp.recipe.modifiersGroups.forEach((mg) => {
                mg.modifier.forEach(mod => {
                  let originalPrice;
                  let modifiedPrice;
                  if (rewardType !== undefined && rewardType === "PERCENTAGE") {
                    originalPrice = mod.price;
                    modifiedPrice = (mod.price * (100 - rewardValue)) / 100;
                    if (mod.originalPrice === undefined) {
                      mod.price = parseFloat(modifiedPrice.toFixed(currecyISOPrecision))
                      mod.originalPrice = originalPrice
                    }
                  }

                });

                modifiers.push({ ...mg, recipeId: rp.recipe.id });
              });
            }
          });
        }
        modifiers.forEach((md) => {
          if (md.min === 1 && md.max === 1) {
            mandatoryExtraItems.push(md.modifierGroupId);
          }
        });

        payload = {
          ...selectedMenuItem,
          categoryId,
          price,
          mandatoryExtraItems,
          modifiers,
          existing: true,
          extraItemPrice: selectedItem.extraItemPrice,
          extraItems: selectedItem.extraItems,
          itemCount: selectedItem.itemCount,
          rewardType,
          rewardValue,
          originalPrice,
          isOpenCategory: selectedCategory.categoryData.isOpenCategory
        };
      }
    }

    // eslint-disable-next-line prefer-const

    dispatch({
      type: FETCH_MENU_ITEM,
      payload,
    });
  };
};

export const updateSelectedLocation = (locationId) => {
  return (dispatch, getState) => {
    const { restaurant } = getState().menu;
    dispatch({
      type: FETCH_RESTAURANT_SUCCESS,
      payload: {
        ...restaurant,
        locationId: locationId
      }
    });
  };
};