import React from 'react';
import { withRouter } from 'react-router-dom';

const Breadcrumb = ({ items, leftSide, history }) => {
	const handleClick = (e) => {
		e.preventDefault();
		const href = e.target.getAttribute('href');
		href && history.push(href);
		return false;
	};
	// eslint-disable-next-line no-param-reassign
	items = items.map((child, index) => (
		<a key={`breadcrumb_item${index}`} href={child.url} onClick={handleClick}>
			{(index > 0 ? ' / ' : '') + (child.name || child)}
		</a>
	));

	return (
		<div
			className="ls-breadcrumb"
			style={{
				justifyContent: leftSide ? 'left' : 'center'
			}}
		>
			<div className="container">{items}</div>
		</div>
	);
};

export default withRouter(Breadcrumb);
