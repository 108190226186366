import React, { useState } from "react";

const NumberBoxes = ({ boxes, onComplete, onIncomplete, isOnError=false }) => {
  const INTIAL_NUMBERS_STATE = (new Array(boxes)).fill("");
  const [numbers, setnumbers] = useState(INTIAL_NUMBERS_STATE);

  const onReceiveValue = (event, index) => {  
    const value = event.target.value;
    numbers[index] = value;
    setnumbers(numbers)

    const allFieldsFilled = !isEmpty();
    
    if(onIncomplete && !allFieldsFilled) onIncomplete();
    if(onComplete && allFieldsFilled) onComplete(numbers.join(''));

    const nextElem = event.target.nextElementSibling
    if(nextElem !== null && value !== "") nextElem.focus()
  };

  const onKeyDownReceive = (event, index) => {
    const keyID = event.keyCode;
    
    if(keyID === 46 || keyID === 8) {
      const value = event.target.value;
      const prevElem = event.target.previousElementSibling;
      if(prevElem !== null && value === "") {
        prevElem.focus();
        // enable this if user wants to avoid the extra backspace hit
        // event.preventDefault();
      }
    }
  }

  const isEmpty = () => {
      return numbers.find(n => {
          return n === "";
      }) >= 0;
  }

  const boxStyle = () => {
    const defaultStyleClass = "number-box";
    const errorClass = 'error';
    if (isOnError) return defaultStyleClass.concat(' ', errorClass);

    return defaultStyleClass;
  }

  return (
    <div className="number-boxes-wrapper">
      {[...Array(boxes).keys()].map((key, index) => {
        return <input type="tel" key={key} className={boxStyle()} maxLength={1} onChange={(e) => onReceiveValue(e, index)} onKeyDown={e => onKeyDownReceive(e, index)} />;
      })}
    </div>
  );
};

export default NumberBoxes;
