import React from "react";
import { Svg } from "../Svg";
import { RESTAURANT_OPEN } from "../../utils/appConstants";
import Loader from "../Loader";

const hidden = {
  display: "none"
};

const show = {
  display: "block"
};

function Banner(props) {
  const {
    image,
    restaurantName,
    restaurantStatus,
    operatingHours,
    displayTime,
    toggleDisplayHours,
    openModal,
    applicationType,
    selectedLocationInfo,
    deliveryTimeDisplayText
  } = props;
  const LS_BANNER_IMG_ID = "ls-banner-home";
  const DISABLED_IF_VIRTUAL_WAITER = (applicationType === 'ONLINE_ORDER');

  return (
    <div className="ls-banner">
      {applicationType && applicationType === "ONLINE_ORDER" ? (
        <>
          {image !== undefined ? (
            <>
              <div id={LS_BANNER_IMG_ID} className="ls-banner-image stretch-vw">
                <img src={image} alt="" />
              </div>
            </>
          ) : <Loader />}
          <div className="ls-banner-rect">
            {DISABLED_IF_VIRTUAL_WAITER && <Svg
              className="icon-info"
              type="info"
              width="22"
              height="22"
              fill="#8749ff"
              onClick={toggleDisplayHours}
            />}
            <div className="restaurant-name">{restaurantName}</div>
            {DISABLED_IF_VIRTUAL_WAITER && (
              <React.Fragment>
                <div
                  className={
                    restaurantStatus === RESTAURANT_OPEN
                      ? "open-now"
                      : "closed-now"
                  }
                >
                  {restaurantStatus}
                </div>
                <div className="within-mins">
                  <Svg
                    className="icon-map-pointer"
                    type="map-pointer"
                    fill="#000000"
                    width="18"
                    height="22"
                  />
                  {deliveryTimeDisplayText}
                  {selectedLocationInfo &&
                    <span
                      className="location-change"
                      style={{ float: "right", marginRight: "10px" }}
                      onClick={openModal}
                    >
                      <Svg
                        className="icon-map-pointer"
                        type="map-pointer"
                        fill="#77aa1d"
                        width="18"
                        height="22"
                      />
                      Change Location</span>}
                </div>
                <RestHours
                  operatingHours={operatingHours}
                  isOpen={restaurantStatus}
                  displayTime={displayTime}
                />
              </React.Fragment>
            )}
          </div>
        </>
      ) : (
          <>
            {image !== undefined ? (
              <>
                <div id={LS_BANNER_IMG_ID} className="ls-banner-image stretch-vw">
                  <img src={image} alt="" />
                </div>
                {restaurantName && (<div className="restaurant-name-vw">{restaurantName}</div>)}
              </>
            ) : <Loader />}
          </>
        )}
    </div>
  );
}

const RestHours = ({ operatingHours, displayTime }) => {
  return (
    <div className="rest-details">
      <div className="rest-hours-wrapper">
        <span className="rest-hours open-hours__section">
          <ul className="open-hours__ul">
            {operatingHours &&
              operatingHours.map((day, index) => {
                const startTime = day.startTime.split(":");
                let startHour = startTime[0];
                const startSuffix = startHour >= 12 ? "PM" : "AM";
                startHour = startHour > 12 ? startHour - 12 : startHour;
                startHour = startHour === "00" ? 12 : startHour;

                const endTime = day.endTime.split(":");
                let endHour = endTime[0];
                const endSuffix = endHour >= 12 ? "PM" : "AM";
                endHour = endHour > 12 ? endHour - 12 : endHour;
                endHour = endHour === "00" ? 12 : endHour;

                const date = new Date();
                const today = date.toLocaleDateString("en-US", {
                  weekday: "long",
                });

                if (day.startTime !== "") {
                  return (
                    <li
                      key={index}
                      className={
                        day !== today
                          ? "open-hours__li"
                          : "open-hours__li open-hours__li--current"
                      }
                      style={displayTime === "hidden" ? hidden : show}
                    >
                      <span className="open-hours__day">{day.day}</span>
                      <span className="open-hours__time">
                        {`${startHour}:${startTime[1]} ${startSuffix} - ${endHour}:${endTime[1]} ${endSuffix}`}
                      </span>
                    </li>
                  );
                }
                return "";
              })}
          </ul>
        </span>
      </div>
    </div>
  );
};

export default Banner;
