import React from 'react';

function MenuCategory(props) {
	const { name, description } = props;
	return <>
		<div className="ls-menu-items-header">{name}</div>
		{description && <div className="ls-menu-items-description">{description}</div>}
	</>;
}

export default MenuCategory;
