const searchUrl = localStorage.getItem("searchUrl");
export const SEARCH =
  searchUrl !== null &&
  window.location.search.length !== 0 &&
  searchUrl.length !== 0
    ? searchUrl
    : window.location.search.length === 0
    ? ""
  : window.location.search.includes("signature")
  ? ""
  : window.location.search.includes("merchant_reference")
  ? ""
  :  window.location.search;

export const ROOT = "/";
export const DELIVERY_LOCATION_PICKER = "/delivery-location-pick";
export const BASKET = "/basket";
export const UPDATE_BASKET_ITEM = "/basket/:itemId";
export const ITEM_DETAIL = "/menu/:categoryId/:menuId/:cartItemId";
export const GRUB_WAITER_WITHOUT_SOURCE_NUMBER = "/:brandId&:kitchenId&source:";
export const GRUB_WAITER_WITH_SOURCE_NUMBER = "/:brandId&:kitchenId&source:&sourceNumber:";
export const ORDER = "/order";
export const ORDER_SUMMARY = "/order/:orderInternalId";
export const CHECKOUT = "/checkout";
export const LOCATION_SELECT = "/location-select";
export const PAYMENT = "/payments";
export const VERIFICATION = "/verification";
export const SELECT_ADDRESS = "/select-address";
export const NEW_ADDRESS = "/new-address";
export const SELECT_PAYMENT = "/select-payment";
export const NEW_GUEST_INFO = "/new-guest-info"
