export const PAYMENT = {
  CASH: "COD",
  CREDIT_CARD: "CCOD",
  PREPAID: "CARD",
  FOC: "FOC"
};
export const PAYMENT_STATUS = {
  PREPAID: "PREPAID",
  UNPAID: "UNPAID",
  FOC: "FOC"
};
export const PAYFORT_STATUS = {
  SUCCESS: "18",
};

export const PAYFORT_3DS_STATUS = {
  SUCCESS: "14000",
  TRANSACTION_DECLINED: "666",
  INSUFFICIENT_FUNDS: "029",
  INVALID_CARD_NUMBER: "016",
  CARD_EXPIRED: "012"
};
