import {
  ADD_ITEM_TO_CART,
  FETCH_CART_RESPONSE_SUCCESS,
  UPDATE_EXISTING_CART_ITEM,
  REMOVE_ITEM_FROM_CART,
  FETCH_ORDER_RESPONSE_SUCCESS,
  SET_DELIVERY_LOCATION,
  FETCH_ORDER_RESPONSE_FAILED,
  SET_CART_EMPTY,
  SET_MENU_CARD_ID,
  APPLY_PROMO_DISCOUNT,
  REMOVE_PROMO_DISCOUNT,
  ADD_CUSTOM_NOTE_TO_CART,
  SET_MENU_CARD_IMAGE,
  SET_OPERATING_HOURS,
  FETCH_MERCHANT_REFERENCE,
  FETCH_ACCESS_CODE,
  FETCH_MERCHANT_ID,
  SET_SITEWIDE_PROMO_RESPONSE,
  REMOVE_SITEWIDE_PROMO_RESPONSE,
  SET_KITCHEN_ID
} from "../actionTypes";

const initialState = {
  cartItems: [],
  totalPrice: 0,
  subTotal: 0,
  itemCount: 0,
  cartResponse: {},
  deliveryLocation: {},
  deliveryFee: 0,
  taxRate: 0,
  tax: 0,
  discount: 0,
  transactionID: "",
  voucherCode: "",
  customNote: "",
  merchantReference: "",
  accessCode: "",
  merchantId: "",
  siteWideVoucherDetails: {}
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_PROMO_DISCOUNT: {
      return {
        ...state,
        cartItems: action.payload.cartItems,
        subTotal: action.payload.subTotal,
        totalPrice: action.payload.totalPrice,
        discount: action.payload.discount,
        transactionID: action.payload.uniqueID,
        voucherCode: action.payload.voucherCode,
      };
    }

    case REMOVE_PROMO_DISCOUNT: {
      return {
        ...state,
        cartItems: action.payload.cartItems,
        subTotal: action.payload.subTotal,
        totalPrice: action.payload.totalPrice,
        discount: action.payload.discount,
        voucherCode: action.payload.voucherCode,
      };
    }

    case ADD_ITEM_TO_CART: {
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload.menuItem],
        subTotal: action.payload.subTotal,
        totalPrice: action.payload.total,
        tax: action.payload.tax,
        itemCount: state.itemCount + 1,
      };
    }

    case ADD_CUSTOM_NOTE_TO_CART: {
      return {
        ...state,
        customNote: action.payload,
      };
    }

    case FETCH_CART_RESPONSE_SUCCESS: {
      return {
        ...state,
        cartResponse: action.payload,
      };
    }
    case UPDATE_EXISTING_CART_ITEM: {
      const { updatedCartItems, totalPrice, subTotal, tax } = action.payload;
      return {
        ...state,
        cartItems: updatedCartItems,
        subTotal,
        totalPrice,
        itemCount: updatedCartItems.length,
        tax,
      };
    }
    case REMOVE_ITEM_FROM_CART: {
      const { updatedCartItems, totalPrice, subTotal, tax } = action.payload;
      return {
        ...state,
        cartItems: updatedCartItems,
        totalPrice,
        subTotal,
        itemCount: updatedCartItems.length,
        tax,
      };
    }
    case FETCH_ORDER_RESPONSE_SUCCESS:
      return {
        ...state,
        orderResponse: action.payload,
      };
    case FETCH_ORDER_RESPONSE_FAILED:
      return {
        ...state,
        orderResponse: action.payload,
      };
    case SET_DELIVERY_LOCATION:
      return {
        ...state,
        deliveryLocation: action.payload,
      };
    case SET_CART_EMPTY:
      return {
        ...state,
        cartItems: [],
        itemCount: 0,
        totalPrice: 0,
        subTotal: 0,
        // removed discount and voucher code on a successful order creation
        discount: 0,
        voucherCode: "",
      };
    case SET_MENU_CARD_ID:
      return {
        ...state,
        menuCardId: action.payload,
      };
    case SET_KITCHEN_ID:
      return {
        ...state,
        kitchenId: action.payload,
      };
    case SET_MENU_CARD_IMAGE:
      return {
        ...state,
        menuCardImage: action.payload,
      };
    case SET_OPERATING_HOURS:
      return {
        ...state,
        operatingHours: action.payload,
      };
    case FETCH_MERCHANT_REFERENCE: {
      return {
        ...state,
        merchantReference: action.payload,
      };
    }
    case FETCH_ACCESS_CODE: {
      return {
        ...state,
        accessCode: action.payload,
      };
    }
    case FETCH_MERCHANT_ID: {
      return {
        ...state,
        merchantId: action.payload,
      };
    }
    case SET_SITEWIDE_PROMO_RESPONSE: {
      return {
        ...state,
        siteWideVoucherDetails: action.payload
      };
    }
    case REMOVE_SITEWIDE_PROMO_RESPONSE: {
      return {
        ...state,
        siteWideVoucherDetails: {}
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
