import React from "react";

const DeliveryInfo = ({ changeAddress, deliveryLocation }) => {
  return (
    <div className="content-section delivery-info__wrap">
      <div className="content-title">
        <div className="content-title-left delivery-info__label">Deliver to this Address</div>
        <div
          role="presentation"
          className="content-title-right"
          onClick={() => changeAddress()}
        >
          <div className="content-link change">Change</div>
        </div>
      </div>
      <div className="content-content delivery-info">{deliveryLocation}</div>
      {/* <div className="content-footer">
        <div className="content-link">Add Rider Instructions</div>
      </div> */}
    </div>
  );
};

export default DeliveryInfo;
