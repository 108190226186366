/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

function MenuItem(props) {
	const { name, description, price, image, onItemClick, currecyDescription, openImage, orginalPrice, ui, isOpenCategory, currecyISOPrecision } = props;
	let bannerImage = "";
	if (image) {
		bannerImage = image;
	}
	const handleError = (imageCdn) => {
		const img = document.querySelector("img[src='" + imageCdn + "']");
		img && img.parentElement.remove();
	};

	return (
		<div role="button" className="ls-menu-item">
			<div className="ls-menu-item-details" onClick={onItemClick}>
				<h6 onClick={onItemClick}>{name}</h6>
				<p className="ls-desc">{description}</p>
				<p className="ls-price">
					{ui.screenSize === "sm" ? (<span> {`${currecyDescription} ${price.toFixed(currecyISOPrecision)}`}</span>) : null}

					{
						orginalPrice > 0 ? (<span> <del> {`${currecyDescription} ${orginalPrice}`}</del> </span>) : null
					}
					{ui.screenSize === "lg" ? (<span> {`${currecyDescription} ${price.toFixed(currecyISOPrecision)}`}</span>) : null}
					<button
						type="button"
						className="gt-default-button"
						style={{ backgroundColor: (!isOpenCategory ? "#909ca2" : null) }}
						onClick={onItemClick}
					>
						+ ADD
					</button>
				</p>
			</div>
			<div className="ls-menu-item-image-wrapper" onClick={() => openImage(bannerImage)}>
				<img
					className="ls-menu-item-image"
					src={bannerImage}
					alt="menuitem"
					onError={() => handleError(bannerImage)}
				/>
			</div>
		</div>
	);
}

export default MenuItem;
