import {
  SET_CUSTOMER_DETAILS,
  SET_SELECTED_LOCATION,
  SET_PREVIOUS_LOCATIONS,
  SET_MOBILE_NUMBER,
  SET_LOADER_OFF,
  SET_LOADER_ON,
  SET_MOBILE_NUMBER_REMEMBER,
  FETCH_RESTAURANT_SUCCESS
} from "../actionTypes";
import uuid from "uuid";
import axios from "axios";
import { CUSTOMERS, FETCH_APP_LOCATION } from "../../utils/apiConstants";

const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;

export const setMobileNumberRemember = (mobileNumberOb) => {
  return (dispatch, getState) => {
    const { rememberMobileNumbers } = getState().auth;
    var mobileNumberList = [];
    if (rememberMobileNumbers === undefined) {
      if (
        mobileNumberOb.mobileNumber !== null
      ) {
        mobileNumberList.unshift(mobileNumberOb);
      }
    } else {
      var existsRememberMobileNumberOb = rememberMobileNumbers.find(
        (data) => data.mobileNumber === mobileNumberOb.mobileNumber
      );
      if (
        existsRememberMobileNumberOb === undefined
      ) {
        mobileNumberList = rememberMobileNumbers;
        mobileNumberList.unshift(mobileNumberOb);
      } else {
        //remove phone number => remember list
        if (mobileNumberOb.mobileNumberRemember === false) {
          mobileNumberList = rememberMobileNumbers.filter(
            (data) => data.mobileNumber !== mobileNumberOb.mobileNumber
          );
          //update phone number and customer code
        } else {
          mobileNumberList = rememberMobileNumbers.filter(
            (data) => data.mobileNumber !== mobileNumberOb.mobileNumber
          );
          mobileNumberList.unshift(mobileNumberOb);
        }
      }
    }
    dispatch({ type: SET_MOBILE_NUMBER_REMEMBER, payload: mobileNumberList });
  };
};

export const setUserAddress = (addressObj) => {
  return (dispatch) => {
    const { address1, address2, address3 } = addressObj;
    const location = [address1, address2, address3].join(" ");
    const name = `${addressObj.firstName} ${addressObj.lastName}`;
    const newLocation = { ...addressObj, name };

    dispatch({ type: SET_SELECTED_LOCATION, payload: location });
    dispatch({ type: SET_CUSTOMER_DETAILS, payload: newLocation });
  };
};

export const setSelectDeliveryDetails = (addressObj) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    addressObj.customerName = user.customerName;
    addressObj.mobileNumber = user.mobileNumber;
    addressObj.emailAddress = user.emailAddress;
    dispatch({
      type: SET_CUSTOMER_DETAILS,
      payload: addressObj,
    });
  };
};

export const setSelectedLocation = (location) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_LOCATION, payload: location });
  };
};

export const setUsersPreviousLocation = (responseObj) => {
  return (dispatch) => {
    const previousAddresses = [];
    const userObj = {
      name: responseObj.name,
      contactNumber: responseObj.contactNumber,
      email: responseObj.email
    };

    responseObj.recipients.forEach((ad) => {
      previousAddresses.push({ ...ad, id: uuid.v4() });
    });

    dispatch({ type: SET_CUSTOMER_DETAILS, payload: userObj });
    dispatch({ type: SET_PREVIOUS_LOCATIONS, payload: previousAddresses });
  };
};

export const setLocationCoordinates = () => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADER_ON });

    const { restaurant } = getState().menu;

    axios.get(appApiEndPoint + FETCH_APP_LOCATION + "/" + restaurant.locationId + "/basicInfo")
      .then(async locationData => {
        if (locationData.status === 200) {
          const locationOb = {
            lat: locationData.data.latitude,
            lng: locationData.data.longitude,
          }
          dispatch({
            type: FETCH_RESTAURANT_SUCCESS,
            payload: { ...restaurant, location: locationOb },
          });
        }
        dispatch({ type: SET_LOADER_OFF });
      }).catch((error) => {
        dispatch({ type: SET_LOADER_OFF });
      });
  };
};

export const saveCustomerDetails = () => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADER_ON });
    const { app } = getState();
    const { user, mobileNumber, previousLocations } = getState().auth;
    const { deliveryLocation } = getState().cart;

    const contactNumber = user.contactNumber.replace("+", "");

    const requestObject = {
      partnerId: app.appParams.partnerId,
      brandId: app.appParams.restaurantId,
      name: user.name,
      contactNumber: mobileNumber.replace("+", ""),
      email: user.email,
      recipients: [
        {
          label: user.label,
          name: user.name,
          contactNumber: contactNumber,
          latitude: deliveryLocation.lat,
          longitude: deliveryLocation.lng,
          buildingName: user.buildingName,
          address: user.address,
          //tempory
          area: "",
          notes: user.notes,
        }
      ]
    };

    axios.post(appApiEndPoint + CUSTOMERS, requestObject)
      .then(async res => {
        if (res.status === 204) {

          const deliveryDetails = {
            name: user.name,
            contactNumber: contactNumber,
            email: user.email,
            label: user.label,
            latitude: deliveryLocation.lat,
            longitude: deliveryLocation.lng,
            buildingName: user.buildingName,
            address: user.address,
          };

          dispatch({
            type: SET_CUSTOMER_DETAILS,
            payload: deliveryDetails,
          });

          previousLocations.push(deliveryDetails);
          dispatch({ type: SET_PREVIOUS_LOCATIONS, payload: previousLocations });

          dispatch({ type: SET_LOADER_OFF });
        }
      }).catch((error) => {
        dispatch({ type: SET_LOADER_OFF });
      });
  };
};

export const setMobileNumber = (number) => {
  return (dispatch) => {
    dispatch({ type: SET_MOBILE_NUMBER, payload: number });
  };
};
