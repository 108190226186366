import React from "react";
import { PAYMENT } from "../../utils/enums";

const renderSelectedPayment = (props) => {
  const { onPaymentClick, selectedPayment } = props;
  switch (selectedPayment) {
    case PAYMENT.CASH:
      return (
        <div
          role="presentation"
          className="content-footer"
          onClick={() => onPaymentClick()}
        >
          <div className="content-link">
            <i className="material-icons">money</i>
            Cash on Delivery
          </div>
        </div>
      );
    case PAYMENT.CREDIT_CARD:
      return (
        <div
          role="presentation"
          className="content-footer"
          onClick={() => onPaymentClick()}
        >
          <div className="content-link">
            <i className="material-icons">credit_card</i>
            Card on Delivery
          </div>
        </div>
      );
    case PAYMENT.PREPAID:
      return (
        <div
          role="presentation"
          className="content-footer"
          onClick={() => onPaymentClick()}
        >
          <div className="content-link">
            <i className="material-icons">credit_card</i>
            Paid By Card
          </div>
        </div>
      );
      case PAYMENT.FOC:
        return (
          <div
            role="presentation"
            className="content-footer"
            onClick={() => onPaymentClick()}
          >
            <div className="content-link">
              <i className="material-icons">free_breakfast</i>
              Free of Charge
            </div>
          </div>
        );
    default:
      return (
        <div
          role="presentation"
          className="content-footer"
          onClick={() => onPaymentClick()}
        >
          <div className="content-link">
            <i className="material-icons">credit_card</i>
            Add Payment method
          </div>
        </div>
      );
  }
};

const PaymenInfo = (props) => {
  const { onPaymentClick, selectedPayment } = props;

  return (
    <div className="content-section">
      <div className="content-title">
        <div className="content-title-left delivery-info__label">Payment</div>
        {selectedPayment && (
          <div
            role="presentation"
            className="content-title-right"
            onClick={() => onPaymentClick()}
          >
            <div className="content-link change">Change</div>
          </div>
        )}
      </div>
      {renderSelectedPayment(props)}
    </div>
  );
};

export default PaymenInfo;
