import React, { Component } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist6";
import storage from "redux-persist6/lib/storage";
import { PersistGate } from "redux-persist6/integration/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rootReducer from "./store/reducers/rootReducer";
import App from "./App";
import { setScreenSize } from "./store/actions/responsiveActions";

const mediaQuery = window.matchMedia("(min-width: 768px)");

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["payment"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
if (mediaQuery) {
  const mediaQueryCheckFn = (mq) => {
    if (mq.matches) {
      store.dispatch(setScreenSize("lg"));
    } else {
      store.dispatch(setScreenSize("sm"));
    }
  };
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener("change", mediaQueryCheckFn);
  } else if (mediaQuery.addListener) {
    mediaQuery.addListener(mediaQueryCheckFn);
  }
  window.requestAnimationFrame(() => {
    mediaQueryCheckFn(mediaQuery);
  });
}

toast.configure({ draggable: false, position: "bottom-center" });
const persistor = persistStore(store);
if (window.location.search === "?debug") {
  persistor.purge();
}

class WithProvider extends Component {

  render() {
    return (<
      Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>)
  }
}

export default WithProvider;
