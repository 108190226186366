/* eslint-disable radix */

import { RESTAURANT_OPEN, RESTAURANT_CLOSED } from './appConstants';

function getTimeAsNumberOfMinutes(time) {
	const timeParts = time.split(':');
	return timeParts[0] * 60 + parseInt(timeParts[1]);
}

const checkRestaurantOpen = (operatingHours) => {
	const date = new Date();
	const today = date.toLocaleDateString('en-US', {
		weekday: 'long'
	});

	let isOpen = RESTAURANT_CLOSED;

	operatingHours.map((day) => {
		if (day.day === today) {
			const currentHours = (date.getHours() < 10 ? '0' : '') + date.getHours();
			const currentMinutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
			const currentTime = getTimeAsNumberOfMinutes(`${currentHours}:${currentMinutes}`);
			const openingTime = getTimeAsNumberOfMinutes(day.startTime);
			const closingTime = getTimeAsNumberOfMinutes(day.endTime);

			if (currentTime >= openingTime && currentTime <= closingTime) {
				isOpen = RESTAURANT_OPEN;
			}
		}
		return null;
	});
	return isOpen;
};

export default checkRestaurantOpen;
