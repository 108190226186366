import { toast } from "../../components/Toast";
import axios from "axios";
import {
  FETCH_INITIAL_PARAMS_SUCCESSFUL,
  SET_SELECTED_CITY,
  SET_LOADER_ON,
  SET_LOADER_OFF,
  SET_DELIVERY_LOCATION,
  SET_MODAL_ON,
  SET_MODAL_OFF,
  SET_PAYMENT_METHODS,
  SET_VOUCHERY_END_POINT,
  SET_SITEWIDE_PROMO_DETAILS,
  FETCH_CURRENT_DISTRICT_CODE,
  SET_APPLICATION_TYPE,
  SET_OPERATING_HOURS,
  FETCH_RESTAURANT_SUCCESS,
  FETCH_MENU_SUCCESS,
  VIRTUAL_WAITER_CONFIGURATIONS,
  SELECTED_LOCATION_INFO,
  SELECTED_LOCATION_COORDINATES
} from "../actionTypes";
import { FETCH_APP_CONFIGURATION, FETCH_NEAREST_LOCATION, FETCH_APP_MENU, FETCH_PROMOTIONS } from "../../utils/apiConstants";
import TagManager from "react-gtm-module";
import Hashmap from "hashmap";
import { DELIVERY_LOCATION_PICKER, SEARCH, ROOT } from "../../utils/routeConstants";

const locationApiEndPoint = process.env.REACT_APP_LOCATION_API_URL;
const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;

//online-order/virtual waiter
export const setApplicationType = (type, params) => {
  return (dispatch) => {
    if (type === "VIRTUAL_WAITER") {
      dispatch({ type: SET_APPLICATION_TYPE, payload: "VIRTUAL_WAITER" });
      dispatch({ type: VIRTUAL_WAITER_CONFIGURATIONS, payload: params });
    } else {
      dispatch({ type: SET_APPLICATION_TYPE, payload: "ONLINE_ORDER" });
    }
  };
};

export const updateDistrictCode = (districCode) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CURRENT_DISTRICT_CODE, payload: districCode });
  }
}

const findISOPrecisionByCurrencyCode = (currencyCode) => {
  if ("JOD" === currencyCode) {
    return 2;
  } else {
    const formatedValue = new Intl.NumberFormat('en-US',
      {
        style: 'currency',
        currency: currencyCode,
        useGrouping: false
      }
    ).format(0);

    const splitedValue = formatedValue.split(".");
    if (splitedValue.length > 1) {
      return splitedValue[splitedValue.length - 1].length;
    } else {
      return 0;
    }
  }
}

const getDoubleAmount = (amount, currencyCode) => {
  const fractionDigit = findISOPrecisionByCurrencyCode(currencyCode);
  return parseFloat(amount / Math.pow(10, fractionDigit));
}

const convertMenu = (menuApiResponseOb) => {
  const apiMenuData = menuApiResponseOb;
  let rootData = [];
  // eslint-disable-next-line
  apiMenuData.categories.map(function (categoryData) {
    let menuItemDataList = [];
    // eslint-disable-next-line
    categoryData.items.map(function (itemData) {
      let menuItem = apiMenuData.items.find(
        (data) => data.id === itemData
      );

      if (menuItem !== undefined) {
        let modifierGroupDataList = [];
        // eslint-disable-next-line
        menuItem.modifierGroups.map(function (modifierGroupId) {
          let modifierGroup = apiMenuData.modifierGroups.find(
            (data) => data.id === modifierGroupId
          );

          if (modifierGroup !== undefined) {
            let modifierDataList = [];
            // eslint-disable-next-line
            modifierGroup.modifiers.map(function (modifierId) {
              let modifier = apiMenuData.items.find(
                (data) => data.id === modifierId
              );

              if (modifier !== undefined) {
                if (modifier.availability !== "UNAVAILABLE") {
                  let modifierData = {
                    "modifierId": modifier.id,
                    "displayName": modifier.name.translations['en-US'],
                    "modifierQty": modifier.quantityInfo.maxPermitted,
                    "price": getDoubleAmount(modifier.priceInfo.price, apiMenuData.currencyCode),
                    "active": true
                  }
                  modifierDataList.push(modifierData);
                }
              }
            });

            let modifierGroupData = {
              "modifierGroupId": modifierGroup.id,
              "groupName": modifierGroup.name.translations['en-US'],
              "min": modifierGroup.quantityInfo.minPermitted,
              "max": modifierGroup.quantityInfo.maxPermitted,
              "modifier": modifierDataList
            }
            modifierGroupDataList.push(modifierGroupData);
          }

        });

        if (menuItem.availability !== "UNAVAILABLE") {
          let menuData = {
            "id": menuItem.id,
            "internalId": menuItem.internalId,
            "name": menuItem.name.translations['en-US'],
            "details": menuItem.description.translations['en-US'],
            "images": [menuItem.imageUrl],
            "recipeIds": [
              {
                "internalId": menuItem.id,
                "intId": menuItem.internalId,
                "sellingPrice": getDoubleAmount(menuItem.priceInfo.price, apiMenuData.currencyCode),
                "recipe": {
                  "id": menuItem.id,
                  "name": menuItem.name.translations['en-US'],
                  "details": menuItem.description.translations['en-US'],
                  "modifiersGroups": modifierGroupDataList
                }
              }
            ]
          };

          menuItemDataList.push(menuData);
        }
      }
    });

    let menuData = {
      "internalId": categoryData.id,
      "categoryData": {
        "id": categoryData.id,
        "name": categoryData.name.translations['en-US'],
        "description": validateCategoryDescription(categoryData),
        "isOpenCategory": checkOperationHoursValidation(categoryData.servingHours),
        "menuList": menuItemDataList
      }
    }
    rootData.push(menuData);
  });

  return rootData;
}

const validateCategoryDescription = (categoryData) => {
  if (categoryData.description !== undefined
    && categoryData.description !== null
    && categoryData.description.translations !== undefined
    && categoryData.description.translations !== null) {
    return categoryData.description.translations['en-US'];
  }
  return null;
}

const getTimeAsNumberOfMinutes = (time) => {
  const timeParts = time.split(':');
  return timeParts[0] * 60 + parseInt(timeParts[1]);
}

const checkOperationHoursValidation = (operatingHours) => {
  const date = new Date();
  const today = date.toLocaleDateString('en-US', {
    weekday: 'long'
  });

  let isOpen = false;
  operatingHours.map((operatingHour) => {
    if (today.toLowerCase() === operatingHour.dayOfWeek.toLowerCase()) {
      operatingHour.timePeriods.map((timePeriod) => {
        const currentHours = (date.getHours() < 10 ? '0' : '') + date.getHours();
        const currentMinutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        const currentTime = getTimeAsNumberOfMinutes(`${currentHours}:${currentMinutes}`);
        const openingTime = getTimeAsNumberOfMinutes(`${timePeriod.start.hours}:${timePeriod.start.minutes}`);
        const closingTime = getTimeAsNumberOfMinutes(`${timePeriod.end.hours}:${timePeriod.end.minutes}`);
        if (currentTime >= openingTime && currentTime <= closingTime) {
          isOpen = true;
        }
      });
    }
  });
  return isOpen;
}

const formatServingHours = (servingHours) => {
  let convertOperatingHours = [];
  if (servingHours) {
    servingHours.map((servingHour) => {
      servingHour.timePeriods.map((timePeriod) => {
        convertOperatingHours.push({
          "day": capitalizeFirstLetter(servingHour.dayOfWeek),
          "startTime": `${formatTwoDigitsNumber(timePeriod.start.hours)}:${formatTwoDigitsNumber(timePeriod.start.minutes)}`,
          "endTime": `${formatTwoDigitsNumber(timePeriod.end.hours)}:${formatTwoDigitsNumber(timePeriod.end.minutes)}`
        });
      });
    });
  }
  return convertOperatingHours;
}

const formatTwoDigitsNumber = (number) => {
  if (number.toString().length == 1) {
    return ("0" + number).slice(-2);
  }
  return number;
}

const capitalizeFirstLetter = (string) => {
  const word = string.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const getVoucherData = (partnerId, brandId, menuCardId) => {
  return new Promise((resolve, reject) => {
    resolve(
      axios.get(appApiEndPoint + FETCH_PROMOTIONS, {
        params: {
          partnerId: partnerId,
          brandId: brandId,
          menuId: menuCardId,
        }
      })
    );
    reject(new Error());
  });
};

export const getNearestLocation = (locationCoordinates, history) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADER_ON });
    const { restaurant } = getState().menu;
    const { appParams, applicationType } = getState().app;

    axios.get(locationApiEndPoint + FETCH_NEAREST_LOCATION, {
      params: {
        "brandId": appParams.restaurantId,
        "latitude": locationCoordinates.lat,
        "longitude": locationCoordinates.lng
      }
    })
      .then(async res => {
        if (res.status === 200) {
          let locationData = res.data;
          if (locationData.locationId !== undefined) {
            const isExistLocation = restaurant.kitchenList.find((data) => data.locationId === locationData.locationId);

            if (isExistLocation !== undefined) {
              dispatch({
                type: FETCH_RESTAURANT_SUCCESS,
                payload: {
                  ...restaurant,
                  locationId: isExistLocation.locationId,
                  menuId: isExistLocation.menuId,
                  deliveryFee: isExistLocation.deliveryFee,
                  deliveryTimeDisplayText: isExistLocation.deliveryTimeDisplayText,
                  locationName: locationData.name
                }
              });

              let formatedServingHours = formatServingHours(isExistLocation.servingHours);
              dispatch({
                type: SET_OPERATING_HOURS,
                payload: formatedServingHours
              });

              if (isExistLocation.menuId && isExistLocation.locationId) {
                axios.get(appApiEndPoint + FETCH_APP_MENU + isExistLocation.menuId, {
                  params: {
                    "locationId": isExistLocation.locationId
                  }
                })
                  .then(async menusData => {
                    let menuData = convertMenu(menusData.data);
                    var dispatchObj = { ...appParams, currecyDescription: menusData.data.currencyCode }
                    dispatch({
                      type: FETCH_INITIAL_PARAMS_SUCCESSFUL,
                      payload: dispatchObj,
                    });

                    dispatch({
                      type: SELECTED_LOCATION_COORDINATES,
                      payload: locationCoordinates
                    });

                    await Promise.all([
                      getVoucherData(
                        appParams.partnerId,
                        appParams.restaurantId,
                        isExistLocation.menuId
                      ),
                    ])
                      .then((res) => {
                        let responseData = null
                        let pointer = res[0].data[0];
                        if (pointer !== undefined) {
                          if (res[0].data.length > 0) {
                            let siteWideInfo = {
                              ...pointer.reward,
                              promoId: pointer.id
                            }
                            dispatch({
                              type: SET_SITEWIDE_PROMO_DETAILS,
                              payload: siteWideInfo,
                            });

                            if (res[0].data.length > 0) {
                              let modifiedData = getPromoAddedMenuCardDetails(
                                menuData,
                                pointer,
                                findISOPrecisionByCurrencyCode(menusData.data.currencyCode)
                              );
                              responseData = modifiedData

                            } else {
                              responseData = menuData
                            }

                            dispatch({
                              type: FETCH_MENU_SUCCESS,
                              payload: responseData
                            });
                            dispatch({ type: SELECTED_LOCATION_INFO, payload: true });
                          } else {
                            dispatch({
                              type: FETCH_MENU_SUCCESS,
                              payload: menuData
                            });
                            dispatch({ type: SELECTED_LOCATION_INFO, payload: true });
                          }
                        } else {
                          dispatch({
                            type: FETCH_MENU_SUCCESS,
                            payload: menuData
                          });
                          dispatch({ type: SELECTED_LOCATION_INFO, payload: true });
                        }
                        history.push(ROOT + SEARCH);
                        dispatch({ type: SET_LOADER_OFF });
                      })
                      .catch((err) => {
                        dispatch({
                          type: FETCH_MENU_SUCCESS,
                          payload: menuData
                        });
                        dispatch({ type: SELECTED_LOCATION_INFO, payload: true });
                      });
                    history.push(ROOT + SEARCH);
                    dispatch({ type: SET_LOADER_OFF });
                  }).catch(error => {
                    dispatch({ type: SET_LOADER_OFF });
                  });
              }
            } else {
              toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: false });
              dispatch({ type: SET_LOADER_OFF });
            }
          } else {
            dispatch({
              type: FETCH_RESTAURANT_SUCCESS,
              payload: {
                ...restaurant,
                locationId: undefined,
                menuId: undefined
              }
            });
            dispatch({
              type: FETCH_MENU_SUCCESS,
              payload: []
            });
            toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: false });
            dispatch({ type: SET_LOADER_OFF });
          }
        }
      });
  }
}

export const fetchInitialApplicationParams = (history) => {
  return (dispatch, getState) => {

    dispatch({ type: SET_LOADER_ON });

    const { selectedLocationInfo, applicationType } = getState().app;
    const virtualWaiterConfiguration = getState().app.virtualWaiterConfiguration;

    let protocol = window.location.protocol;
    var slashes = protocol.concat("//");
    var host = slashes.concat(window.location.hostname);

    //host = "https://web-order.staging.grubtech.io";

    axios.get(appApiEndPoint + FETCH_APP_CONFIGURATION, {
      params: {
        "appUrl": host
      }
    })
      .then(async appConfigurationData => {
        const response = appConfigurationData.data;
        document.title = response.webAppName;
        //kitchen data
        const kitchenList = response.locationConfigurations;
        let kitchenData = {};
        if (applicationType === "ONLINE_ORDER") {
          if (kitchenList.length > 1) {
            const uniqueLocationsByMenuId = [...new Set(kitchenList.map(item => item.menuId))];
            if (uniqueLocationsByMenuId.length > 1) {
              if (!selectedLocationInfo) {
                history.push(DELIVERY_LOCATION_PICKER);
              }
            } else {
              kitchenData = kitchenList[0];
            }
          } else {
            kitchenData = kitchenList[0];
          }
        } else {
          const isExistLocation = kitchenList.find((data) => data.locationId === virtualWaiterConfiguration.kitchenId);
          if (isExistLocation !== undefined) {
            kitchenData = isExistLocation;
          } else {
            toast.error(`Configuration not found!`, {
              autoClose: false
            });
          }
        }

        //application data
        const dispatchObj = {
          partnerId: response.partnerId,
          restaurantName: response.webAppName,
          restaurantId: response.brandId,
          currecyDescription: "",
          currecyISOPrecision: 0,
          taxRate: 0,
          deliveryFee: kitchenData.deliveryFee,
          gtmKey: response.gtmKey,
          logo: response.mobileBanner,
          bgDesktop: response.desktopBanner
        };

        const tagManagerArgs = {
          gtmId: response.gtmKey,
        };

        TagManager.initialize(tagManagerArgs);

        const dispatchObjRestaurant = {
          menuId: kitchenData.menuId,
          locationId: kitchenData.locationId,
          deliveryFee: kitchenData.deliveryFee,
          deliveryTimeDisplayText: kitchenData.deliveryTimeDisplayText,
          location: {},
          kitchenList: kitchenList
        }

        dispatch({
          type: FETCH_INITIAL_PARAMS_SUCCESSFUL,
          payload: dispatchObj,
        });

        dispatch({
          type: SET_PAYMENT_METHODS,
          payload: response.paymentMethods
        });

        if (applicationType === "ONLINE_ORDER") {
          if (kitchenList.length > 1) {
            if (!selectedLocationInfo) {
              dispatch({
                type: FETCH_RESTAURANT_SUCCESS,
                payload: dispatchObjRestaurant
              });

              let formatedServingHours = formatServingHours(kitchenData.servingHours);
              dispatch({
                type: SET_OPERATING_HOURS,
                payload: formatedServingHours
              });

            }
          } else {
            dispatch({
              type: FETCH_RESTAURANT_SUCCESS,
              payload: dispatchObjRestaurant
            });

            let formatedServingHours = formatServingHours(kitchenData.servingHours);
            dispatch({
              type: SET_OPERATING_HOURS,
              payload: formatedServingHours
            });

          }
        } else {
          dispatch({
            type: FETCH_RESTAURANT_SUCCESS,
            payload: dispatchObjRestaurant
          });

          let formatedServingHours = formatServingHours(kitchenData.servingHours);
          dispatch({
            type: SET_OPERATING_HOURS,
            payload: formatedServingHours
          });

        }
        dispatch({ type: SET_LOADER_OFF });
        dispatch(fetchMenuItems());
      }).catch(error => {
        dispatch({ type: SET_LOADER_OFF });
        toast.error(`Oops! Something went wrong. Try Again`, {
          autoClose: false,
        });
      });
  };
};

export const fetchMenuItems = () => {
  return (dispatch, getState) => {
    const { appParams } = getState().app;
    const { restaurant } = getState().menu;

    if (restaurant.menuId && restaurant.locationId) {
      axios.get(appApiEndPoint + FETCH_APP_MENU + restaurant.menuId, {
        params: {
          "locationId": restaurant.locationId
        }
      })
        .then(async menusData => {
          let menuData = convertMenu(menusData.data);

          var dispatchObj = {
            ...appParams,
            currecyDescription: menusData.data.currencyCode,
            currecyISOPrecision: findISOPrecisionByCurrencyCode(menusData.data.currencyCode)
          }
          dispatch({
            type: FETCH_INITIAL_PARAMS_SUCCESSFUL,
            payload: dispatchObj,
          });

          await Promise.all([
            getVoucherData(
              appParams.partnerId,
              appParams.restaurantId,
              restaurant.menuId
            ),
          ])
            .then((res) => {

              let responseData = null
              let pointer = res[0].data[0];
              if (pointer !== undefined) {
                if (res[0].data.length > 0) {
                  let siteWideInfo = {
                    ...pointer.reward,
                    promoId: pointer.id
                  }
                  dispatch({
                    type: SET_SITEWIDE_PROMO_DETAILS,
                    payload: siteWideInfo,
                  });

                  if (res[0].data.length > 0) {
                    let modifiedData = getPromoAddedMenuCardDetails(
                      menuData,
                      pointer,
                      findISOPrecisionByCurrencyCode(menusData.data.currencyCode)
                    );
                    responseData = modifiedData

                  } else {
                    responseData = menuData
                  }

                  dispatch({
                    type: FETCH_MENU_SUCCESS,
                    payload: responseData
                  });

                } else {
                  dispatch({
                    type: FETCH_MENU_SUCCESS,
                    payload: menuData
                  });
                }
              } else {
                dispatch({
                  type: FETCH_MENU_SUCCESS,
                  payload: menuData
                });
              }
            })
            .catch((err) => {
              dispatch({
                type: FETCH_MENU_SUCCESS,
                payload: menuData
              });
            });
          dispatch({ type: SET_LOADER_OFF });
        }).catch(error => {
          dispatch({ type: SET_LOADER_OFF });
        });
    }
  }
}

const getPromoAddedMenuCardDetails = (menuCardData, promoData, currecyISOPrecision) => {
  let rewardType = promoData.reward.rewardType;
  let rewardValue = promoData.reward.value;
  let promoItemList = promoData.limitations.applicability.itemIds;
  let menuCategoryData = menuCardData;
  let hashedItemList = new Hashmap();

  promoItemList.forEach((promo) => {
    hashedItemList.set(promo, promo);
  });

  menuCategoryData.forEach((cat) => {
    let catMenuData = cat.categoryData.menuList;
    return catMenuData.forEach((menu) => {
      if (hashedItemList.has(menu.internalId)) {
        menu.recipeIds.forEach((recipe, key) => {
          let rewardedPrice = null;
          if (rewardType === "FIXED") {
            rewardedPrice = recipe.sellingPrice - rewardValue;
            if (rewardedPrice < 0) {
              rewardedPrice = 0
            }
          } else {
            rewardedPrice =
              (recipe.sellingPrice * (100 - rewardValue)) / 100;
            rewardedPrice = rewardedPrice.toFixed(currecyISOPrecision);
          }
          menu.recipeIds[key].originalPrice = menu.recipeIds[key].sellingPrice;
          menu.recipeIds[key].sellingPrice = parseFloat(rewardedPrice);
          menu.recipeIds[key].rewardType = rewardType;
          menu.recipeIds[key].rewardValue = rewardValue
        });
      }
    });
  });

  menuCardData = menuCategoryData;
  return menuCardData;
};

export const setSelectedCity = (city) => {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_LOCATION, payload: city.coordinates });
    dispatch({ type: SET_SELECTED_CITY, payload: city });
  };
};

export const setModalOn = () => {
  return (dispatch) => {

    dispatch({ type: SET_MODAL_ON, payload: null });
  };
};

export const setModalOff = () => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_OFF });
  };
};

export const setVoucheryEndPoint = (url) => {
  return dispatch => {
    dispatch({ type: SET_VOUCHERY_END_POINT, payload: url });
  };
}

export const setSideWidePromoDetails = (val) => {
  return dispatch => {
    dispatch({ type: SET_SITEWIDE_PROMO_DETAILS, payload: val });
  };
}

export const clearLocationSelectInfo = () => {
  return dispatch => {
    dispatch({ type: SELECTED_LOCATION_INFO, payload: false });
    dispatch({ type: SELECTED_LOCATION_COORDINATES, payload: {} });
  };
}
