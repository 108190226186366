import {
	SET_LOADER_OFF,
	SET_LOADER_ON,
	FETCH_INITIAL_PARAMS_SUCCESSFUL,
	SET_SELECTED_CITY,
	SET_MODAL_OFF,
	SET_MODAL_ON,
	SET_VOUCHERY_END_POINT,
	SET_SITEWIDE_PROMO_DETAILS,
	FETCH_DISTRICT,
	FETCH_SELECTED_DISTRICTS,
	FETCH_CURRENT_DISTRICT_CODE,
	FETCH_CURRENT_IP_ADDRESS,
	SET_APPLICATION_TYPE,
	VIRTUAL_WAITER_CONFIGURATIONS,
	SELECTED_LOCATION_INFO,
	SELECTED_LOCATION_COORDINATES
} from '../actionTypes';

const initState = {
	loading: false,
	marketingApi: "",
	siteWideDeatails: {},
	isModalShow: 'close',
	appParams: {
		cities: [],
		districts: [],
		currecyDescription: '',
		currencyId: '',
		masterTaxDetailsList: {},
		couponPrefix: '',
		gtmKey: ''
	}
};

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_LOADER_ON:
			return {
				...state,
				loading: true
			};
		case SET_LOADER_OFF:
			return {
				...state,
				loading: false
			};
		case SET_MODAL_ON:
			return {
				...state,
				restaurant: action.payload,
				isModalShow: 'show'
			};
		case SET_MODAL_OFF:
			return {
				...state,
				isModalShow: 'close'
			};
		case FETCH_INITIAL_PARAMS_SUCCESSFUL:
			return {
				...state,
				appParams: action.payload
			};
		case SET_SELECTED_CITY:
			return {
				...state,
				selectedCity: action.payload
			};
		case SET_VOUCHERY_END_POINT:
			return {
				...state,
				marketingApi: action.payload
			};
		case SET_SITEWIDE_PROMO_DETAILS:
			return {
				...state,
				siteWideDeatails: action.payload
			};
		case FETCH_DISTRICT:
			return {
				...state,
				districts: action.payload
			};
		case FETCH_SELECTED_DISTRICTS:
			return {
				...state,
				selectedDistricts: action.payload
			};
		case FETCH_CURRENT_DISTRICT_CODE:
			return {
				...state,
				currentDistrictCode: action.payload
			};
		case FETCH_CURRENT_IP_ADDRESS:
			return {
				...state,
				currentIpAddress: action.payload
			};
		case SET_APPLICATION_TYPE:
			return {
				...state,
				applicationType: action.payload
			};
		case VIRTUAL_WAITER_CONFIGURATIONS:
			return {
				...state,
				virtualWaiterConfiguration: action.payload
			};
		case SELECTED_LOCATION_INFO:
			return {
				...state,
				selectedLocationInfo: action.payload
			};
		case SELECTED_LOCATION_COORDINATES:
			return {
				...state,
				selectedLocationCoordinates: action.payload
			};
		default: {
			return state;
		}
	}
};

export default appReducer;
