import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ORDERS } from "../../utils/apiConstants";
const SHOW_MORE_THRESHOLD = 2;

const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;

class OrderSummary extends Component {
    state = {
        orderSummery: {},
        showMore: true,
        expandItemList: false,
        currecyPrecision: 0,
        currencyCode: ""
    };

    orderItemListClass = () => {
        const defaultClass = 'ls-order-item-list'
        if (this.state.expandItemList) return `${defaultClass} expanded`
        else return defaultClass;
    }

    componentDidMount() {
        this.setState({
            orderSummery: {}
        }, () => {
            const params = this.props.match.params
            let isOrderSummaryForOrderInternalId = Object.keys(params).length === 0 && params.constructor === Object
            if (!isOrderSummaryForOrderInternalId) {
                this.getOrderDetailsById(params.orderInternalId);
            }
        })
    }

    getOrderDetailsById = (orderId) => {
        this.setState({
            isLoading: false
        });
        axios.get(appApiEndPoint + ORDERS + "/" + orderId + "/order-summery")
            .then(res => {
                if (res.status === 200) {

                    let currecyPrecision = 0;
                    let currencyCode = res.data.menuItems[0].price.totalPrice.currencyCode ?? '';
                    if ("JOD" === currencyCode) {
                        currecyPrecision = 2;
                    } else {
                        const formatedValue = new Intl.NumberFormat('en-US',
                            {
                                style: 'currency',
                                currency: currencyCode,
                                useGrouping: false
                            }
                        ).format(0);

                        const splitedValue = formatedValue.split(".");
                        if (splitedValue.length > 1) {
                            currecyPrecision = splitedValue[splitedValue.length - 1].length;
                        } else {
                            currecyPrecision = 0;
                        }
                    }

                    this.setState({
                        orderSummery: res.data,
                        showMore: (res.data.menuItems.length > SHOW_MORE_THRESHOLD),
                        isLoading: true,
                        currecyPrecision: currecyPrecision,
                        currencyCode: currencyCode
                    });
                }
            })
            .catch(error => {
                this.setState({
                    orderSummery: {},
                    isLoading: true
                });
            });
    }

    getDoubleAmount = (amount, currecyPrecision) => {
        return parseFloat(amount / Math.pow(10, currecyPrecision));
    }

    getTotalPrice(data, currecyPrecision) {
        const itemOriginalPrices = data.quantity * data.price.unitPrice.amount;
        const modifiersOriginalPrice = data.modifiers.reduce((totalOriginalPrice, item) => totalOriginalPrice + item.price.unitPrice.amount * item.modifierQty, 0);
        const finalItemPrice = itemOriginalPrices + (data.quantity * modifiersOriginalPrice);;
        if (finalItemPrice !== undefined && finalItemPrice !== null && !Number.isNaN(finalItemPrice)) {
            return this.getDoubleAmount(finalItemPrice, currecyPrecision).toFixed(currecyPrecision);
        } else {
            return 0;
        }
    }

    render() {
        const {
            appParams
        } = this.props;
        const { orderSummery, currecyPrecision, currencyCode } = this.state;
        const { logo, bgDesktop } = appParams;
        const imgBackground = (this.props.ui.screenSize === "sm" ? logo : bgDesktop);

        return (
            <div className="App ls-app ls-order">
                <div className="ls-app-back" style={{ backgroundImage: "url(" + imgBackground + ")" }}>
                    <div className="ls-entry-backdrop"></div>
                </div>
                {this.state.isLoading && (
                    <>
                        {orderSummery.externalId !== undefined ? (
                            <div className="body">
                                <div className="modal-order-wrapper">
                                    <div className="ls-order-dialog-icon">
                                        <img src={require("../../assets/img/icon-check-alt.svg")} alt="check" />
                                    </div>
                                    <div className="ls-order-dialog-title">Order Successful</div>
                                    <div className="ls-order-dialog-content">
                                        Your order has approved. Here are the details of the transaction for
                                        your reference.
                                        <div className="ls-order-items-wrapper">
                                            <ul className={this.orderItemListClass()}>
                                                {orderSummery.menuItems.map((data) => {
                                                    return (
                                                        <div key={data.id} className="ls-order-row">
                                                            <div className="main-row">
                                                                <div className="ls-order-item-qty">
                                                                    <span>{`${data.quantity}x`}</span>
                                                                </div>
                                                                <div className="ls-order-item-details">
                                                                    <div className="ls-order-item-name">{data.name}</div>
                                                                    {data.modifiers.length > 0 && (
                                                                        <div className="ls-order-item-desc">
                                                                            {data.modifiers.map((i) => i.displayName).join()}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="ls-order-item-price">
                                                                    <div className="prices-wrapper">
                                                                        <div className="final-price">
                                                                            {currencyCode} {this.getTotalPrice(data, currecyPrecision)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </ul>
                                            {this.state.showMore &&
                                                <div className='show-more'
                                                    onClick={() => {
                                                        this.setState({
                                                            showMore: !this.state.showMore,
                                                            expandItemList: true
                                                        })
                                                    }}> show more </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="ls-order-dialog-additional">
                                        <p>
                                            Order Reference : <strong>{orderSummery.externalId}</strong>
                                        </p>
                                        {orderSummery.discount !== null && orderSummery.discount > 0 ? (
                                            <p>{`Discount: `}
                                                <span>
                                                    {currencyCode} {orderSummery.discount.toFixed(currecyPrecision)}
                                                </span>
                                            </p>
                                        ) : null}
                                        <p>{`Amount Paid: `}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {currencyCode} {orderSummery.netPrice.toFixed(currecyPrecision)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appParams: state.app.appParams,
        ui: state.ui
    };
};

export default connect(mapStateToProps)(OrderSummary);
