import React from 'react';

const getPath = (type) => {
	switch (type) {
		case 'left-arrow':
			return [
				'M15 8c-.004-3.864-3.136-6.996-7-7-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7zm-.7 0c0 3.48-2.82 6.3-6.3 6.3S1.7 11.48 1.7 8C1.704 4.522 4.522 1.704 8 1.7c3.48 0 6.3 2.82 6.3 6.3zm-4.906 2.518c.024-.123-.02-.25-.113-.332h0L6.78 8l2.5-2.186c.145-.128.16-.349.033-.494-.128-.146-.349-.16-.494-.034h0l-2.8 2.45c-.076.067-.12.163-.12.264s.044.197.12.264h0l2.8 2.45c.094.082.225.108.343.067.119-.04.206-.14.23-.263z'
			];
		case 'down-arrow':
			return [
				'M7 0C3.134 0 0 3.134 0 7s3.134 7 7 7 7-3.134 7-7c-.004-3.864-3.136-6.996-7-7zm0 13.3C3.52 13.3.7 10.48.7 7S3.52.7 7 .7s6.3 2.82 6.3 6.3c-.004 3.478-2.822 6.296-6.3 6.3z',
				'M9.186 5.72L7 8.218 4.814 5.72c-.083-.094-.21-.137-.332-.113-.122.025-.223.112-.263.23-.04.12-.015.25.067.344l2.45 2.8c.067.076.163.12.264.12s.197-.044.264-.12l2.45-2.8c.127-.145.112-.366-.034-.494-.145-.127-.366-.112-.494.033h0z'
			];
		case 'info':
			return [
				'M11,0 C4.92525,0 0,4.92525 0,11 C0,17.07475 4.92525,22 11,22 C17.07475,22 22,17.07475 22,11 C22,4.92525 17.07475,0 11,0 Z M11.9166667,16.5 L10.0833333,16.5 L10.0833333,10.0833333 L11.9166667,10.0833333 L11.9166667,16.5 Z M11,7.79166667 C10.241,7.79166667 9.625,7.17566667 9.625,6.41666667 C9.625,5.65766667 10.241,5.04166667 11,5.04166667 C11.759,5.04166667 12.375,5.65766667 12.375,6.41666667 C12.375,7.17566667 11.759,7.79166667 11,7.79166667 Z'
			];
		case 'map-pointer':
			return [
				'M9 0C4.032 0 0 4.102 0 9.167c0 2.53 1.007 4.82 2.637 6.48L9 22l6.365-6.354c1.63-1.658 2.637-3.95 2.635-6.481C18 4.105 13.971 0 9 0zm0 13.75c-2.486 0-4.5-2.052-4.5-4.583 0-2.532 2.014-4.584 4.5-4.584s4.5 2.052 4.5 4.584c0 2.531-2.014 4.583-4.5 4.583z'
			];
		case 'basket':
			return [
				`M24.286 8.47c.394 0 .714.317.714.707v3.529c0 .39-.32.706-.714.706h-1.227l-1.446 9.39C21.506 23.49 20.907 24 20.2 24H4.799c-.707 0-1.306-.51-1.413-1.199l-1.446-9.39H.714c-.395 0-.714-.315-.714-.705v-3.53c0-.389.32-.705.714-.705zm-6.786 6c-.592 0-1.071.475-1.071 1.06v4.588c0 .584.479 1.059 1.071 1.059.591 0 1.071-.475 1.071-1.06V15.53c0-.585-.48-1.06-1.071-1.06zm-5 0c-.592 0-1.071.475-1.071 1.06v4.588c0 .584.479 1.059 1.071 1.059.591 0 1.071-.475 1.071-1.06V15.53c0-.585-.48-1.06-1.071-1.06zm-5 0c-.592 0-1.071.475-1.071 1.06v4.588c0 .584.479 1.059 1.071 1.059.591 0 1.071-.475 1.071-1.06V15.53c0-.585-.48-1.06-1.071-1.06zM17.185 0c1.077 0 2.03.678 2.371 1.69l1.692 5.016h-2.259l-1.465-4.347c-.05-.144-.185-.241-.34-.241H7.817c-.155 0-.291.097-.34.241L6.01 6.706H3.75l1.693-5.017C5.784.68 6.738 0 7.816 0z`
			];
		case 'info-header':
			return [
				'M12.833 0H1.167C.525 0 0 .525 0 1.167v11.666C0 13.475.525 14 1.167 14h11.666c.642 0 1.167-.525 1.167-1.167V1.167C14 .525 13.475 0 12.833 0zM7 1.75c.644 0 1.167.523 1.167 1.167 0 .643-.523 1.166-1.167 1.166S5.833 3.56 5.833 2.917c0-.644.523-1.167 1.167-1.167zm2.333 10.5H4.667v-1.167h1.75V6.417h-1.75V5.25H7c.323 0 .583.26.583.583v5.25h1.75v1.167z'
			];
		case 'close-button':
			return [
				'M17.223 6.777c-.27-.27-.626-.406-.98-.406-.355 0-.71.135-.98.406l-3.264 3.262-3.261-3.262c-.271-.27-.626-.406-.98-.406-.355 0-.71.135-.98.406-.272.27-.407.626-.407.98 0 .355.135.71.406.98L10.04 12l-3.262 3.263c-.27.271-.406.626-.406.98 0 .355.135.71.406.98.27.272.626.407.98.407.355 0 .71-.135.98-.406L12 13.96l3.262 3.263c.271.27.626.406.98.406.355 0 .71-.135.98-.406.272-.27.407-.626.407-.98 0-.355-.135-.71-.406-.98l-3.263-3.264 3.263-3.261c.27-.271.406-.626.406-.98 0-.355-.135-.71-.406-.98z'
			];
		case 'maps':
			return [
				'M9.167 0C4.107 0 0 4.102 0 9.167c0 2.53 1.026 4.82 2.686 6.48L9.166 22l6.483-6.354c1.66-1.658 2.686-3.95 2.684-6.481C18.333 4.105 14.23 0 9.167 0zm0 13.75c-2.532 0-4.584-2.052-4.584-4.583 0-2.532 2.052-4.584 4.584-4.584 2.531 0 4.583 2.052 4.583 4.584 0 2.531-2.052 4.583-4.583 4.583z'
			];
		case 'bell':
			return [
				'M7.667 20.125c0 1.588 1.287 2.875 2.875 2.875s2.875-1.287 2.875-2.875h-5.75zM20.125 16.77c-1.059 0-1.916-.858-1.916-1.917V7.667C18.209 3.433 14.776 0 10.542 0S2.875 3.433 2.875 7.667v7.187c0 1.059-.858 1.917-1.916 1.917H0v1.916h21.084v-1.916h-.959zM14.375 6.47l-5.27 5.75h5.27v1.917H6.709v-1.917l5.27-5.75H6.71V4.552h7.666v1.917z'
			];
		case 'trash':
			return [
				'M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.324219 3.675781 14 4.5 14 L 10.5 14 C 11.324219 14 12 13.324219 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 4 4 L 11 4 L 11 12.5 C 11 12.78125 10.78125 13 10.5 13 L 4.5 13 C 4.21875 13 4 12.78125 4 12.5 Z M 5 5 L 5 12 L 6 12 L 6 5 Z M 7 5 L 7 12 L 8 12 L 8 5 Z M 9 5 L 9 12 L 10 12 L 10 5 Z'
			];
		default:
			return [];
	}
};

export const Svg = ({
	className,
	width = 16,
	height = 16,
	fill = '#FFF',
	stroke = '#FFF',
	strokeWidth = 0.5,
	type,
	viewBox,
	onClick
}) => {
	const paths = getPath(type);
	return (
		<svg
			className={className}
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={viewBox || '0 0 ' + width + ' ' + height}
		>
			{paths.map((path, index) => (
				<path key={index} fill={fill} stroke={stroke} strokeWidth={strokeWidth} d={path} />
			))}
		</svg>
	);
};
