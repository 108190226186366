import React from "react";

const Button = React.forwardRef((props, ref) => {
  return (
    <button ref={ref} {...props} className="gt-default-button">
      {props.children}
    </button>
  );
});

export default Button;
