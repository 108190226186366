import { combineReducers } from "redux";

import authReducer from "./authReducer";
import menuReducer from "./menuReducer";
import cartReducer from "./cartReducer";
import appReducer from "./appReducer";
import paymentReducer from "./paymentReducer";
import responsiveReducer from "./responsiveReducer";
import payReducer from "./payReducer"

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  cart: cartReducer,
  app: appReducer,
  payment: paymentReducer,
  ui: responsiveReducer,
  paymentDetails: payReducer
});

export default rootReducer;
