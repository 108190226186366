import React from 'react';

export const MiniQuantitySelector = (props) => {
	const { modifier: mod, itemCount, onRemove, onAdd } = props;

	return (
		<label className="ls-dj-mini-quantity-selector" htmlFor={`${mod.modifierId}:${mod.displayName}`}>
			<div className="ls-quantity-selector">
				<div className="ls-quantity-selector-counter">
					<div className="ls-quantity-qty-selector">
						<button
							type="button"
							className="ls-quantity-toggle--left"
							onClick={() => onRemove()}
						>
							-
						</button>
						<div className="amount">{itemCount}</div>
						<button type="button" className="ls-quantity-toggle--right" onClick={() => onAdd()}>
							+
						</button>
					</div>
				</div>
			</div>
		</label>
	);
};
