/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { connect } from "react-redux";
import AppHeader from "../../../components/AppHeader";
import { setUserAddress } from "../../../store/actions/authActions";
import {
  ROOT,
  VERIFICATION,
  LOCATION_SELECT,
  SELECT_ADDRESS,
  SEARCH,
} from "../../../utils/routeConstants";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Breadcrumb from "../../../components/Breadcrumb";
import { formStateManager } from "../../../utils/formState";
import getFinalizedMobileNumber from "../../../utils/mobileNumberValidation";

class NewAddress extends Component {
  state = {
    contactNumber: null,
    firstName: "",
    lastName: "",
    allValid: false,
  };

  focusOn = () => {
    let getCurrentErrorLabel = document.querySelectorAll(".validation-error");
    if (getCurrentErrorLabel.length > 0) {
      window.scrollTo(0, getCurrentErrorLabel[0].offsetTop);
    }
  };

  componentDidMount() {
    const { auth } = this.props;
    if (auth.user !== undefined) {
      if (auth.user.name !== undefined) {
        const customerName = auth.user.name.split(" ");
        this.setState({
          firstName: customerName[0],
          lastName: customerName[1],
          contactNumber: auth.mobileNumber
        });
      }
    }

    this.setState({
      contactNumber: getFinalizedMobileNumber(auth.mobileNumber),
    });

    formStateManager(
      this.refs.newAddressForm,
      {
        validated: (state, allValid) => {
          if (this.state.allValid !== allValid) {
            this.setState({
              allValid: allValid,
            });
          }
        },
      },
      [
        {
          name: "email",
          validation: [
            {
              rule: { type: "email" },
            },
          ],
        },
        ...[
          "label",
          "firstName",
          "lastName",
          "buildingName",
          "contactNumber",
          "address",
          "email",
        ].map((item) => ({
          name: item,
          validation: [
            {
              rule: { type: "required" },
            },
          ],
        })),
      ]
    );
  }

  renderBreadCrumb = () => {
    const items = [
      { url: ROOT + SEARCH, name: "Home Page" },
      { url: VERIFICATION + SEARCH, name: "Verification" },
      { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
      "New Address",
    ];

    return <Breadcrumb items={items} />;
  };

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { setUserData, history, location } = this.props;

    if (this.state.allValid) {
      setUserData(this.state);

      if (location.state && location.state.refFrom === "checkout") {
        history.push(LOCATION_SELECT, { refFrom: "checkout" });
      } else {
        history.push(LOCATION_SELECT);
      }
    } else {
      this.focusOn();
    }
  };

  render() {
    const { restaurantName, history } = this.props;
    const { contactNumber, firstName, lastName } = this.state;

    return (
      <React.Fragment>
        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              backButtonClick={() => history.goBack()}
              heading={"Address"}
              restaurantName={restaurantName}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>
        {this.renderBreadCrumb()}
        <div className="ls-checkout-body">
          <div className="ls-flows-wrapper">
            <div className="ls-flows-container ls-flows-container-scrolled">
              <div className="clm--100">
                <div className="ls-headerArea">
                  <p className="ls-title"> Add New Address </p>
                  <p className="ls-subtitle" />
                </div>
              </div>
              <div className="clm--50">
                <form ref="newAddressForm">
                  <div className="ls-field">
                    <input
                      name="label"
                      type="text"
                      maxLength="50"
                      placeholder="Home / Office"
                      onChange={this.handleOnChange}
                    />
                    <label htmlFor="label">Save This Address As *</label>
                  </div>
                  <div className="ls-field">
                    <input
                      name="firstName"
                      type="text"
                      maxLength="50"
                      ref={"firstName"}
                      value={firstName}
                      onChange={this.handleOnChange}
                      placeholder="First Name"
                    />
                    <label htmlFor="mob-num">First Name*</label>
                  </div>
                  <div className="ls-field">
                    <input
                      name="lastName"
                      type="text"
                      maxLength="50"
                      value={lastName}
                      onChange={this.handleOnChange}
                      placeholder="Last Name*"
                    />
                    <label htmlFor="mob-num">Last Name*</label>
                  </div>

                  <PhoneInput
                    name="contactNumber"
                    placeholder="Phone no*"
                    defaultCountry="AE"
                    value={contactNumber}
                    onChange={(phone) => {
                      this.setState({
                        contactNumber: phone,
                      });
                    }}
                    style={{
                      marginBottom: "10px",
                    }}
                  />
                  <div className="ls-field">
                    <input
                      name="email"
                      type="email"
                      maxLength="50"
                      onChange={this.handleOnChange}
                      placeholder="Email*"
                    />
                    <label htmlFor="mob-num">Email*</label>
                  </div>
                  <div className="ls-field">
                    <input
                      name="buildingName"
                      type="text"
                      maxLength="20"
                      onChange={this.handleOnChange}
                      placeholder="Building name/no., floor or villa no.*"
                    />
                    <label htmlFor="mob-num">
                      Building name/no., floor or villa no.*
                    </label>
                  </div>
                  <div className="ls-field">
                    <input
                      name="address"
                      type="text"
                      maxLength="255"
                      onChange={this.handleOnChange}
                      placeholder="Street Address*"
                    />
                    <label htmlFor="mob-num">Street Address*</label>
                  </div>
                  {/* <div className="ls-field">
                    <input
                      name="area"
                      type="text"
                      maxLength="50"
                      onChange={this.handleOnChange}
                      value={selectedDistrict}
                      placeholder="Area*"
                      disabled
                    />
                    <label htmlFor="mob-num">Area*</label>
                  </div> */}
                  <div className="ls-field">
                    <input
                      name="notes"
                      type="text"
                      maxLength="50"
                      onChange={this.handleOnChange}
                      placeholder="Delivery Instructions"
                    />
                    <label htmlFor="mob-num">Delivery Instructions</label>
                  </div>
                </form>
              </div>
              <div className="clm--50">
                <img
                  src={require("../../../assets/img/location.svg")}
                  className="img-right-side"
                  alt="Location"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ls-mobile-bottom-button-container">
          <button
            type="button"
            className={
              (this.state.allValid
                ? "ls-cart-button"
                : "ls-cart-button-disabled") + " center-text"
            }
            onClick={() => this.state.allValid && this.handleSubmit()}
          >
            Save
                    </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantName: state.app.appParams.restaurantName,
    cart: state.cart,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (obj) => dispatch(setUserAddress(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAddress);
