import React from "react";
import { Svg } from "../../components/Svg";
import { RESTAURANT_CLOSED } from "../../utils/appConstants";

const CartFooter = ({
  itemCount,
  totalPrice,
  onCartClick,
  currecyDescription,
  restaurantStatus,
  currecyISOPrecision
}) => {
  return restaurantStatus === RESTAURANT_CLOSED ? (
    <button
      disabled=""
      type="button"
      className="ls-cart-button-disabled closed-now-btn"
    >
      Restaurant Closed
    </button>
  ) : (
    <div className="footer-fixed show ls-card-footer">
      <button
        type="button"
        className="ls-cart-button dark-theme"
        onClick={onCartClick()}
      >
        <Svg type="basket" width="25" height="24" className="ls-icon-basket" />
        <div className="ls-basket-oval">{itemCount}</div>
        <span>View Basket</span>
        {totalPrice !== null ? (
          <span>{`${currecyDescription}  ${totalPrice.toFixed(currecyISOPrecision)}`}</span>
        ) : null}
      </button>
    </div>
  );
};

export default CartFooter;
