/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { connect } from "react-redux";

import Banner from "../../components/Banner";
import MenuCategory from "../../components/MenuCategory";
import MenuItemImage from "../../assets/img/menuitem1.png";
import MenuItem from "../../components/MenuItem";
import CartFooter from "../../components/CartFooter";
import Loader from "../../components/Loader";
import CategoryMenu from "../../components/MenuCategory/categoryMenu";
import { setCartEmpty } from "../../store/actions/cartActions";
import AppHeader from "../../components/AppHeader";
import {
  fetchRecipeData,
} from "../../store/actions/menuActions";

import {
  fetchInitialApplicationParams,
  setApplicationType
} from "../../store/actions/appActions";

import { SEARCH, DELIVERY_LOCATION_PICKER } from "../../utils/routeConstants";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import TagManager from "react-gtm-module";
import Basket from "../Basket";
import { Svg } from "../../components/Svg";
import { RESTAURANT_CLOSED } from "../../utils/appConstants";
import checkRestaurantOpen from "../../utils/checkRestaurantOpen";
import queryString from "query-string";

let isGTMSent = false;
class Home extends Component {
  state = {
    menuList: [],
    imagePopup: "",
    isOpen: false,
    displayTime: "hidden",
    selectLocation: {},
  };

  componentDidMount() {
    const {
      fetchInitialParams,
      fetchMenuItems,
      setApplicationType,
      history,
      location,
      restaurant
    } = this.props;

    const queryParams = location.search;
    const params = queryString.parse(queryParams);
    if (params.brandId && params.kitchenId && params.source) {
      const paramOB = {
        brandId: params.brandId,
        kitchenId: params.kitchenId,
        sourceName: params.source,
        sourceNumber: params.sourceNumber,
        orderSourceEdit: params.sourceNumber === undefined ? true : false
      }
      setApplicationType("VIRTUAL_WAITER", paramOB);
    } else {
      setApplicationType("ONLINE_ORDER", null);
    }
    fetchInitialParams(history);
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.props.menuItems.length && this.state.menuList.length === 0) {
      const menuItems = document.querySelectorAll(".ls-menu-items-header");
      const menuList = [];
      menuItems.forEach((item) => {
        menuList.push({
          title: item.textContent,
          dom: item,
        });
      });
      if (menuItems.length) {
        const parent = menuItems[menuItems.length - 1].parentElement;
        const dv = document.createElement("div");
        dv.style.height = 40 + global.screen.height / 2 + "px";
        parent.appendChild(dv);
      }

      window.requestAnimationFrame(() => {
        this.setState({ menuList });
      });
    }
  }

  handleCartOpen = () => {
    const { history } = this.props;
    history.push("/basket" + SEARCH);
  };

  onMenuItemSelect = (categoryId, menuData) => {
    const { history, fetchRecipeData } = this.props;
    if (menuData.recipeIds.length) {
      fetchRecipeData(categoryId, menuData, history);
    }
  };

  calculateMenuItemPrice = (recipes) => {
    let menuItemPrice = 0;
    recipes.forEach((rc) => {
      menuItemPrice += rc.sellingPrice;
    });
    return menuItemPrice;
  };

  calculateMenuItemPriceOrginal = (recipes, currecyISOPrecision) => {
    let menuItemPrice = 0;
    recipes.forEach((rc) => {
      if (rc.originalPrice !== undefined) {
        menuItemPrice += rc.originalPrice;
      }
    });
    return menuItemPrice.toFixed(currecyISOPrecision);
  };

  openImage = (imageCdn) => {
    this.setState({ isOpen: true, imagePopup: imageCdn });
  };

  toggleDisplayHours = () => {
    if (this.state.displayTime === "hidden") {
      this.setState({ displayTime: "visible" });
    } else {
      this.setState({ displayTime: "hidden" });
    }
  };

  sendGTMData = (data, restaurantName, appParams) => {
    if (!isGTMSent && data.length) {
      let index = 0;
      let gtmImpressions = [];

      data.forEach((item) => {
        item.categoryData &&
          item.categoryData.menuList &&
          item.categoryData.menuList.forEach((menu) => {
            gtmImpressions.push({
              id: menu.id,
              name: menu.name,
              price: menu.recipeIds[0].sellingPrice,
              brand: restaurantName,
              position: index,
              //location: selectedCity.label,
            });
            index++;
          });
      });

      const tagManagerArgs = {
        gtmId: appParams.gtmKey,
        dataLayer: {
          ecommerce: {
            currencyCode: appParams.currecyDescription,
            impressions: gtmImpressions,
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
      isGTMSent = true;
    }
  };

  changeLocation = () => {
    const { setCartEmpty, history } = this.props;
    setCartEmpty();
    history.push(DELIVERY_LOCATION_PICKER)
  }

  render() {
    const {
      cart,
      menuItems = [],
      isLoading,
      appParams,
      menuCardImage,
      operatingHours,
      siteWide,
      ui,
      applicationType,
      restaurant,
      selectedLocationInfo,
      restaurantName
    } = this.props;

    const { currecyDescription, currecyISOPrecision } = appParams;
    const { menuList, imagePopup, isOpen, displayTime } = this.state;

    let restaurantStatus = "";
    if (operatingHours) {
      restaurantStatus = checkRestaurantOpen(operatingHours);
    }

    if (applicationType === "ONLINE_ORDER") {
      this.sendGTMData(menuItems || [], restaurantName, appParams);
    }

    return (
      <React.Fragment>
        <div className="App ls-layout-home">
          {isLoading && <Loader />}
          <div className="ls-layout-content">
            <>
              {applicationType && applicationType === "ONLINE_ORDER" ? (
                <>
                  {restaurant.kitchenList && restaurant.kitchenList.length > 1 && selectedLocationInfo ? (
                    <AppHeader
                      isHeightChange={true}
                      selectedLocationInfo={selectedLocationInfo}
                      restaurantName={" "}
                      openModal={this.changeLocation}
                      cart={cart}
                      cartEnabled={false}
                      isShowBackButton={false}
                      backButtonClick={() => this.props.history.push(SEARCH)}
                    />
                  ) : null}
                </>
              ) : null}

              {restaurantStatus === RESTAURANT_CLOSED && (
                <div className="warning">
                  <Svg
                    className="bell"
                    type="bell"
                    width="22"
                    height="23"
                    fill="#fff"
                    stroke="#fff"
                  />
                  <span>Restaurant is closed now</span>
                </div>
              )}

              <Banner
                deliveryTimeDisplayText={restaurant.deliveryTimeDisplayText}
                image={menuCardImage}
                restaurantName={restaurantName}
                operatingHours={operatingHours}
                displayTime={displayTime}
                toggleDisplayHours={this.toggleDisplayHours}
                restaurantStatus={restaurantStatus}
                applicationType={applicationType}
              />

              {restaurant.locationId !== undefined && restaurant.menuId !== undefined ? (
                <>
                  {menuItems.length > 0 ?
                    (<CategoryMenu
                      selectedLocationInfo={selectedLocationInfo}
                      locationCount={restaurant.kitchenList !== undefined ? restaurant.kitchenList.length : 0}
                      menuList={menuList}
                      applicationType={applicationType} />
                    ) : null}

                  {menuItems.length > 0 &&
                    menuItems.map((menu) => {
                      const { categoryData } = menu;
                      if (
                        !categoryData ||
                        !menu.categoryData.menuList ||
                        !menu.categoryData.menuList.length
                      ) {
                        return null;
                      }
                      return (
                        <React.Fragment key={menu.internalId}>
                          <MenuCategory name={categoryData.name} description={categoryData.description} />
                          <div className="ls-menu-items">
                            {menu.categoryData.menuList.map((recipe) => {
                              return (
                                <MenuItem
                                  ui={ui}
                                  key={recipe.id}
                                  name={recipe.name}
                                  description={recipe.details}
                                  price={this.calculateMenuItemPrice(
                                    recipe.recipeIds
                                  )}
                                  orginalPrice={
                                    siteWide.rewardType !== undefined
                                      ? this.calculateMenuItemPriceOrginal(
                                        recipe.recipeIds,
                                        currecyISOPrecision
                                      )
                                      : null
                                  }
                                  currecyDescription={currecyDescription}
                                  currecyISOPrecision={currecyISOPrecision}
                                  openImage={this.openImage}
                                  image={
                                    recipe.images.length > 0
                                      ? recipe.images[0]
                                      : MenuItemImage
                                  }
                                  onItemClick={
                                    () =>
                                      this.onMenuItemSelect(categoryData.id, recipe)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                  isOpenCategory={categoryData.isOpenCategory}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagePopup}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                  )}
                </>
              ) :
                <Loader />}
            </>
          </div>
          {this.props.ui.screenSize === "lg" && (
            <div className="ls-layout-basket">
              <Basket showBasketHeader={true} />
            </div>
          )}
          {cart.itemCount > 0 && (
            <CartFooter
              itemCount={cart.itemCount}
              totalPrice={cart.totalPrice}
              onCartClick={() => this.handleCartOpen}
              currecyDescription={currecyDescription}
              currecyISOPrecision={currecyISOPrecision}
              restaurantStatus={restaurantStatus}
            />
          )}
        </div>
        {/* {this.props.ui.screenSize === 'lg' && <AppFooter />} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    cart: state.cart,
    menuItems: state.menu.menuItems,
    isLoading: state.app.loading,
    appParams: state.app.appParams,
    selectedCity: state.app.selectedCity,
    menuCardImage: state.app.appParams.logo,
    operatingHours: state.cart.operatingHours,
    ui: state.ui,
    siteWide: state.app.siteWideDeatails,
    applicationType: state.app.applicationType,
    selectedLocationInfo: state.app.selectedLocationInfo,
    restaurantName: state.app.appParams.restaurantName,
    currecyISOPrecision: state.app.currecyISOPrecision
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialParams: (history) => dispatch(fetchInitialApplicationParams(history)),
    setApplicationType: (type, params, history) => dispatch(setApplicationType(type, params, history)),
    fetchRecipeData: (categoryId, menuData, history) =>
      dispatch(fetchRecipeData(categoryId, menuData, history)),
    setCartEmpty: () => dispatch(setCartEmpty())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
