import React, { useState } from "react";
import CartItemSummary from "../../scenes/OrderComplete/CartItemSummary";

const SHOW_MORE_THRESHOLD = 2;

function OrderModal(props) {
  const { orderResponse, currecyDescription, cartItems, discount, totalPrice, currecyISOPrecision } = props;
  const [showMore, setShowMore] = useState(() => (cartItems.length > SHOW_MORE_THRESHOLD));

  const orderItemListClass = () => {
    const defaultClass = 'ls-order-item-list'

    if (showMore) return defaultClass
    else return `${defaultClass} expanded`;
  }

  return (
    <div className="modal-order-wrapper">

      <div className="ls-order-dialog-icon">
        <img src={require("../../assets/img/icon-check-alt.svg")} alt="check" />
      </div>
      <div className="ls-order-dialog-title">Order Successful</div>
      <div className="ls-order-dialog-content">
        Your order has approved. Here are the details of the transaction for
        your reference.
        <div className="ls-order-items-wrapper">
          <ul className={orderItemListClass()}>
            {cartItems.map((ci) => {
              const {
                extraItemPrice,
                extraItems,
                item,
                itemCount,
                id,
              } = ci;
              return (
                <CartItemSummary
                  cartItem={ci}
                  key={id}
                  itemCount={itemCount}
                  item={item}
                  name={item.name}
                  currecyDescription={currecyDescription}
                  currecyISOPrecision={currecyISOPrecision}
                  price={item.price}
                  originalPrice={item.originalPrice}
                  extraItems={extraItems}
                  extraItemPrice={extraItemPrice}
                />
              );
            })}
          </ul>
          {showMore && <div className='show-more' onClick={() => setShowMore(!showMore)}>show more</div>}
        </div>
      </div>
      <div className="ls-order-dialog-additional">
        <p>
          Order Reference : <strong>{orderResponse.externalId}</strong>
        </p>
        {discount > 0 && (
          <p>{`Discount Amount: `}
            <span>
              {currecyDescription} {discount.toFixed(currecyISOPrecision)}
            </span>
          </p>
        )}
        <p>{`Amount Paid: `}
          <span style={{ fontWeight: 'bold' }}>
            {currecyDescription} {totalPrice.toFixed(currecyISOPrecision)}
          </span>
        </p>
      </div>
    </div>
  );
}

export default OrderModal;
