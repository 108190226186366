import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import classNames from "classnames";
import { toast } from "../../../components/Toast";
import AppHeader from "../../../components/AppHeader";
import {
  ROOT,
  VERIFICATION,
  SEARCH,
  NEW_ADDRESS,
  SELECT_PAYMENT,
  CHECKOUT
} from "../../../utils/routeConstants";
import {
  setSelectedLocation,
  setSelectDeliveryDetails,
  setLocationCoordinates
} from "../../../store/actions/authActions";
import { updateSelectedLocation } from "../../../store/actions/menuActions";
import { setDeliveryLocation } from "../../../store/actions/cartActions";
import {
  setPaymentMethods,
  setPaymentStatus,
  setPaymentMethod,
  getPaymentGateWayConfiguration
} from "../../../store/actions/paymentActions";
import { FETCH_NEAREST_LOCATION } from "../../../utils/apiConstants";
import { PAYMENT, PAYMENT_STATUS } from "../../../utils/enums";

import isEmpty from "../../../utils/isEmpty";
import Breadcrumb from "../../../components/Breadcrumb";

const locationApiEndPoint = process.env.REACT_APP_LOCATION_API_URL;

class SelectAddress extends Component {
  state = {
    selectedAddress: {},
  };

  componentDidMount() {
    const { setPaymentTypes, paymentDetails, setLocationCoordinates } = this.props;
    setLocationCoordinates();
    setPaymentTypes(paymentDetails.paymentMethods);
    document.querySelector(".body").scrollTo(0, 0);
  }

  handleAddressSelection = (address) => {
    if (!address.id) {
      this.handleNewAddress();
    } else {
      this.setState({ selectedAddress: address }, () => {
        this.handleProceed();
      });
    }
  };

  handleNewAddress = () => {
    const { history, location } = this.props;
    if (location.state && location.state.refFrom === "checkout") {
      history.push(NEW_ADDRESS + SEARCH, { refFrom: "checkout" });
    } else {
      history.push(NEW_ADDRESS + SEARCH);
    }
  };

  handleProceed = () => {
    const {
      history,
      setSelectedLocation,
      setDeliveryPosition,
      setSelectDeliveryDetails,
      setStatus,
      setPayment,
      paymentDetails,
      restaurantId,
      restaurant,
      getPaymentGateWayConfiguration,
      updateSelectedLocation,
      cart
    } = this.props;

    const { selectedAddress } = this.state;
    if (isEmpty(selectedAddress)) {
      toast.warn("Please select a address from the list or add a new one !");
    } else {

      axios.get(locationApiEndPoint + FETCH_NEAREST_LOCATION, {
        params: {
          "brandId": restaurantId,
          "latitude": selectedAddress.latitude,
          "longitude": selectedAddress.longitude
        }
      })
        .then(res => {
          if (res.status === 200) {
            let locationData = res.data;
            if (locationData.locationId !== undefined) {

              if (restaurant.kitchenList.length > 1) {

                const uniqueLocationsByMenuId = [...new Set(restaurant.kitchenList.map(item => item.menuId))];
                if (uniqueLocationsByMenuId.length > 1) {
                  const isExistLocation = restaurant.locationId === locationData.locationId;
                  if (isExistLocation) {
                    setSelectedLocation(selectedAddress.deliveryAddress);
                    setDeliveryPosition({
                      lat: selectedAddress.latitude,
                      lng: selectedAddress.longitude
                    });

                    setSelectDeliveryDetails(selectedAddress);
                    if (cart.totalPrice === 0) {
                      setStatus(PAYMENT_STATUS.FOC);
                      setPayment(PAYMENT.FOC);
                      history.push(CHECKOUT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length > 1) {
                        history.push(SELECT_PAYMENT + SEARCH);
                      } else {
                        if (paymentDetails.paymentMethods.length === 0) {
                          toast.error("Payment methods not cofigured!", { autoClose: true })
                        } else if (paymentDetails.paymentMethods.length === 1) {
                          if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                            setStatus(PAYMENT_STATUS.PREPAID);
                            setPayment(PAYMENT.PREPAID);
                            getPaymentGateWayConfiguration(history);
                          } else {
                            history.push(SELECT_PAYMENT + SEARCH);
                          }
                        }
                      }
                    }
                  } else {
                    toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                  }
                } else {
                  const isExistLocation = restaurant.kitchenList.find((data) => data.locationId === locationData.locationId);
                  if (isExistLocation !== undefined) {
                    setSelectedLocation(selectedAddress.deliveryAddress);
                    setDeliveryPosition({
                      lat: selectedAddress.latitude,
                      lng: selectedAddress.longitude,
                    });
                    setSelectDeliveryDetails(selectedAddress);
                    updateSelectedLocation(locationData.locationId);
                    if (cart.totalPrice === 0) {
                      setStatus(PAYMENT_STATUS.FOC);
                      setPayment(PAYMENT.FOC);
                      history.push(CHECKOUT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length > 1) {
                        history.push(SELECT_PAYMENT + SEARCH);
                      } else {
                        if (paymentDetails.paymentMethods.length === 0) {
                          toast.error("Payment methods not cofigured!", { autoClose: true })
                        } else if (paymentDetails.paymentMethods.length === 1) {
                          if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                            setStatus(PAYMENT_STATUS.PREPAID);
                            setPayment(PAYMENT.PREPAID);
                            getPaymentGateWayConfiguration(history);
                          } else {
                            history.push(SELECT_PAYMENT + SEARCH);
                          }
                        }
                      }
                    }
                  } else {
                    toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                  }
                }

              } else {
                const isExistLocation = restaurant.locationId === locationData.locationId;
                if (isExistLocation) {
                  setSelectedLocation(selectedAddress.deliveryAddress);
                  setDeliveryPosition({
                    lat: selectedAddress.latitude,
                    lng: selectedAddress.longitude,
                  });
                  setSelectDeliveryDetails(selectedAddress);
                  if (cart.totalPrice === 0) {
                    setStatus(PAYMENT_STATUS.FOC);
                    setPayment(PAYMENT.FOC);
                    history.push(CHECKOUT + SEARCH);
                  } else {
                    if (paymentDetails.paymentMethods.length > 1) {
                      history.push(SELECT_PAYMENT + SEARCH);
                    } else {
                      if (paymentDetails.paymentMethods.length === 0) {
                        toast.error("Payment methods not cofigured!", { autoClose: true })
                      } else if (paymentDetails.paymentMethods.length === 1) {
                        if (paymentDetails.paymentMethods[0] === PAYMENT.PREPAID) {
                          setStatus(PAYMENT_STATUS.PREPAID);
                          setPayment(PAYMENT.PREPAID);
                          getPaymentGateWayConfiguration(history);
                        } else {
                          history.push(SELECT_PAYMENT + SEARCH);
                        }
                      }
                    }
                  }
                } else {
                  toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
                }

              }
            } else {
              toast.error("Sorry! There is no kitchen online to serve your location.", { autoClose: true });
            }
          }
        }).catch((error) => {
          toast.error("Something went wrong!!!", { autoClose: true });
        });
    }
  };

  renderBreadCrumb = () => {
    const items = [
      { url: ROOT + SEARCH, name: "Home Page" },
      { url: VERIFICATION + SEARCH, name: "Verification" },
      "Delivery Address",
    ];

    return <Breadcrumb items={items} />;
  };

  render() {
    const { restaurant, history, restaurantName } = this.props;
    const { selectedAddress } = this.state;

    const previousLocations = [...this.props.previousLocations];
    previousLocations.push({
      id: 0,
      buttonText: "Add New Address",
    });

    return (
      <React.Fragment>
        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              backButtonClick={() => history.goBack()}
              heading={"Address"}
              restaurantName={restaurantName}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>
        {this.renderBreadCrumb()}
        <div className="body ls-checkout-body ls-screens-address">
          <div className="ls-checkout-section">
            <div className="ls-flows-wrapper">
              <div className="ls-flows-container ls-flows-container-scrolled">
                <div className="ls-headerArea">
                  <p className="ls-title"> Delivery Address </p>
                </div>
                <div className="ls-address-boxes">
                  {previousLocations.map((ad, index) => {
                    const isSelected = classNames({
                      "ls-selected": selectedAddress.id === ad.id,
                    });

                    const addressLine1 =
                      (ad.address || "") +
                      "  " +
                      (ad.buildingName || "");
                    const addressLine2 = ad.notes;
                    const arrayLength = previousLocations.length;
                    return (
                      <div
                        key={index}
                        role="presentation"
                        className={`ls-address-box ${isSelected}`}
                        onClick={() => this.handleAddressSelection(ad)}
                      >
                        <div className={`ls-address-item`}>
                          {arrayLength !== index + 1 ? (
                            <>
                              <div className="ls-address-box-title">
                                {ad.label}
                              </div>
                              <div className="ls-address-box-subtitle">
                                {addressLine1}
                                <br />
                                {addressLine2}
                                <br />
                              </div>
                            </>
                          ) : null}

                          <button type="button" className="ls-button-small">
                            {ad.buttonText || "Deliver Here"}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* <AppFooter /> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantName: state.app.appParams.restaurantName,
    restaurant: state.menu.restaurant,
    cart: state.cart,
    previousLocations: state.auth.previousLocations,
    paymentDetails: state.paymentDetails,
    restaurantId: state.app.appParams.restaurantId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLocation: (location) => dispatch(setSelectedLocation(location)),
    setSelectDeliveryDetails: (addressDetails) =>
      dispatch(setSelectDeliveryDetails(addressDetails)),
    setDeliveryPosition: (coords) => dispatch(setDeliveryLocation(coords)),
    setPaymentTypes: (types) => dispatch(setPaymentMethods(types)),
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    setStatus: (status) => dispatch(setPaymentStatus(status)),
    setLocationCoordinates: (history, ref) =>
      dispatch(setLocationCoordinates(history, ref)),
    getPaymentGateWayConfiguration: (history) =>
      dispatch(getPaymentGateWayConfiguration(history)),
    updateSelectedLocation: (locationId) => dispatch(updateSelectedLocation(locationId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddress);
