import {
  LOGIN_USER_SUCCESSFUL,
  SET_CUSTOMER_DETAILS,
  SET_SELECTED_LOCATION,
  SET_PREVIOUS_LOCATIONS,
  SET_MOBILE_NUMBER,
  SET_MOBILE_NUMBER_REMEMBER
} from "../actionTypes";

const initState = {
  loggedIn: false,
  previousLocations: []
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESSFUL:
      return {
        ...state,
        loggedIn: true,
        user: action.payload
      };
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        user: action.payload
      };
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload
      };
    case SET_PREVIOUS_LOCATIONS:
      return {
        ...state,
        previousLocations: action.payload
      };
    case SET_MOBILE_NUMBER:
      return {
        ...state,
        mobileNumber: action.payload
      };
      case SET_MOBILE_NUMBER_REMEMBER:
        return {
          ...state,
          rememberMobileNumbers: action.payload
        };
    default:
      return state;
  }
};

export default authReducer;
