import React from "react";
import ReactDOM from "react-dom";
import "scroll-behavior-polyfill";

import "./index.scss";
import "./styles/theme.scss";
import WithProvider from "./WithProvider";
import * as serviceWorker from "./serviceWorker";
import { initMainContentScroll } from "../src/utils/scroll";

ReactDOM.render(<WithProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

initMainContentScroll();
