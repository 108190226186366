import axios from "axios";
import uuid from "uuid";
import { toast } from "../../components/Toast";
import { PAYFORT_3DS_STATUS, PAYMENT_STATUS } from "../../utils/enums";
import { ORDER, ROOT } from "../../utils/routeConstants";
import { FETCH_PROMOTIONS, ORDERS } from "../../utils/apiConstants";

import {
  ADD_CUSTOM_NOTE_TO_CART,
  ADD_ITEM_TO_CART,
  APPLY_PROMO_DISCOUNT,
  FETCH_CART_RESPONSE_SUCCESS,
  FETCH_IF_EXISTING_ITEM,
  FETCH_MERCHANT_REFERENCE,
  FETCH_ORDER_RESPONSE_FAILED,
  FETCH_ORDER_RESPONSE_SUCCESS,
  REMOVE_ITEM_FROM_CART,
  REMOVE_PROMO_DISCOUNT,
  REMOVE_SITEWIDE_PROMO_RESPONSE,
  SET_CART_EMPTY,
  SET_DELIVERY_LOCATION,
  SET_LOADER_OFF,
  SET_LOADER_ON,
  SET_SITEWIDE_PROMO_RESPONSE,
  UPDATE_EXISTING_CART_ITEM
} from "../actionTypes";

const cartResponse = {
  prepareTime: "",
  deliverTime: "",
  taxVat: "12",
  deliveryFee: 0,
  distance: "5.00 km",
};

const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;
const marketingApi = process.env.REACT_APP_MARKETING_API_URL;

// const calculateCartTotal = (cart, menuItem) => {
//   const { item } = menuItem;
//   const currentTotal = cart.totalPrice;
//   const itemsPrice = menuItem.itemCount * item.price;
//   const total = currentTotal + itemsPrice + menuItem.extraItemPrice;
//   return total;
// };

// use this to get the discounted values from the API only. Always send the original price to the API.
const calculateTotalPriceForExtraItem = (extraItem) => {
  let extraItemPrice = 0;
  extraItem.forEach(extra => {
    const count = extra.quantity;
    const isSiteWide =
      extra.originalPrice !== undefined && extra.originalPrice !== null && !Number.isNaN(extra.originalPrice);

    if (isSiteWide) {
      extraItemPrice += extra.originalPrice * count;
    } else {
      extraItemPrice += extra.price * count;
    }
  });

  return extraItemPrice;
};

// use this to get the discounted values from the API only. Always send the original price to the API.
const calculateTotalPriceOfAnItem = cartItem => {

  const isSiteWide =
    cartItem.item.originalPrice !== undefined && cartItem.item.originalPrice !== null &&
    !Number.isNaN(cartItem.item.originalPrice);
  let itemsPrice = 0;
  let extraItemPrice = 0;
  if (isSiteWide) {
    itemsPrice = cartItem.itemCount * cartItem.item.originalPrice;
  } else {
    itemsPrice = cartItem.itemCount * cartItem.item.price;
  }



  extraItemPrice = calculateTotalPriceForExtraItem(
    cartItem.extraItems,
  ) * cartItem.itemCount;

  return itemsPrice + extraItemPrice;
};

// check whether and item is discounted or not.
const isDiscounted = (cartItem, discountedItems) => {
  let discounted = false;
  discountedItems.forEach(item => {
    if (item.id === cartItem.item.internalId) {
      discounted = true;
    }
  });

  return discounted;
};

// use this to get sub total
const calculateSubTotalForItemOnAddPromo = (cartItem, discounted) => {
  let totalPrice = 0;
  if (discounted) {
    const isSiteWide =
      cartItem.item.originalPrice !== undefined && cartItem.item.originalPrice !== null &&
      !Number.isNaN(cartItem.item.originalPrice);

    if (isSiteWide) {
      totalPrice = calculateTotalPriceOfAnItem(cartItem);
    } else {
      const itemsPrice = cartItem.itemCount * cartItem.item.price;
      totalPrice = itemsPrice + cartItem.extraItemPrice;
    }
  } else {
    const itemsPrice = cartItem.itemCount * cartItem.item.price;
    totalPrice = itemsPrice + cartItem.extraItemPrice;
  }

  return totalPrice;
};

// use this to get sub total
const calculateSubTotalOnAddPromo = (cartItems, discountedItems) => {
  let totalPrice = 0;
  cartItems.forEach(ci => {
    const discounted = isDiscounted(ci, discountedItems);
    // eslint-disable-next-line no-param-reassign
    ci.discounted = discounted;
    totalPrice += calculateSubTotalForItemOnAddPromo(ci, discounted);
  });

  // retun total price and updated cart items
  return { subTotal: totalPrice, itemArr: cartItems };
};

export const cartItemPrice = (cartItems, discounted) => {
  let totalPrice = 0;
  // eslint-disable-next-line no-param-reassign
  totalPrice += calculateSubTotalForItemOnAddPromo(cartItems, discounted);

  // retun total price and updated cart items
  return totalPrice;
};

// // use this to get sub total
// const getUpdatedCartTotal = (cartItems) => {
//   let totalPrice = 0;
//   cartItems.forEach(ci => {
//     totalPrice += calculateTotalPriceOfAnItem(ci);
//   });

//   // retun total price and updated cart items
//   return totalPrice;
// };

const calculateTotalPriceOfAnItemOnRemove = (cartItem) => {

  let totalPrice = 0
  const itemsPrice = cartItem.itemCount * cartItem.item.price;
  totalPrice = itemsPrice + cartItem.extraItemPrice;

  return totalPrice;
  // if(discounted !== undefined ){
  //   if(discounted && isSiteWide){
  //     // there is a discount and remove it and apply siteWide
  //     totalPrice = 
  //   }else{
  //     // no discount treat as a sitewide
  //   }
  // }else{
  //   if(isSiteWide){
  //     // treat it like sitewide
  //   }else{
  //     //normal calculation
  //   }
  // }
}
// use this to get sub total
const calculateSubTotalOnRemovePromo = (cartItems) => {
  let totalPrice = 0;
  cartItems.forEach(ci => {
    totalPrice += calculateTotalPriceOfAnItemOnRemove(ci);
    ci.discounted = false;
  });

  // retun total price and updated cart items
  return { subTotal: totalPrice, itemArr: cartItems };
};

export const removePromoDiscount = () => {
  return (dispatch, getState) => {
    const { cart } = getState();
    const { cartItems, taxRate } = cart;
    const { restaurant } = getState().menu;
    // returns sub total and new cart items to be updated.
    const { subTotal, itemArr } = calculateSubTotalOnRemovePromo(cartItems);
    const totalPrice = subTotal + (subTotal * taxRate) / 100 + restaurant.deliveryFee;

    dispatch({
      type: REMOVE_PROMO_DISCOUNT,
      payload: {
        subTotal,
        cartItems: itemArr,
        discount: 0,
        totalPrice,
        voucherCode: "",
      },
    });

    dispatch(applySiteWidePromo());
  };
};

function getItems(cartItems) {
  const voucherItems = [];
  cartItems.forEach(ci => {
    const item = {
      id: ci.item.internalId,
      price: calculateTotalPriceOfAnItem(ci)
    }
    voucherItems.push(item);
  });

  return voucherItems;
}

const getPromoDetails = async (appParams, voucher, cartItems, slient, url) => {
  const response = await fetch(`${url}/vouchers/redemptions`, {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      voucherCode: voucher,
      partnerId: appParams.partnerId,
      brandId: appParams.restaurantId,
      items: getItems(cartItems)
    })
  });

  const respData = await response.json();
  if (respData.errorCode || respData.error) {
    toast.error(respData.errorDescription, { autoClose: false });
    return { discount: null };
  } else {
    if (!slient) {
      toast.success("Promo Code Applied", { autoClose: true });
    }
    const redemptionId = respData.id;
    return {
      discount: respData.totalDiscount,
      redemptionId,
      discountedItems: respData.discountedItems
    };
  }
};

export const applySiteWidePromo = () => {
  // send the api call and if success OK if not recalculate values and show notification
  return async (dispatch, getState) => {
    const { app, cart } = getState();
    const { cartItems } = cart;
    const currecyISOPrecision = app.appParams.currecyISOPrecision;
    let itemData = [];
    let orginalPrice = 0;


    cartItems.forEach((item) => {
      if (
        item.discounted === undefined ||
        (item.discounted !== undefined && !item.discounted)
      ) {
        let price = 0;
        let discount = 0;

        item.extraItems.forEach((mod) => {
          if (mod.originalPrice !== null) {
            if (isNaN(mod.originalPrice)) {
              price += mod.price * mod.quantity;
              orginalPrice += mod.price * mod.quantity * item.itemCount;
            } else {
              price += mod.originalPrice * mod.quantity;
              discount += (mod.originalPrice - mod.price) * mod.quantity;
              orginalPrice += mod.originalPrice * item.itemCount * mod.quantity;
            }
          } else {
            price += mod.price * mod.quantity;
            orginalPrice += mod.price * mod.quantity * item.itemCount;
          }
        });

        if (item.item.originalPrice !== null) {
          if (isNaN(item.item.originalPrice)) {
            price += item.item.price;
            discount += item.item.price - item.item.price;
            orginalPrice += item.item.price * item.itemCount;
          } else {
            price += item.item.originalPrice;
            discount += item.item.originalPrice - item.item.price;
            orginalPrice += item.item.originalPrice * item.itemCount;
          }
        } else {
          price += item.item.price;
          discount += item.item.price - item.item.price;
          orginalPrice += item.item.price * item.itemCount;
        }

        let itemDataObj = {
          id: item.item.internalId,
          promotionId: app.siteWideDeatails.promoId,
          price: price.toFixed(currecyISOPrecision) * item.itemCount,
          discount: discount.toFixed(currecyISOPrecision) * item.itemCount,
        };

        if (discount > 0) {
          itemData.push(itemDataObj);
        }
      }
    });

    let promoBody = {
      partnerId: app.appParams.partnerId,
      brandId: app.appParams.restaurantId,
      subTotal: orginalPrice,
      items: itemData,
    };


    if (promoBody.items.length > 0) {
      await axios.post(marketingApi + FETCH_PROMOTIONS + "/redemptions", promoBody)
        .then(res => {
          if (res.status === 200) {
            let promoDetails = {
              redemptionId: res.data[0].id,
              totalDiscount: res.data[0].totalDiscount
            }
            dispatch({ type: SET_SITEWIDE_PROMO_RESPONSE, payload: promoDetails });
          }
        }).catch((error) => {
          console.log("sitewide promo error", error)
        })
    } else {
      dispatch({ type: SET_SITEWIDE_PROMO_RESPONSE, payload: null });
    }

  };
};

export const applyPromoDiscount = (voucher, slient) => {
  return async (dispatch, getState) => {
    const { cart, app } = getState();
    const { cartItems, taxRate } = cart;
    const { restaurant } = getState().menu;
    try {
      const promoDiscount = await getPromoDetails(
        app.appParams,
        voucher,
        cartItems,
        slient,
        marketingApi
      );
      const { subTotal, itemArr } = calculateSubTotalOnAddPromo(
        cartItems,
        promoDiscount.discountedItems !== undefined ? promoDiscount.discountedItems : []
      );
      const tax = subTotal * (taxRate / 100);
      const totalForVoucher = tax + subTotal;
      const totalPrice = totalForVoucher - promoDiscount.discount + restaurant.deliveryFee;
      dispatch({
        type: APPLY_PROMO_DISCOUNT,
        payload: {
          cartItems: itemArr,
          subTotal,
          discount:
            promoDiscount.discount === null ? 0 : promoDiscount.discount,
          totalPrice,
          uniqueID: promoDiscount.redemptionId,
          voucherCode: promoDiscount.discount === null ? "" : voucher,
        }
      });

      dispatch(applySiteWidePromo());
    } catch (e) {
      // console.log(e);
    }
  };
};

export const addItemToCart = (menuItem) => {
  return (dispatch, getState) => {
    const { restaurant } = getState().menu;
    const randString = uuid.v4();
    const newMenuItem = { ...menuItem };
    const { cart } = getState();
    const { item } = menuItem;
    const currentSubTotal = cart.subTotal;
    const itemsPrice = menuItem.itemCount * item.price;
    const subTotal = currentSubTotal + itemsPrice + menuItem.extraItemPrice;
    const tax = subTotal * (cart.taxRate / 100);
    newMenuItem.id = randString;
    // calculate tax and apply it
    const total = subTotal + restaurant.deliveryFee + tax;

    // const itemTotal = item.
    dispatch({
      type: ADD_ITEM_TO_CART,
      payload: {
        id: randString,
        menuItem: newMenuItem,
        total,
        subTotal,
        tax,
      },
    });
  };
};

export const fetchCartResponseData = () => {
  // generating and setting a uuid for cart transaction
  return (dispatch) => {
    dispatch({ type: FETCH_CART_RESPONSE_SUCCESS, payload: cartResponse });
  };
};

export const checkIfExistingCartItem = (menuId) => {
  return (dispatch, getState) => {
    const { cartItems } = getState().cart;

    const selectedItem = cartItems.find((i) => i.id === menuId);

    if (selectedItem) {
      dispatch({
        type: FETCH_IF_EXISTING_ITEM,
        payload: {
          existing: true,
          extraItemPrice: selectedItem.extraItemPrice,
          extraItems: selectedItem.extraItems,
        },
      });
    }
  };
};

const calculateTotalPriceForExtraItemOnUpdate = extraItem => {
  // if discounted and sitewide calculate the orginal price
  let extraItemPrice = 0;
  extraItem.forEach(extra => {
    const count = extra.quantity;
    extraItemPrice += extra.price * count;
  });

  return extraItemPrice;
};

const calculateTotalPriceOfAnItemOnUpdate = cartItem => {
  // if discounted and sitewide calculate the orginal price
  let itemsPrice = 0;
  let extraItemPrice = 0;
  itemsPrice = cartItem.itemCount * cartItem.item.price;
  extraItemPrice =
    calculateTotalPriceForExtraItemOnUpdate(cartItem.extraItems) *
    cartItem.itemCount;

  return itemsPrice + extraItemPrice;
};

const getCartTotalOnUpdate = cartItems => {
  let totalPrice = 0;
  cartItems.forEach(ci => {
    totalPrice += calculateTotalPriceOfAnItemOnUpdate(ci);
  });

  // retun total price and updated cart items
  return totalPrice;
};

export const updateExistingCartItem = (menuItem) => {
  return (dispatch, getState) => {
    const { restaurant } = getState().menu;
    const { cartItems, taxRate } = getState().cart;
    let updatedCartItems = cartItems.filter((i) => i.id !== menuItem.id);
    updatedCartItems = [...updatedCartItems, menuItem];
    const subTotal = getCartTotalOnUpdate(updatedCartItems);
    const tax = subTotal * (taxRate / 100);
    const total = subTotal + restaurant.deliveryFee + tax;
    dispatch({
      type: UPDATE_EXISTING_CART_ITEM,
      payload: { updatedCartItems, totalPrice: total, subTotal, tax },
    });
  };
};

export const removeItemFromCart = (menuItem) => {
  return (dispatch, getState) => {
    const { restaurant } = getState().menu;
    const { cartItems, taxRate } = getState().cart;
    const updatedCartItems = cartItems.filter((ci) => ci.id !== menuItem);
    const subTotal = getCartTotalOnUpdate(updatedCartItems);
    const tax = subTotal * (taxRate / 100);
    const totalPrice = subTotal + restaurant.deliveryFee + tax;

    dispatch({
      type: REMOVE_ITEM_FROM_CART,
      payload: { updatedCartItems, subTotal, totalPrice, tax },
    });
  };
};

export const addCustomNote = (customNote) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_CUSTOM_NOTE_TO_CART, payload: customNote });
  };
};

const getIntegerAmount = (amount, currecyISOPrecision) => {
  return parseFloat(amount * Math.pow(10, currecyISOPrecision))
}

const getRoudedIntegerAmount = (amount, currecyISOPrecision) => {
  return parseFloat((amount * Math.pow(10, currecyISOPrecision)).toFixed(currecyISOPrecision))
}

export const postOrder = (history) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADER_ON });
    const { appParams, applicationType, siteWideDeatails } = getState().app;
    const { user, mobileNumber } = getState().auth;
    const { restaurant } = getState().menu;
    const { cartItems, totalPrice, subTotal, customNote, deliveryLocation, siteWideVoucherDetails, transactionID, discount, voucherCode } = getState().cart;
    const { paymentDetails } = getState();
    const { cardDetails } = getState().payment;
    const currencyCode = appParams.currecyDescription;
    const currecyISOPrecision = appParams.currecyISOPrecision;

    const items = [];

    cartItems.forEach((ci) => {
      const modifiers = [];
      //create modifiers
      ci.extraItems.forEach((modifier) => {
        modifiers.push({
          "id": modifier.modifierId,
          "displayName": modifier.displayName,
          "modifierQty": modifier.quantity,
          "price": {
            "discountAmount": {
              "amount": getRoudedIntegerAmount((modifier.originalPrice !== null && !isNaN(modifier.originalPrice)
                ? ((modifier.originalPrice * parseFloat(modifier.quantity)) - parseFloat(
                  modifier.price) * parseFloat(modifier.quantity)) : 0), currecyISOPrecision),
              "currencyCode": currencyCode,
              "formattedAmount": ""
            },
            "taxAmount": {
              "amount": 0,
              "currencyCode": currencyCode,
              "formattedAmount": ""
            },
            "totalPrice": {
              "amount": getRoudedIntegerAmount(parseFloat(modifier.price) * parseFloat(modifier.quantity), currecyISOPrecision),
              "currencyCode": currencyCode,
              "formattedAmount": ""
            },
            "unitPrice": {
              "amount": getRoudedIntegerAmount(modifier.originalPrice !== null && !isNaN(modifier.originalPrice)
                ? modifier.originalPrice : modifier.price, currecyISOPrecision),
              "currencyCode": currencyCode,
              "formattedAmount": ""
            }
          }
        });
      });

      //create items
      const item = {
        "id": ci.item.id,
        "name": ci.item.name,
        "price": {
          "discountAmount": {
            "amount": getRoudedIntegerAmount(
              (ci.item.originalPrice !== null && !isNaN(ci.item.originalPrice)
                ? (
                  (ci.item.originalPrice * ci.itemCount) - parseFloat(
                    ci.item.price) * ci.itemCount) : 0), currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          },
          "taxAmount": {
            "amount": 0,
            "currencyCode": currencyCode,
            "formattedAmount": ""
          },
          "totalPrice": {
            "amount": getRoudedIntegerAmount(parseFloat(ci.item.price) * parseFloat(ci.itemCount), currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          },
          "unitPrice": {
            "amount": getRoudedIntegerAmount(
              ci.item.originalPrice !== null && !isNaN(ci.item.originalPrice)
                ? ci.item.originalPrice : ci.item.price, currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          }
        },
        "quantity": ci.itemCount,
        "modifiers": modifiers
      };

      items.push(item);
    });

    let discountArr = []
    let voucherObj = {
      amount: { discount: discount, amount: getIntegerAmount(discount.toFixed(currecyISOPrecision), currecyISOPrecision), currencyCode: currencyCode, formattedAmount: '' },
      redemptionId: transactionID,
      code: `${voucherCode}`,
      type: 'VOUCHER'
    }

    let isEmpty = siteWideVoucherDetails !== undefined && siteWideVoucherDetails !== null ? Object.keys(siteWideVoucherDetails).length === 0 && siteWideVoucherDetails.constructor === Object : true
    let promoObj = !isEmpty && siteWideDeatails.rewardType !== undefined ? {
      amount: { discount: siteWideVoucherDetails.totalDiscount, amount: getIntegerAmount(siteWideVoucherDetails.totalDiscount.toFixed(currecyISOPrecision), currecyISOPrecision), currencyCode: currencyCode, formattedAmount: '' },
      redemptionId: siteWideVoucherDetails.redemptionId,
      type: 'PROMOTION'
    } : null;

    if (!(voucherCode === "" || voucherCode === null)) {
      discountArr.push(voucherObj)
    }

    if (promoObj !== null) {
      discountArr.push(promoObj)
    }

    const requestObject = {
      "externalReferenceId": uuid.v4(),
      "partnerId": appParams.partnerId,
      "brandId": appParams.restaurantId,
      "locationId": restaurant.locationId,
      "menuId": restaurant.menuId,
      "instructions": customNote,
      "delivery": {
        "receiverName": user.name,
        "receiverMobileNumber": applicationType === "ONLINE_ORDER" ? mobileNumber : user.mobileNumber,
        "location": {
          "address": applicationType === "ONLINE_ORDER" ? user.buildingName + ", " + user.address : user.sourceName + ": " + user.sourceNumber,
          "latitude": deliveryLocation.lat,
          "longitude": deliveryLocation.lng
        },
        "notes": user.notes
      },
      "customer": {
        "name": user.name,
        "contactNumber": applicationType === "ONLINE_ORDER" ? mobileNumber : user.mobileNumber,
        "email": user.email === undefined ? "" : user.email
      },
      "items": items,
      "payment": {
        "charges": {
          "deliveryFee": {
            "amount": getIntegerAmount(restaurant.deliveryFee, currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          },
          "subTotal": {
            "amount": getRoudedIntegerAmount(
              (totalPrice + getTotalDiscounts(discountArr) - restaurant.deliveryFee), currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          },
          "total": {
            "amount": getRoudedIntegerAmount(totalPrice, currecyISOPrecision),
            "currencyCode": currencyCode,
            "formattedAmount": ""
          }
        },
        "discounts": discountArr,
        "method": paymentDetails.paymentType,
        "status": paymentDetails.paymentStatus,
        "tax": [],
        "metaData": {
          "paymentToken": paymentDetails.paymentStatus === PAYMENT_STATUS.PREPAID ? cardDetails.tokenname : ""
        }
      },
      "placedAt": Math.floor(Date.now() / 1000),
      "mode": applicationType === "ONLINE_ORDER" ? "ONLINE_ORDER" : "GRUB_WAITER"
    };

    let protocol = window.location.protocol;
    var slashes = protocol.concat("//");
    var host = slashes.concat(window.location.hostname);

    axios.post(appApiEndPoint + ORDERS, requestObject, {
      headers: {
        hostUrl: host + (window.location.port ? ":" + window.location.port : "")
      }
    })
      .then(data => {
        dispatch({ type: SET_LOADER_ON });
        if (data.status === 200) {
          let orderResData = data.data;
          if (orderResData.threeDSUrl) {
            orderResData.orderSuccesful = true;
            orderResData.orderStatus = "VALIDATION_SUCCESS";
            dispatch({
              type: FETCH_ORDER_RESPONSE_SUCCESS,
              payload: orderResData
            });
            window.location = orderResData.threeDSUrl;
          } else {
            if (paymentDetails.paymentStatus === PAYMENT_STATUS.UNPAID || paymentDetails.paymentStatus === PAYMENT_STATUS.FOC) {
              orderResData.orderSuccesful = true;
              orderResData.orderPaymentStatus = "ORDER_POSTPAID";
              dispatch({
                type: FETCH_ORDER_RESPONSE_SUCCESS,
                payload: orderResData
              });
              history.push(ORDER + localStorage.getItem("searchUrl"));
            }
          }
          dispatch({ type: SET_LOADER_OFF });
        }
      }).catch((error) => {
        dispatch({ type: SET_LOADER_OFF });
        dispatch({
          type: FETCH_ORDER_RESPONSE_FAILED,
          payload: { orderSuccesful: false },
        });
        toast.error(error.response.data.message || error.response.data.message.toString(), { autoClose: false });
      });
  };
};

const getTotalDiscounts = (discountArr) => {
  let totalDiscount = 0;
  discountArr.forEach((discount) => {
    totalDiscount += discount.amount.discount;
  });
  return totalDiscount;
}

export const fetchOrderStatusMet = (
  dispatch,
  history,
  orderResponse,
  retries = 12
) => {
  dispatch({ type: SET_LOADER_OFF });
  axios.get(appApiEndPoint + ORDERS + '/' + orderResponse.id + '/status')
    .then(data => {
      let orderStatus = data.data;
      if (orderStatus === "VALIDATION_SUCCESS") {
        if (retries > 0) {
          setTimeout(function () {
            return fetchOrderStatusMet(
              dispatch,
              history,
              orderResponse,
              --retries
            );
          }, 5000);
        }
        orderResponse.orderStatus = "VALIDATION_SUCCESS";
        dispatch({ type: SET_LOADER_OFF });
        dispatch({
          type: FETCH_ORDER_RESPONSE_SUCCESS,
          payload: { ...orderResponse, orderSuccesful: true },
        });
        history.push(ORDER + localStorage.getItem("searchUrl"));
        if (retries === 0) {
          orderResponse.orderStatus = "PAYMENT_FAILED";
          dispatch({ type: SET_LOADER_OFF });
          dispatch({
            type: FETCH_ORDER_RESPONSE_SUCCESS,
            payload: { ...orderResponse, orderSuccesful: false },
          });
          history.push(ORDER + localStorage.getItem("searchUrl"));
        }
      } else if (orderStatus === "PAYMENT_SUCCESS") {
        orderResponse.orderStatus = "PAYMENT_SUCCESS";
        dispatch({ type: SET_LOADER_OFF });
        dispatch({
          type: FETCH_ORDER_RESPONSE_SUCCESS,
          payload: { ...orderResponse, orderSuccesful: true },
        });
        history.push(ORDER + localStorage.getItem("searchUrl"));
      } else if (orderStatus === "PAYMENT_FAILED") {
        orderResponse.orderStatus = "PAYMENT_FAILED";
        dispatch({ type: SET_LOADER_OFF });
        dispatch({
          type: FETCH_ORDER_RESPONSE_SUCCESS,
          payload: { ...orderResponse, orderSuccesful: false },
        });
      } else if (orderStatus === "UNKNOWN") {
        orderResponse.orderStatus = "UNKNOWN";
        dispatch({ type: SET_LOADER_OFF });
        dispatch({
          type: FETCH_ORDER_RESPONSE_SUCCESS,
          payload: { ...orderResponse, orderSuccesful: false },
        });
      }
    }).catch((err) => {
      dispatch({ type: SET_LOADER_OFF });
      orderResponse.orderStatus = "PAYMENT_FAILED";
      dispatch({
        type: FETCH_ORDER_RESPONSE_SUCCESS,
        payload: { ...orderResponse, orderSuccesful: false },
      });
      return Promise.reject(err);
    });
};

const getPaymentErrorMessage = (responseCode) => {
  if (responseCode !== undefined) {
    const messageCode = responseCode.substring(2, 5);
    let statusMessage;
    switch (messageCode) {
      case PAYFORT_3DS_STATUS.CARD_EXPIRED:
        statusMessage = "Card expired.";
        break;
      case PAYFORT_3DS_STATUS.INVALID_CARD_NUMBER:
        statusMessage = "Invalid card number.";
        break;
      case PAYFORT_3DS_STATUS.INSUFFICIENT_FUNDS:
        statusMessage = "Insufficient funds.";
        break;
      case PAYFORT_3DS_STATUS.TRANSACTION_DECLINED:
        statusMessage = "Card declined.";
        break;
      default:
        statusMessage = "Technical Failure.";
        break;
    }
    return statusMessage;
  }
};

export const set3dsPaymentResponse = (threedsPaymentResponse, history) => {
  return (dispatch, getState) => {
    const { response_code } = threedsPaymentResponse;
    if (response_code === PAYFORT_3DS_STATUS.SUCCESS) {
      dispatch({ type: SET_LOADER_ON });
      const { orderResponse } = getState().cart;
      fetchOrderStatusMet(dispatch, history, orderResponse);
    } else {
      toast.error(getPaymentErrorMessage(response_code));
      history.push(ROOT + localStorage.getItem("searchUrl"));
    }
  };
};

// TODO: Move this sort of function into user function
export const setDeliveryLocation = (coords) => {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_LOCATION, payload: coords });
  };
};

export const setCartEmpty = () => {
  return (dispatch) => {
    dispatch({ type: SET_CART_EMPTY });
  };
};

export const setMerchantRefEmpty = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_MERCHANT_REFERENCE, payload: "" });
  };
};

export const removeSiteWidePromoDetails = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_SITEWIDE_PROMO_RESPONSE });
  };
};
