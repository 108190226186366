/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
// import cc from "../../../assets/img/payment-types/credit-card.svg";
import cc from "../../../assets/img/payment-types/card-on-delivery.svg";
import cash from "../../../assets/img/payment-types/cash.svg";
import card from "../../../assets/img/payment-types/card.svg";
import AppHeader from "../../../components/AppHeader";
import Loader from "../../../components/Loader";
import { PAYMENT, PAYMENT_STATUS } from "../../../utils/enums";
import {
  PAYMENT as PAYMENT_ROUTE,
  CHECKOUT,
  SELECT_ADDRESS,
  SEARCH,
  VERIFICATION,
  ROOT,
  NEW_GUEST_INFO
} from "../../../utils/routeConstants";
import {
  setPaymentMethod,
  setPaymentStatus,
  getPaymentGateWayConfiguration
} from "../../../store/actions/paymentActions";
import { setMerchantRefEmpty } from "../../../store/actions/cartActions";
import Breadcrumb from "../../../components/Breadcrumb";

class MobileVerification extends Component {
  state = {
    isLoading: false,
    selectedPayment:
      this.props.paymentDetails.paymentType.length > 0
        ? this.props.paymentDetails.paymentType
        : this.props.paymentDetails.paymentMethods.length > 0 &&
          this.props.paymentDetails.paymentMethods.includes(PAYMENT.PREPAID)
          ? PAYMENT.PREPAID
          : this.props.paymentDetails.paymentMethods.length > 0
            ? this.props.paymentDetails.paymentMethods[0]
            : PAYMENT.PREPAID,
  };

  componentDidMount() {
    const { setPayment } = this.props;
    setPayment(this.state.selectedPayment);
  }

  handleChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  handleSelectedPayment = (payment) => {
    const { setPayment } = this.props;
    this.setState({ selectedPayment: payment }, () => {
      setPayment(payment);
    });
  };

  changeAddress = () => {
    const { history } = this.props;
    history.push(SELECT_ADDRESS + SEARCH);
  };

  handleSubmit = () => {
    const { selectedPayment } = this.state;
    const { history, setStatus, setRefEmpty, getPaymentGateWayConfiguration } = this.props;

    if (selectedPayment === PAYMENT.PREPAID) {
      // set payment status
      setStatus(PAYMENT_STATUS.PREPAID);
      getPaymentGateWayConfiguration(history)
    } else if (selectedPayment === PAYMENT.CASH) {
      // set payment status
      setStatus(PAYMENT_STATUS.UNPAID);
      setRefEmpty();
      history.push(CHECKOUT + SEARCH);
    } else if (selectedPayment === PAYMENT.CREDIT_CARD) {
      // setPayment status
      setStatus(PAYMENT_STATUS.UNPAID);
      setRefEmpty();
      history.push(CHECKOUT + SEARCH);
    }
  };

  renderBreadCrumb = () => {
    const { applicationType } = this.props;
    let items = [];
    if (applicationType === "VIRTUAL_WAITER") {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: NEW_GUEST_INFO + SEARCH, name: "Gust Info" },
        "Payment Method"
      ];
    } else {
      items = [
        { url: ROOT + SEARCH, name: "Home Page" },
        { url: VERIFICATION + SEARCH, name: "Verification" },
        { url: SELECT_ADDRESS + SEARCH, name: "Delivery Address" },
        "Payment Method"
      ];
    }

    return <Breadcrumb items={items} />;
  };

  render() {
    const { isLoading, selectedPayment } = this.state;
    const { restaurantName, history, paymentDetails } = this.props;

    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              backButtonClick={() => history.goBack()}
              heading={"Payment Method"}
              restaurantName={restaurantName}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>
        {this.renderBreadCrumb()}
        <div className="body ls-checkout-body ">
          <div className="ls-checkout-section">
            <div className="ls-flows-wrapper">
              <div className="ls-flows-container ls-flows-container-scrolled">
                <div className="clm--100">
                  <div className="ls-headerArea">
                    <p className="ls-title"> Select Payment Method </p>
                    <p className="ls-subtitle">
                      {" "}
                      Please select your preferred payment method{" "}
                    </p>
                  </div>
                </div>
                <div className="cust-row payment-item-wrapper">
                  {paymentDetails.paymentMethods.includes(PAYMENT.PREPAID) ? (
                    <div className="cust-col">
                      <div
                        role="presentation"
                        onClick={() =>
                          this.handleSelectedPayment(PAYMENT.PREPAID)
                        }
                        className={`payment-item ${classNames({
                          selected: selectedPayment === PAYMENT.PREPAID,
                        })}`}
                      >
                        <img src={card} alt="Cash" />
                        Card Payment
                      </div>
                    </div>
                  ) : null}
                  {paymentDetails.paymentMethods.includes(
                    PAYMENT.CREDIT_CARD
                  ) ? (
                      <div className="cust-col">
                        <div
                          role="presentation"
                          onClick={() =>
                            this.handleSelectedPayment(PAYMENT.CREDIT_CARD)
                          }
                          className={`payment-item ${classNames({
                            selected: selectedPayment === PAYMENT.CREDIT_CARD,
                          })}`}
                        >
                          <img src={cc} alt="CC" />
                        Card on Delivery
                      </div>
                      </div>
                    ) : null}

                  {paymentDetails.paymentMethods.includes(PAYMENT.CASH) ? (
                    <div className="cust-col">
                      <div
                        role="presentation"
                        onClick={() => this.handleSelectedPayment(PAYMENT.CASH)}
                        className={`payment-item ${classNames({
                          selected: selectedPayment === PAYMENT.CASH,
                        })}`}
                      >
                        <img src={cash} alt="Cash" />
                        Cash on Delivery
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="ls-mobile-bottom-button-container">
                  <button
                    type="button"
                    className="ls-cart-button center-text"
                    onClick={() => this.handleSubmit()}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="footer-fixed">
          <button
            type="button"
            className="ls-cart-button center-text"
            onClick={() => this.handleSubmit()}
          >
            Proceed
          </button>
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    cart: state.cart,
    selectedAddress: state.auth.selectedLocation,
    appParams: state.app.appParams,
    paymentDetails: state.paymentDetails,
    applicationType: state.app.applicationType,
    restaurantName: state.app.appParams.restaurantName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    setStatus: (status) => dispatch(setPaymentStatus(status)),
    setRefEmpty: () => dispatch(setMerchantRefEmpty()),
    getPaymentGateWayConfiguration: (history) =>
      dispatch(getPaymentGateWayConfiguration(history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);
