/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "../../../components/Toast";
import PhoneInput, {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import AppHeader from "../../../components/AppHeader";
import Loader from "../../../components/Loader";
import {
  ROOT,
  SELECT_ADDRESS,
  SEARCH,
  NEW_ADDRESS
} from "../../../utils/routeConstants";
import {
  setUsersPreviousLocation,
  setMobileNumber as setNumber,
  setMobileNumberRemember,
  setLocationCoordinates
} from "../../../store/actions/authActions";
import {
  CUSTOMERS
} from "../../../utils/apiConstants";
import Breadcrumb from "../../../components/Breadcrumb";
import getFinalizedMobileNumber from "../../../utils/mobileNumberValidation";
import NumberBoxes from "../../../components/NumberBoxes";

// import CartFooter from "../../../components/CartFooter";

const appApiEndPoint = process.env.REACT_APP_WEB_ORDER_API_URL;

class MobileVerification extends Component {
  state = {
    isValid: false,
    isLoading: false,
    sentToVerification: false,
    verificationCode: "",
    wrongVerificationCount: 0,
    handleProceed: false,
    mobileNum: "",
    mobileNumberRemember: false,
    invalidOTP: false
  };

  componentDidMount() {
    document.querySelector(".body").scrollTo(0, 0);
  }

  handleChange = (key, value) => {
    value = value || "";
    const newState = { [key]: value };
    if (key === "verificationCode") {
      const isValid = key === "verificationCode" && value.length === 4;
      newState.isValid = isValid;
    }
    if (key === "mobileNum") {
      const { rememberMobileNumbers } = this.props;
      const isValid = isPossiblePhoneNumber(value);
      newState.isValid = isValid;
      if (isValid) {
        const numberArray = formatPhoneNumberIntl(value)
          .replace("+", "")
          .split(" ");

        const phoneNumber = `${numberArray.shift()}${numberArray.join("")}`;
        if (rememberMobileNumbers !== undefined) {
          var existsRememberMobileNumberOb = rememberMobileNumbers.find(
            (data) => data.mobileNumber === phoneNumber
          );
          if (existsRememberMobileNumberOb) {
            this.setState({
              mobileNumberRemember: true,
            });
          } else {
            this.setState({
              mobileNumberRemember: false,
            });
          }
        }
      }
    } else if (key === "verificationCode" && newState.isValid) {
      newState.verificationCode = value;
      window.requestAnimationFrame(() => this.sendOpt());
    }
    this.setState(newState);
  };

  sendOpt = () => {
    if (this.state.verificationCode.length > 3 && !this.state.handleProceed) {
      this.setState({ handleProceed: true });
      this.handleOtpRequest();
    }
  };

  handleProceed = (event) => {
    event.preventDefault();
    const { mobileNum, sentToVerification } = this.state;
    if (isPossiblePhoneNumber(mobileNum)) {
      this.setState({ sentToVerification: true });
    } else {
      toast.error("Invalid phone number");
    }

    if (sentToVerification) {
      this.handleOtpRequest();
    } else {
      this.fetchMobileNumberData();
    }
  };

  handleOtpRequest = () => {
    const { mobileNum, verificationCode } = this.state;
    const { appParams } = this.props;
    const {
      history,
      setLocations,
      setMobileNumber,
      setMobileNumberRemember,
      setLocationCoordinates
    } = this.props;
    const numberArray = formatPhoneNumberIntl(mobileNum)
      .replace("+", "")
      .split(" ");

    const phoneNumber = `${numberArray.shift()}${numberArray.join("")}`;
    this.setState({ isLoading: true });

    axios.post(appApiEndPoint + CUSTOMERS + "/" + phoneNumber + "/verifyOtp", {
      otp: verificationCode
    })
      .then(async data => {
        if (data.status === 204) {
          if (this.state.mobileNumberRemember) {
            setMobileNumberRemember({
              mobileNumber: phoneNumber,
              mobileNumberRemember: true
            });
          }

          await axios.get(appApiEndPoint + CUSTOMERS + "/" + phoneNumber, {
            params: {
              partnerId: appParams.partnerId
            }
          })
            .then(res => {
              if (res.status === 200) {
                setLocationCoordinates();
                const customerData = res.data;
                if (customerData !== "" && customerData.recipients !== "") {
                  setLocations(customerData);
                  setMobileNumber(mobileNum);
                  history.push(SELECT_ADDRESS + SEARCH);
                } else {
                  setMobileNumber(mobileNum);
                  history.push(NEW_ADDRESS + SEARCH);
                }
              }
            });
        }
      }).catch((error) => {
        toast.error("Invalid verification code");
        const { wrongVerificationCount } = this.state;
        this.setState({
          handleProceed: false,
          invalidOTP: true,
          verificationCode: "",
          sentToVerification: !(wrongVerificationCount > 4),
          wrongVerificationCount:
            wrongVerificationCount > 4 ? 0 : wrongVerificationCount + 1,
          isLoading: false,
        }, () => {
          setMobileNumberRemember({
            mobileNumber: phoneNumber,
            mobileNumberRemember: false
          });
        });
      });
  };

  renderBreadCrumb = () => {
    const items = [{ url: ROOT + SEARCH, name: "Home Page" }, "Verification"];
    return <Breadcrumb items={items} />;
  };

  changeNumber = () => {
    this.setState({ sentToVerification: false });
  };

  changeMobileNumberRemember = (event) => {
    const { setMobileNumberRemember } = this.props;
    this.setState({
      mobileNumberRemember: event.target.checked
    }, () => {
      if (!this.state.mobileNumberRemember) {
        const numberArray = formatPhoneNumberIntl(this.state.mobileNum)
          .replace("+", "")
          .split(" ");

        const phoneNumber = `${numberArray.shift()}${numberArray.join("")}`;
        setMobileNumberRemember({
          mobileNumber: phoneNumber,
          mobileNumberRemember: false
        });
      }
    });
  };

  onCompleteVerificatioCodeEntering = (code) => {
    this.setState({
      isValid: true,
      verificationCode: code,
      invalidOTP: false
    });

    window.requestAnimationFrame(() => this.sendOpt());
  };

  getVerificationInputTextRef = (ref) => {
    this.VerificationInputTextRef = ref;
  };

  sentOtp = () => {
    const {
      appParams
    } = this.props;

    const finalizedMobileNumber = getFinalizedMobileNumber(this.state.mobileNum);
    const numberArray = formatPhoneNumberIntl(finalizedMobileNumber)
      .replace("+", "")
      .split(" ");

    const phoneNumber = `${numberArray.shift()}${numberArray.join("")}`;

    axios.post(appApiEndPoint + CUSTOMERS + "/" + phoneNumber + "/sendOtp", {}, {
      params: {
        brandId: appParams.restaurantId
      }
    })
      .then(async data => {
        if (data.status === 204) {
          this.setState({
            sentToVerification: true,
            isLoading: false,
            isValid: false,
          });
          toast.info("Verification code sent");
        }
      }).catch((error) => {
        toast.error("Error occurred. Try Again !");
        this.setState({ isLoading: false });
      });
  }

  sendForVerification = () => {
    const {
      appParams,
      history,
      setLocations,
      setMobileNumber,
      rememberMobileNumbers
    } = this.props;
    const { mobileNum } = this.state;

    if (this.VerificationInputRef !== undefined) {
      this.VerificationInputRef.state.tan = "";
      this.VerificationInputRef.state.previousTan = "";
      this.VerificationInputTextRef.value = "";
      this.setState({ verificationCode: "", handleProceed: false });
    }

    if (!isPossiblePhoneNumber(mobileNum)) {
      toast.error("Invalid phone number");
      return;
    }

    const finalizedMobileNumber = getFinalizedMobileNumber(mobileNum);
    const numberArray = formatPhoneNumberIntl(finalizedMobileNumber)
      .replace("+", "")
      .split(" ");

    const phoneNumber = `${numberArray.shift()}${numberArray.join("")}`;
    this.setState({ isLoading: true });
    if (rememberMobileNumbers === undefined) {
      axios.post(appApiEndPoint + CUSTOMERS + "/" + phoneNumber + "/sendOtp", {}, {
        params: {
          brandId: appParams.restaurantId
        }
      })
        .then(async data => {
          if (data.status === 204) {
            this.setState({
              sentToVerification: true,
              isLoading: false,
              isValid: false,
            });
            toast.info("Verification code sent");
          }
        }).catch((error) => {
          toast.error("Error occurred. Try Again !");
          this.setState({ isLoading: false });
        });
    } else {
      var existsRememberMobileNumberOb = rememberMobileNumbers.find(
        (data) => data.mobileNumber === phoneNumber
      );
      if (existsRememberMobileNumberOb) {
        axios.get(appApiEndPoint + CUSTOMERS + "/" + phoneNumber, {
          params: {
            partnerId: appParams.partnerId
          }
        })
          .then(async data1 => {
            if (data1.status === 200) {
              const customerData = data1.data;
              if (customerData !== "" && customerData.recipients !== "") {
                setLocations(customerData);
                setMobileNumber(mobileNum);
                history.push(SELECT_ADDRESS + SEARCH);
              } else {
                setMobileNumber(mobileNum);
                history.push(NEW_ADDRESS + SEARCH);
              }
            }
          });

      } else {
        axios.post(appApiEndPoint + CUSTOMERS + "/" + phoneNumber + "/sendOtp", {}, {
          params: {
            brandId: appParams.restaurantId
          }
        })
          .then(async data => {
            if (data.status === 204) {
              this.setState({
                sentToVerification: true,
                isLoading: false,
                isValid: false,
              });
              toast.info("Verification code sent");
            }
          }).catch((error) => {
            toast.error("Error occurred. Try Again !");
            this.setState({ isLoading: false });
          });
      }
    }
  };

  renderInitialScreen = () => {
    const { mobileNum } = this.state;

    return (
      <React.Fragment>
        <div className="ls-flows-wrapper">
          <div className="ls-flows-container">
            <div className="clm--100">
              <div className="ls-headerArea">
                <p className="ls-title"> Mobile Number </p>
                <p className="ls-subtitle">
                  {" "}
                  Your mobile number with "+country code"{" "}
                </p>
              </div>
            </div>
            <div className="clm--50 phone-input-container">
              <PhoneInput
                placeholder="Enter phone number (+XXXXX XXX XX XX)"
                value={mobileNum}
                defaultCountry="AE"
                maxLength="20"
                onChange={(value) => this.handleChange("mobileNum", value)}
              />
              {this.state.isValid && (
                <div style={{ marginTop: "15px" }}>
                  <label className="ls-dj-checkbox">
                    <input
                      type="checkbox"
                      checked={this.state.mobileNumberRemember}
                      id="rememberMe"
                      onClick={(event) =>
                        this.changeMobileNumberRemember(event)
                      }
                    />
                    <span className="ls-checkmark" />
                  </label>
                  <label for="rememberMe" className="trust-device">
                    Trust this device with this phone number
                  </label>
                  <p className="subtitle" style={{ marginTop: "20px" }}>
                    You will not be asked for an OTP verification on this device
                    for this phone number the next time you order.
                  </p>
                </div>
              )}
              <div
                className="ls-submit-container"
                style={{
                  padding: "0",
                }}
              >
                <button
                  type="button"
                  className={
                    this.state.isValid
                      ? "ls-cart-button"
                      : "ls-cart-button-disabled"
                  }
                  style={{
                    padding: "0",
                    marginTop: "29px",
                  }}
                  onClick={(event) => this.sendForVerification(event)}
                >
                  Proceed
                </button>
              </div>
            </div>
            <div className="clm--50">
              <img
                src={require("../../../assets/img/letter.svg")}
                className="img-right-side"
                alt="Send"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderVerificationScreen = () => {
    const { verificationCode, mobileNum } = this.state;
    const { cart, appParams } = this.props;
    const { currecyDescription, currecyISOPrecision } = appParams;
    return (
      <React.Fragment>
        <div className="ls-flows-wrapper">
          <div className="ls-flows-container">
            <div className="clm--100">
              <div className="ls-headerArea">
                <p className="ls-title"> Verification Code </p>
                <p className="ls-subtitle">
                  Please enter the verification code we sent to your phone
                  number
                  <span className="ls-mobile-num-change">
                    {mobileNum}
                    <span onClick={() => this.changeNumber()}>change</span>
                  </span>
                </p>
              </div>
            </div>
            <div className="clm--50">
              <div className="dj-input-wrapper1" style={{ height: "60px" }}>
                <div>
                  <NumberBoxes
                    boxes={4}
                    onIncomplete={() =>
                      this.setState({ isValid: false, verificationCode: "" })
                    }
                    onComplete={this.onCompleteVerificatioCodeEntering}
                    isOnError={this.state.invalidOTP}
                  />
                </div>
              </div>
              <div
                className="ls-submit-container"
                style={{ padding: 0, paddingBottom: "1em" }}
              >
                <button
                  type="button"
                  className={
                    this.state.isValid
                      ? "ls-cart-button"
                      : "ls-cart-button-disabled"
                  }
                  onClick={() => this.sendOpt()}
                >
                  Verify
                </button>
                <div className="resend">
                  <span
                    role="presentation"
                    className="input-link"
                    onClick={() => this.sentOtp()}
                  >
                    Resend code?
                  </span>
                </div>
              </div>
            </div>
            <div className="clm--50">
              <img
                src={require("../../../assets/img/letter.svg")}
                className="img-right-side"
                alt="send"
              />
            </div>
          </div>
        </div>
        <div className="footer-fixed">
          <div className="basket-final-total">
            <span>Total</span>
            <span>{`${currecyDescription} ${cart.totalPrice.toFixed(currecyISOPrecision)}`}</span>
          </div>
          <button
            disabled={verificationCode.length < 4 ? "disabled" : ""}
            type="button"
            className={
              this.state.isValid
                ? "ls-cart-button"
                : "ls-cart-button-disabled center-text"
            }
            onClick={(event) => this.handleProceed(event)}
          >
            Proceed
          </button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { isLoading, sentToVerification } = this.state;
    const { restaurantName, history } = this.props;
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="ls-app-header-wrapper">
          <AppHeader
            restaurantName={restaurantName}
            heading="Verify"
            backButtonClick={() => history.goBack()}
            isShowHours={true}
          />
        </div>
        {this.renderBreadCrumb()}
        <div className="body ls-checkout-body">
          <div className="ls-checkout-section">
            {sentToVerification
              ? this.renderVerificationScreen()
              : this.renderInitialScreen()}
            {/* <AppFooter /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    cart: state.cart,
    appParams: state.app.appParams,
    selectedCity: state.app.selectedCity,
    rememberMobileNumbers: state.auth.rememberMobileNumbers,
    restaurantName: state.app.appParams.restaurantName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocations: (resp) => dispatch(setUsersPreviousLocation(resp)),
    setMobileNumber: (number) => dispatch(setNumber(number)),
    setMobileNumberRemember: (mobileNumberOb) =>
      dispatch(setMobileNumberRemember(mobileNumberOb)),
    setLocationCoordinates: (history, ref) =>
      dispatch(setLocationCoordinates(history, ref)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);
