export const PARTNET_PROPERTIES = {
  RESTAURANT: "restaurants"
};

export const CART = {
  CART_PUSH: ""
};

export const FETCH_APP_CONFIGURATION = "/app-configuration";
export const FETCH_APP_MENU = "/menus/";
export const FETCH_NEAREST_LOCATION = "/locations/byBrandAndCoordinates";
export const FETCH_CURRENT_LOCATION_COORDINATES = "/city";
export const FETCH_PROMOTIONS = "/promotions";
export const CUSTOMERS = "/customers";
export const ORDERS = "/orders";
export const FETCH_APP_LOCATION = "/locations";
export const FETCH_PAYMENT_GATEWAY  = "/payment-gateways"
