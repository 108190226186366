import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Svg } from '../Svg';
import checkRestaurantOpen from '../../utils/checkRestaurantOpen';
import { setModalOff, setModalOn } from '../../store/actions/appActions';
import { RESTAURANT_OPEN } from '../../utils/appConstants';

class BannerHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuList: [],
			imagePopup: '',
			isOpen: false,
			displayTime: 'hidden'
		};
	}

	componentDidMount() {}

	toggleDisplayHours = () => {
		if (this.state.displayTime === 'hidden') {
			this.setState({ displayTime: 'visible' });
		} else {
			this.setState({ displayTime: 'hidden' });
		}
	};

	render() {
		const { operatingHours } = this.props;
		let isOpen;

		if (operatingHours) {
			isOpen = checkRestaurantOpen(operatingHours);
		}
		return (
			<div className="ls-banner-header">
				<div className="ls-banner-rect">
					<div className={isOpen === RESTAURANT_OPEN ? 'open-now' : 'closed-now'}>{isOpen}</div>
					<Svg
						className="info-header"
						type="info"
						width="22"
						height="22"
						fill="#fff"
						stroke="transparent"
						onClick={this.toggleDisplayHours}
					/>
					<div className="within-mins-free">{isOpen && this.restHours(operatingHours)}</div>
				</div>
			</div>
		);
	}

	restHours = (operatingHours) => {
		operatingHours = operatingHours.filter((item) => item.startTime && item.endTime);
		var d = new Date();
		var weekday = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
		var dayName = weekday[d.getDay()];

		const currentItem = operatingHours.find((item) => item.day === dayName);


		if(currentItem !== undefined){
			const startTime = currentItem.startTime.split(':');
			let startHour = startTime[0];
			const startSuffix = startHour >= 12 ? 'PM' : 'AM';
			startHour = startHour > 12 ? startHour - 12 : startHour;
			startHour = startHour === '00' ? 12 : startHour;
	
			const endTime = currentItem.endTime.split(':');
			let endHour = endTime[0];
			const endSuffix = endHour >= 12 ? 'PM' : 'AM';
			endHour = endHour > 12 ? endHour - 12 : endHour;
			endHour = endHour === '00' ? 12 : endHour;
	
			return `${startHour}:${startTime[1]} ${startSuffix} - ${endHour}:${endTime[1]} ${endSuffix}`;
		}else {
			return ""
		}
		
	};
}

const mapStateToProps = (state) => {
	return {
		restaurant: state.menu.restaurant,
		cart: state.cart,
		menuItems: state.menu.menuItems,
		isLoading: state.app.loading,
		appParams: state.app.appParams,
		selectedCity: state.app.selectedCity,
		menuCardImage: state.cart.menuCardImage,
		operatingHours: state.cart.operatingHours,
		ui: state.ui
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: (restaurant) => dispatch(setModalOn(restaurant)),
		closeModal: () => dispatch(setModalOff())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerHeader);
