import {
  SET_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_STATUS,
  SET_PAYMENT_GATEWAY_CONFIGURATION
} from "../actionTypes";

const initState = {
  paymentType: "",
  paymentMethods: [],
  paymentStatus: "",
  paymentGateWayConfigurations: {}
};

const payReducer = (state = initState, action) => {

  switch (action.type) {
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentType: action.payload
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.payload
      };
    case SET_PAYMENT_GATEWAY_CONFIGURATION:
      return {
        ...state,
        paymentGateWayConfigurations: action.payload
      };
    default: {
      return state;
    }
  }
};

export default payReducer;
