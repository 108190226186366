/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from "react";
import { connect } from "react-redux";

import BasketHeader from "./BasketHeader";
import CartItem from "./CartItem";
import {
  fetchCartResponseData,
  postOrder,
  applyPromoDiscount,
  removePromoDiscount,
  addCustomNote,
  removeItemFromCart,
  applySiteWidePromo
} from "../../store/actions/cartActions";
import {
  CHECKOUT,
  ROOT,
  VERIFICATION,
  SEARCH,
  NEW_GUEST_INFO
} from "../../utils/routeConstants";
import TagManager from "react-gtm-module";
// import {ADD_CUSTOM_NOTE_TO_CART, SET_LOADER_ON, SET_MENU_CARD_IMAGE} from "../../store/actionTypes";
import checkRestaurantOpen from "../../utils/checkRestaurantOpen";
import AppHeader from "../../components/AppHeader";
import { RESTAURANT_CLOSED, RESTAURANT_OPEN } from "../../utils/appConstants";
import bellSleeping from "../../assets/img/bell-sleeping-mode.svg";
import { withRouter } from "react-router-dom";

const hidden = {
  display: "none",
};

const show = {
  display: "block",
};

class Basket extends Component {
  state = {
    cartItems: [],
    cart: {},
    voucher: "",
    displayNote: "hidden",
    customNote: "",
  };

  componentDidMount() {
    window.scroll(0, 0);

    const { fetchCartResponse, cart, applySiteWide, siteWide } = this.props;

    fetchCartResponse(cart.cartItems);
    const voucherCode = cart.voucherCode;
    this.setState(
      {
        voucher: voucherCode,
        cart: cart.cartItems,
        cartItems: cart.cartItems,
      },
      () => {
        if (voucherCode && cart.cartItems.length) {
          // if the fresh-code exist
          this.props.applyDiscount(voucherCode, true);
        }
      }
    );

    // sendPromoDetails
    if (siteWide.rewardType !== undefined) {
      applySiteWide();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      cartItems: nextProps.cart.cartItems,
    });
  }

  handleBackClick = () => {
    const { history, applicationType } = this.props;
    history.push(applicationType === "ONLINE_ORDER" ? ROOT + SEARCH : SEARCH);
  };

  handleOnItemClick = (item, id) => {
    const { history } = this.props;
    history.push(`/menu/${item.categoryId}/${item.id}/${id}${SEARCH}`);
  };

  gtmProducts = (data, restaurantName) => {
    let index = 0;
    let products = [];

    data.forEach((record) => {
      products.push({
        id: record.item.id,
        name: record.item.name,
        price: record.item.recipeIds[0].sellingPrice,
        brand: restaurantName,
        dimension1: null,
        position: index,
        quantity: record.itemCount,
      });
      index++;
    });
    return products;
  };

  onPlaceOrder = () => {
    const {
      addCustomNote,
      history,
      isLoggedIn,
      cart,
      restaurant,
      appParams,
      applicationType
    } = this.props;
    addCustomNote(this.state.customNote);

    this.sendGTMDataCheckout(cart.cartItems, restaurant, appParams);

    if (isLoggedIn) {
      history.push(CHECKOUT + SEARCH);
    } else {
      if (applicationType === "ONLINE_ORDER") {
        history.push(VERIFICATION + SEARCH);
      } else {
        history.push(NEW_GUEST_INFO + SEARCH);
      }
    }
  };

  handleItemRemove = (cartItem, currencyCode) => {
    const { removeFromCart, restaurant, appParams, cart, applySiteWide, siteWide } = this.props;

    removeFromCart(cartItem.id);
    this.sendGTMDataItemRemove([cartItem], restaurant, appParams, currencyCode);
    if (cart.voucherCode !== null) {
      const voucherCode = cart.voucherCode;

      if (cart.cartItems.length > 1 && voucherCode) {
        this.props.applyDiscount(voucherCode);
      }
    }

    if (siteWide.rewardType !== undefined) {
      applySiteWide();
    }

  };

  sendGTMDataItemRemove = (data, restaurant, appParams, currencyCode) => {
    const tagManagerArgs = {
      gtmId: appParams.gtmKey,
      dataLayer: {
        event: "removeFromCart",
        ecommerce: {
          checkout: {
            currencyCode: currencyCode,
            remove: {
              products: this.gtmProducts(data, restaurant.name),
            },
          },
        },
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  sendGTMDataCheckout = (data, restaurant, appParams) => {
    const tagManagerArgs = {
      gtmId: appParams.gtmKey,
      dataLayer: {
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: this.gtmProducts(data, restaurant.name),
          },
        },
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  handleVoucher = async () => {
    const { applyDiscount, removeDiscount, } = this.props;
    const { discount } = this.props.cart;
    if (discount === 0) {
      applyDiscount(this.state.voucher);
    } else {
      removeDiscount();
      this.setState({ voucher: "" });
    }


  };

  toggleCustomNote = () => {
    if (this.state.displayNote === "hidden") {
      this.setState({ displayNote: "visible" });
      window.requestAnimationFrame(() => {
        const body = document.querySelector(".body");
        body && body.scrollTo(0, global.screen.height);
      });
    } else {
      this.setState({ displayNote: "hidden" });
    }
  };

  render() {
    const { cart, appParams, restaurant } = this.props;
    const { currecyDescription, currecyISOPrecision } = appParams;
    const {
      totalPrice,
      subTotal,
      // tax,
      discount,
      operatingHours,
    } = cart;

    const { deliveryFee } = restaurant;

    let isOpen = RESTAURANT_CLOSED;
    if (operatingHours) {
      isOpen = checkRestaurantOpen(operatingHours);
    }

    const itemCount = this.state.cartItems.reduce(
      (total, current) => total + current.itemCount,
      0
    );

    return (
      <div className="ls-basket-container">
        {this.props.showBasketHeader ? (
          <BasketHeader
            itemCount={itemCount}
            backButtonClick={this.handleBackClick}
          />
        ) : (
          <AppHeader heading="Basket" backButtonClick={this.handleBackClick} />
        )}
        {itemCount === 0 ? (
          <div className="ls-empty-basket">
            <div className="ls-empty-basket-oval">
              <img src={bellSleeping} alt="sleeping" />
            </div>
            <div className="ls-empty-basket-out-of-order">Basket empty</div>
            <div className="ls-empty-basket-out-of-order-descr">
              Good food is always cooking! Go ahead, order some yummy items from
              the menu.
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="body no-side-padding with-fixed-total">
              <div className="ls-basket-items-wrapper">
                <ul className="ls-basket-item-list">
                  {this.state.cartItems.map((ci) => {
                    const {
                      extraItemPrice,
                      extraItems,
                      item,
                      itemCount,
                      id,
                    } = ci;
                    return (
                      <CartItem
                        cartItem={ci}
                        key={id}
                        itemCount={itemCount}
                        item={item}
                        name={item.name}
                        currecyDescription={currecyDescription}
                        currecyISOPrecision={currecyISOPrecision}
                        price={item.price}
                        originalPrice={item.originalPrice}
                        extraItems={extraItems}
                        extraItemPrice={extraItemPrice}
                        onItemClick={() => {
                          this.handleOnItemClick(item, id);
                        }}
                        onRemoveClick={(currencyCode) => {
                          this.handleItemRemove(ci, currencyCode);
                        }}
                      />
                    );
                  })}
                </ul>
              </div>

              <ul className="ls-basket-item-list with-padding">
                <li className="ls-basket-item-list-item basket-item-list-item-total">
                  <div className="ls-basket-item-details">
                    <label className="promo-code">
                      Promo code?
                      <input
                        name="Voucher"
                        type="text"
                        value={this.state.voucher}
                        className="PhoneInput input-coupon"
                        placeholder="Add here"
                        onChange={(event) =>
                          this.setState({
                            voucher: event.target.value,
                          })
                        }
                      />
                    </label>
                  </div>
                  <div className="ls-basket-item-price">
                    <button
                      type="button"
                      className="btn-coupon"
                      onClick={() => this.handleVoucher()}
                    >
                      {discount === 0 ? "Apply" : " X"}
                    </button>
                  </div>
                  <div className="ls-line" />
                </li>
              </ul>

              <ul className="ls-basket-item-list with-padding m-b-40-mobile basket-item-custom-note">
                <li className="ls-basket-item-list-item basket-item-list-item-total">
                  <div>
                    <span onClick={() => this.toggleCustomNote()}>
                      Add a custom note?
                    </span>
                    <textarea
                      name="Note"
                      type="text"
                      value={this.state.customNote}
                      className=""
                      placeholder="Enter a custom note for the restaurant"
                      style={
                        this.state.displayNote === "hidden" ? hidden : show
                      }
                      onChange={(event) =>
                        this.setState({ customNote: event.target.value })
                      }
                    />
                  </div>
                </li>
              </ul>
              <div className="ls-basket-total-list-pre"></div>
            </div>
            <div className="footer-fixed show ls-basket-total-list">
              <ul className="ls-basket-item-list ls-basket-item-small with-padding m-t-40-mobile">
                <li className="ls-basket-item-list-item-price basket-item-list-item-total">
                  <div className="ls-basket-item-details">
                    <div className="ls-basket-item-name">Subtotal</div>
                  </div>
                  <div className="ls-basket-total-item-price">
                    {currecyDescription} {subTotal.toFixed(currecyISOPrecision)}
                  </div>
                </li>
                {discount > 0 && (
                  <li className="ls-basket-item-list-item-price basket-item-list-item-total ls-basket-item-highlight">
                    <div className="ls-basket-item-details">
                      <div className="ls-basket-item-name">Discount</div>
                    </div>
                    <div className="ls-basket-total-item-price">
                      - {currecyDescription} {discount.toFixed(currecyISOPrecision)}
                    </div>
                  </li>
                )}
                {deliveryFee > 0 && (
                  <li className="ls-basket-item-list-item-price basket-item-list-item-total">
                    <div className="ls-basket-item-details">
                      <div className="ls-basket-item-name">Delivery fee </div>
                    </div>
                    <div className="ls-basket-total-item-price">
                      {currecyDescription} {deliveryFee.toFixed(currecyISOPrecision)}
                    </div>
                  </li>
                )}
              </ul>
              <ul className="ls-basket-item-list with-padding border-top-1px">
                <li className="ls-basket-item-list-item-total-price basket-item-list-item-total ls-basket-last-total">
                  <div className="ls-basket-item-details">
                    <div className="ls-basket-item-name">Total</div>
                  </div>
                  {totalPrice !== null ? (
                    <div className="ls-basket-item-price price-xl">
                      {currecyDescription} {totalPrice.toFixed(currecyISOPrecision)}
                    </div>
                  ) : null}
                </li>
              </ul>
              <button
                disabled={isOpen === RESTAURANT_OPEN ? "" : "disabled"}
                type="button"
                className={`ls-full-width dark-theme ${isOpen === RESTAURANT_OPEN
                  ? "ls-cart-button"
                  : "ls-cart-button-disabled"
                  }`}
                onClick={() => this.onPlaceOrder()}
              >
                {isOpen === RESTAURANT_OPEN
                  ? "Checkout"
                  : "Restaurant is Closed"}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    cart: state.cart,
    isLoggedIn: state.auth.loggedIn,
    appParams: state.app.appParams,
    siteWide: state.app.siteWideDeatails,
    applicationType: state.app.applicationType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartResponse: (cart) => dispatch(fetchCartResponseData(cart)),
    publishOrder: () => dispatch(postOrder()),
    applyDiscount: (discount, slient) => dispatch(applyPromoDiscount(discount, slient)),
    removeDiscount: () => dispatch(removePromoDiscount()),
    addCustomNote: (customNote) => dispatch(addCustomNote(customNote)),
    removeFromCart: (menuItem) => dispatch(removeItemFromCart(menuItem)),
    applySiteWide: () => { dispatch(applySiteWidePromo()) }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Basket));
