import React from 'react'

import { toast } from "react-toastify";

const ErrorAlert = ({message}) => {
    return (
        <div className='ls-app-toast error'>
            <span className='message'>{message}</span>
            <span className='icon exclamation'></span>
        </div>
    )
}

const InfoAlert = ({message}) => {
    return (
        <div className='ls-app-toast info'>
            <span className='message'>{message}</span>
            <span className='icon check'></span>
        </div>
    )
}

const AppToast = {
        error: (err) => toast(<ErrorAlert message={err} />, {
            autoClose: true,
            closeButton: false,
            className: 'ls-app-override-toast'
        }),
        info: (info) => toast(<InfoAlert message={info} />, {
            autoClose: true,
            closeButton: false,
            className: 'ls-app-override-toast'
        }),
        success: (info) => toast(<InfoAlert message={info} />, {
            autoClose: true,
            closeButton: false,
            className: 'ls-app-override-toast'
        }),
        warn: (err) => toast(<ErrorAlert message={err} />, {
            autoClose: true,
            closeButton: false,
            className: 'ls-app-override-toast'
        })
}

export {AppToast as toast}