import React from 'react';

class CategoryMenu extends React.Component {
	manualScrollMode = false;
	state = { activeIndex: 0 };

	componentDidMount() {
		const layoutContent = document.querySelector('.ls-layout-content');
		const scrollBar = document.getElementById('menuList-ul');
		const offsetCheck = 0;
		var scrollTimer = null;
		layoutContent &&
			layoutContent.addEventListener('scroll', () => {
				if (this.manualScrollMode || scrollTimer) {
					return;
				}
				scrollTimer = setTimeout(() => {
					scrollTimer = null;
					const { menuList } = this.props;
					if (menuList.length > 0) {
						try {
							// create a value array from menus
							const values = menuList.map((item) => item.dom.offsetTop);

							// get the current position
							const position = layoutContent.scrollTop + 10;

							const output = values.reduce(
								(prev, curr) => (Math.abs(curr - position) < Math.abs(prev - position) ? curr : prev)
							);

							const setIndex = values.indexOf(output);
							this.setActiveMenu(setIndex);

							const itemActive = document.querySelector('.ls-tabs__item--active');
							const itemActiveLeft =
								itemActive.getBoundingClientRect().left - scrollBar.getBoundingClientRect().left;
							if (
								itemActiveLeft + offsetCheck + itemActive.clientWidth > scrollBar.clientWidth &&
								scrollBar.children[setIndex]
							) {
								scrollBar.scrollLeft = scrollBar.children[setIndex].offsetLeft;
							} else if (itemActiveLeft < offsetCheck && scrollBar.children[setIndex - 1]) {
								scrollBar.scrollLeft = scrollBar.children[setIndex - 1].offsetLeft;
							}
						} catch (err) {
							console.error(err);
						}
					}
				}, 100);
			});
	}

	setActiveMenu(index) {
		this.setState({
			activeIndex: index
		});
	}

	render() {
		const { menuList, applicationType, locationCount, selectedLocationInfo } = this.props;
		const { activeIndex } = this.state;

		const wrapperClass = () => {
			if (applicationType === 'ONLINE_ORDER') {
				if (locationCount > 1 && selectedLocationInfo) {
					return "ls-categories-wrapper";
				} else {
					return "ls-categories-wrapper virtual-waiter"
				}
			} else {
				return "ls-categories-wrapper virtual-waiter"
			}
		}

		return (
			<div className={wrapperClass()} id="meuListContainer">
				<div className="ls-categories-desktop-top" />
				<div className="ls-categories-desktop-left" />
				<div className="ls-categories-desktop-right" />
				<ul className="ls-tabs" id="menuList-ul">
					{menuList.map((item, index) => (
						// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
						<li
							className={activeIndex !== index ? 'ls-tabs__item' : 'ls-tabs__item ls-tabs__item--active'}
							key={item.title}
							onClick={(e) => {
								this.manualScrollMode = true;
								const layoutContent = document.querySelector('.ls-layout-content');
								const scrollTo = item.dom.offsetTop - 150;
								layoutContent.scrollTo({
									top: scrollTo,
									behavior: 'smooth'
								});
								const timer = setInterval(() => {
									if (Math.abs(layoutContent.scrollTop - scrollTo) < 10) {
										this.manualScrollMode = false;
										clearInterval(timer);
									}
								});
								this.setActiveMenu(index);
							}}
						>
							{item.title}
						</li>
					))}
				</ul>
			</div>
		);
	}
}

export default CategoryMenu;
