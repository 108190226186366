/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { connect } from "react-redux";
import AppHeader from "../../../components/AppHeader";
import { setUserAddress } from "../../../store/actions/authActions";
import { toast } from "../../../components/Toast";
import {
  SELECT_PAYMENT,
  SEARCH,
  CHECKOUT
} from "../../../utils/routeConstants";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Breadcrumb from "../../../components/Breadcrumb";
import { formStateManager } from "../../../utils/formState";
import {
  setPaymentMethod,
  setPaymentStatus,
  getPaymentGateWayConfiguration
} from "../../../store/actions/paymentActions";
import { PAYMENT_STATUS, PAYMENT as PAYMENT_TYPE } from "../../../utils/enums";

class NewGuestInfo extends Component {
  state = {
    // selectedType: "",
    mobileNumber: null,
    firstName: "",
    lastName: "",
    allValid: false,
  };

  focusOn = () => {
    let getCurrentErrorLabel = document.querySelectorAll(".validation-error");
    if (getCurrentErrorLabel.length > 0) {
      window.scrollTo(0, getCurrentErrorLabel[0].offsetTop);
    }
  };

  componentDidMount() {
    const { setPayment, setStatus, virtualWaiterConfiguration } = this.props;
    setPayment(PAYMENT_TYPE.PREPAID);
    setStatus(PAYMENT_STATUS.PREPAID);
    if (virtualWaiterConfiguration) {
      this.setState({
        sourceName: virtualWaiterConfiguration.sourceName,
        sourceNumber: virtualWaiterConfiguration.sourceNumber,
        orderSourceEdit: !virtualWaiterConfiguration.orderSourceEdit
      });
    }

    formStateManager(
      this.refs.newAddressForm,
      {
        validated: (state, allValid) => {
          if (this.state.allValid !== allValid) {
            this.setState({
              allValid: allValid,
            });
          }
        },
      },
      [
        ...[
          "firstName",
          "lastName",
          "mobileNumber",
          "sourceNumber"
        ].map((item) => ({
          name: item,
          validation: [
            {
              rule: { type: "required" },
            },
          ],
        })),
      ]
    );
  }

  renderBreadCrumb = () => {
    const items = [
      { url: SEARCH, name: "Home Page" },
      "Gust Info",
    ];

    return <Breadcrumb items={items} />;
  };

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { setUserData, history, paymentDetails, getPaymentGateWayConfiguration, setStatus, setPayment, cart } = this.props;
    if (this.state.allValid) {
      setUserData(this.state);
      if (cart.totalPrice === 0) {
        setStatus(PAYMENT_STATUS.FOC);
        setPayment(PAYMENT_TYPE.FOC);
        history.push(CHECKOUT + SEARCH);
      } else {
        if (paymentDetails.paymentMethods.length > 1) {
          history.push(SELECT_PAYMENT + SEARCH);
        } else {

          if (paymentDetails.paymentMethods.length === 0) {
            toast.error("Payment methods not cofigured!", { autoClose: true })
          } else if (paymentDetails.paymentMethods.length === 1) {
            if (paymentDetails.paymentMethods[0] === PAYMENT_TYPE.PREPAID) {
              setStatus(PAYMENT_STATUS.PREPAID);
              setPayment(PAYMENT_TYPE.PREPAID);
              getPaymentGateWayConfiguration(history);
            } else {
              history.push(SELECT_PAYMENT + SEARCH);
            }
          }
        }
      }

    } else {
      this.focusOn();
      // toast.error("Required fields are missing.", { autoClose: false });
    }
  };

  render() {
    const { restaurantName, history } = this.props;
    const { mobileNumber, firstName, lastName, sourceNumber, sourceName, orderSourceEdit } = this.state;
    return (
      <React.Fragment>
        <div className="ls-app-header-wrapper">
          <div className="container">
            <AppHeader
              backButtonClick={() => history.push(SEARCH)}
              heading={"Guest"}
              restaurantName={restaurantName}
              cartEnabled={false}
              isShowHours={true}
            />
          </div>
        </div>
        {this.renderBreadCrumb()}
        <div className="ls-checkout-body">
          <div className="ls-flows-wrapper">
            <div className="ls-flows-container ls-flows-container-scrolled">
              <div className="clm--50">
                <form ref="newAddressForm">
                  <div className="ls-field" style={{ marginTop: "30px" }}>
                    <input
                      name="firstName"
                      type="text"
                      maxLength="50"
                      ref={"firstName"}
                      value={firstName}
                      onChange={this.handleOnChange}
                      placeholder="Name"
                    />
                    <label htmlFor="mob-num">Name*</label>
                  </div>
                  <div className="ls-field">
                    <input
                      name="lastName"
                      type="text"
                      maxLength="50"
                      value={lastName}
                      onChange={this.handleOnChange}
                      placeholder="Surname*"
                    />
                    <label htmlFor="mob-num">Surname*</label>
                  </div>

                  <PhoneInput
                    name="mobileNumber"
                    placeholder="Mobile Number*"
                    defaultCountry="AE"
                    value={mobileNumber}
                    onChange={(phone) => {
                      this.setState({
                        mobileNumber: phone,
                      });
                    }}
                    // TODO className={formValidation.phoneNo === '*required' ? 'error-border' : null}
                    style={{
                      marginBottom: "10px",
                    }}
                  />

                  <div className="ls-field">
                    <input
                      name="sourceNumber"
                      type="text"
                      maxLength="50"
                      disabled={orderSourceEdit}
                      value={sourceNumber}
                      onChange={this.handleOnChange}
                      placeholder={sourceName + "*"}
                    />
                    <label htmlFor="sourceNumber">{sourceName + "*"}</label>
                  </div>
                </form>
              </div>
              <div className="clm--50">
                <img
                  src={require("../../../assets/img/location.svg")}
                  className="img-right-side"
                  alt="Location"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ls-mobile-bottom-button-container">
          <button
            type="button"
            className={
              (this.state.allValid
                ? "ls-cart-button"
                : "ls-cart-button-disabled") + " center-text"
            }
            onClick={() => this.state.allValid && this.handleSubmit()}
          >
            Next
                    </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantName: state.app.appParams.restaurantName,
    restaurant: state.menu.restaurant,
    cart: state.cart,
    auth: state.auth,
    virtualWaiterConfiguration: state.app.virtualWaiterConfiguration,
    paymentDetails: state.paymentDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (obj) => dispatch(setUserAddress(obj)),
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    setStatus: (status) => dispatch(setPaymentStatus(status)),
    getPaymentGateWayConfiguration: (history) =>
      dispatch(getPaymentGateWayConfiguration(history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGuestInfo);
