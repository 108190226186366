/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
//  This component is a temporary component
// Will be removed after the deployment

import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { postOrder, setCartEmpty } from "../../store/actions/cartActions";
import { ROOT, SEARCH } from "../../utils/routeConstants";
import Loader from "../../components/Loader";
import {
  set3dsPaymentResponse,
  removeSiteWidePromoDetails,
} from "../../store/actions/cartActions";
import { clearLocationSelectInfo } from "../../store/actions/appActions";
import { setPaymentMethod } from "../../store/actions/paymentActions";
import TagManager from "react-gtm-module";
import OrderModal from "../../components/Modal/order";
import { PAYMENT } from "../../utils/enums";
let isGTMSent = false;

class OrderComplete extends Component {
  state = {
    cartItems: [],
    cart: {}
  }
  componentDidMount() {
    const {
      location,
      setPayfortResponse,
      history,
      selectedPayment,
    } = this.props;
    const queryParams = location.search;
    if (selectedPayment === PAYMENT.PREPAID) {
      const params = queryString.parse(queryParams);
      setPayfortResponse(params, history);
    }
  }

  componentDidUpdate() {
    const { orderResponse, setPayment } = this.props;
    if (
      orderResponse &&
      orderResponse.orderSuccesful &&
      orderResponse.orderStatus === "PAYMENT_SUCCESS"
    ) {
      setPayment("");
    }
  }

  sendGTMData = (cart, restaurant, appParams, referenceCode) => {
    if (!isGTMSent) {
      let index = 0;
      let gtmProducts = [];

      cart.cartItems.forEach((record) => {
        gtmProducts.push({
          id: record.item.id,
          name: record.item.name,
          price: record.item.recipeIds[0].sellingPrice,
          brand: restaurant.name,
          dimension1: "M",
          position: index,
          quantity: record.itemCount,
        });
        index++;
      });

      const tagManagerArgs = {
        gtmId: appParams.gtmKey,
        dataLayer: {
          event: "transaction",
          ecommerce: {
            purchase: {
              actionField: {
                id: referenceCode,
                affiliation: "Online Store",
                revenue: cart.totalPrice,
                tax: cart.tax,
                shipping: restaurant.deliveryFee,
              },
              products: gtmProducts,
            },
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
      isGTMSent = true;
    }
  };

  onBackButtonClick = () => {
    const { history } = this.props;
    history.push(localStorage.getItem("searchUrl"));
  };

  handleSubmit = () => {
    const {
      history,
      orderResponse,
      setPayment,
      resetCart,
      removePromoDetails,
      clearLocationSelectInfo
    } = this.props;
    if (orderResponse.orderStatus !== "VALIDATION_SUCCESS"
      || orderResponse.orderStatus !== "UNKNOWN"
      || orderResponse.orderPaymentStatus === "ORDER_POSTPAID") {
      setPayment("");
      removePromoDetails();
      this.chartReset = true;
      resetCart();
      clearLocationSelectInfo();
      history.push(ROOT + SEARCH);
    }
  };

  render() {
    const {
      restaurant,
      isLoading,
      orderResponse,
      appParams,
      cart
    } = this.props;
    const { currecyDescription, currecyISOPrecision, logo, bgDesktop } = appParams;
    const imgBackground = (this.props.ui.screenSize === "sm" ? logo : bgDesktop);
    if (orderResponse && orderResponse.orderExternalId) {

      this.sendGTMData(
        cart,
        restaurant,
        appParams,
        orderResponse.orderExternalId
      );
    }

    let totalDiscount = 0;
    if (this.props.cart.discount !== undefined && this.props.cart.discount !== null) {
      totalDiscount = this.props.cart.discount;
    }

    if (this.props.cart.siteWideVoucherDetails !== undefined && this.props.cart.siteWideVoucherDetails !== null) {
      if (this.props.cart.siteWideVoucherDetails.totalDiscount !== undefined
        && this.props.cart.siteWideVoucherDetails.totalDiscount !== null) {
        totalDiscount = totalDiscount + this.props.cart.siteWideVoucherDetails.totalDiscount;
      }
    }

    return (
      <div className="App ls-app ls-order">
        <div className="ls-app-back" style={{ backgroundImage: "url(" + imgBackground + ")" }}>
          <div className="ls-entry-backdrop"></div>
        </div>
        {isLoading && <Loader />}
        <div className="body">
          {orderResponse && orderResponse.orderSuccesful ? (
            <div>
              {orderResponse.orderStatus === "VALIDATION_SUCCESS"
                || orderResponse.orderStatus === "UNKNOWN" ? (
                <div className="ls-order-dialog-wrapper">
                  <div className="ls-order-dialog-icon">
                    <div className="lds-ripple">
                      <div />
                      <div />
                    </div>
                  </div>
                  <div className="ls-order-dialog-title">
                    Payment Processing
                  </div>
                  <div className="ls-order-dialog-content">
                    Please wait while we confirm the payment.
                  </div>
                  <div className="ls-order-dialog-additional">
                    <p>
                      Order Reference :{" "}
                      <strong>{orderResponse.externalId}</strong>
                    </p>
                  </div>
                </div>
              ) : (
                <OrderModal
                  orderResponse={orderResponse}
                  currecyDescription={currecyDescription}
                  currecyISOPrecision={currecyISOPrecision}
                  discount={totalDiscount}
                  cartItems={this.props.cart.cartItems}
                  totalPrice={this.props.cart.totalPrice}
                />
              )}
            </div>
          ) : (
            <div className="ls-order-dialog-wrapper ls-order-dialog-wrapper--error">
              <div className="ls-order-dialog-icon">
                <img
                  src={require("../../assets/img/close-red.svg")}
                  alt="close"
                />
              </div>
              <div className="ls-order-dialog-title">
                Order Unsuccessful
              </div>
              <div className="ls-order-dialog-content">
                There was an error while processing your payment
              </div>
            </div>
          )}
          <div>
            {orderResponse ? (
              <div>
                {
                  orderResponse.orderStatus === "PAYMENT_SUCCESS" ||
                    orderResponse.orderStatus === "PAYMENT_FAILED" ||
                    orderResponse.orderPaymentStatus === "ORDER_POSTPAID" ? (
                    <button
                      type="button"
                      className="ls-cart-button verification"
                      onClick={() => this.handleSubmit()}
                      style={{ marginTop: '0.5rem' }}
                    >
                      Back to Home
                    </button>) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.menu.restaurant,
    cart: state.cart,
    isLoading: state.app.loading,
    orderResponse: state.cart.orderResponse,
    appParams: state.app.appParams,
    selectedPayment: state.paymentDetails.paymentType,
    ui: state.ui
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishOrder: () => dispatch(postOrder()),
    resetCart: () => dispatch(setCartEmpty()),
    clearLocationSelectInfo: () => dispatch(clearLocationSelectInfo()),
    setPayfortResponse: (response, history) =>
      dispatch(set3dsPaymentResponse(response, history)),
    setPayment: (method) => dispatch(setPaymentMethod(method)),
    removePromoDetails: () => dispatch(removeSiteWidePromoDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete);
