import React from "react";
import Button from '../../components/Button';

const QuantitySelector = ({
  mobile,
  price,
  itemCount,
  extraItemPrice,
  onAdd,
  addToBasket,
  onRemove,
  currecyDescription,
  currecyISOPrecision,
  originalPrice,
  existingItem,
  isOpenCategory
}) => {

  const totalPrice = (itemCount * price + extraItemPrice).toFixed(currecyISOPrecision);
  if (mobile) {
    const buttonName = existingItem ? 'UPDATE' : 'ADD';
    return (
      <div className="ls-quantity-selector-mobile" style={{ backgroundColor: (!isOpenCategory ? "#909ca2" : null) }}>
        <div>
          <button
            type="button"
            disabled={itemCount === 1}
            onClick={() => isOpenCategory ? onRemove() : null}>
            -
          </button>
        </div>
        <div>
          <span>{itemCount}</span>
          <div />
        </div>
        <div>
          <button type="button" onClick={() => isOpenCategory ? onAdd() : null}>
            +
          </button>
        </div>
        <div onClick={isOpenCategory ? addToBasket : null}>
          <div>
            {currecyDescription} &nbsp;
            {`${totalPrice}`}
          </div>
        </div>
        <div onClick={isOpenCategory ? addToBasket : null}>{buttonName}</div>
      </div>
    );
  }

  return (
    <div className="ls-quantity-selector">
      <div className="ls-quantity-selector-counter">
        <div className="ls-quantity-value">
          {currecyDescription}
          &nbsp;
          {totalPrice}
        </div>

        <div className="ls-quantity-qty-selector">
          <button
            type="button"
            className="ls-quantity-toggle--left"
            disabled={itemCount === 1}
            onClick={() => isOpenCategory ? onRemove() : null}
          >
            -
          </button>
          <div className="amount">{itemCount}</div>
          <button
            type="button"
            className="ls-quantity-toggle--right"
            onClick={() => isOpenCategory ? onAdd() : null}
          >
            +
          </button>
        </div>
      </div>
      <div className="ls-quantity-selector-price">
        <span className="ls-quantity-selector-last-price" style={{ marginRight: "5px" }}>
          {currecyDescription}
          {` ${price && price.toFixed(currecyISOPrecision)}`}
        </span>
        <span className="ls-quantity-selector-price-vat">including VAT</span>
        {!isNaN(originalPrice) ? (
          <span className="ls-quantity-selector-original-price">
            <del>{currecyDescription}</del>
            <del>{` ${originalPrice && originalPrice.toFixed(currecyISOPrecision)}`}</del>
          </span>
        ) : null}
        <Button onClick={isOpenCategory ? addToBasket : null} style={{ backgroundColor: (!isOpenCategory ? "#909ca2" : null) }}>+ Add</Button>
      </div>
    </div >
  );
};

export default QuantitySelector;
