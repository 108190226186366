import {
  CREDIT_CARD_TOKENIZATION_SUCCESS,
} from "../actionTypes";

import { PAYMENT } from "../../utils/enums";

const initState = {
  paymentType: ""

};

const paymentReducer = (state = initState, action) => {

  switch (action.type) {
    case CREDIT_CARD_TOKENIZATION_SUCCESS:
      return {
        ...state,
        paymentType: PAYMENT.CREDIT_CARD,
        cardDetails: action.payload
      };
    default: {
      return state;
    }
  }
};

export default paymentReducer;
